
import type { PropType } from 'vue';
import Vue from 'vue';
import NavigationEntry from '@/components/navigation/NavigationEntry.vue';
import type { NavigationsElternPunkt } from '@/components/navigation/navigation';

export default Vue.extend({
  components: { NavigationEntry },
  props: {
    group: {
      type: Object as PropType<NavigationsElternPunkt>,
      required: true,
    },
  },
  data: () => ({ active: false }),
});
