
import mixins from 'vue-typed-mixins';
import { useStore } from '@/store';
import type { Organizations } from '@/utils/backendTypes';
import handleReportChange from '@/mixins/handleReportChange';

interface SelectData {
  id?: number;
  header?: string;
  divider?: boolean;
  name?: string;
  group?: string;
  isLocked?: boolean;
  isAggregated?: boolean;
}

export default mixins(handleReportChange).extend({
  name: 'OrganizationReportSelector',
  data() {
    return {
      organizations: [] as Organizations,
      store: useStore(),
    };
  },
  computed: {
    selectOptions(): SelectData[] {
      const selectData: SelectData[] = [];
      const hasMultipleOrganizations = this.organizations.length > 1;
      const numberOfOrganizations = this.organizations.length;
      let i = 0;
      for (const organization of this.organizations) {
        i++;
        // add header
        if (hasMultipleOrganizations) {
          selectData.push({
            header: organization.name,
          });
        }

        // add reports option
        for (const orgReport of organization.organizationReports) {
          selectData.push({
            id: orgReport.id,
            name: orgReport.name,
            group: organization.name,
            isLocked: orgReport.isLocked,
            isAggregated: orgReport.isAggregated,
          });
        }

        // add divider
        if (hasMultipleOrganizations && i < numberOfOrganizations) {
          selectData.push({
            divider: true,
          });
        }
      }

      return selectData;
    },
  },
  async created() {
    this.setStoreBasedValues();

    // Subscribe to changes in the store
    this.store.$subscribe(() => {
      this.setStoreBasedValues();
    });
  },
  methods: {
    setStoreBasedValues() {
      this.organizations = this.store.organizations;

      this.selectedOrganizationReportId =
        this.store.selectedOrganizationReportId;
    },
  },
});
