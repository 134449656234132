
import type { PropType } from 'vue';
import mixins from 'vue-typed-mixins';
import FrageId from '@/components/library/FrageId.vue';

import type {
  QuantitativeElementBackend,
  QuantitativeResponseBackend,
} from '@/utils/backendTypes';
import handleQuantitativeError from '@/mixins/handleError';
import { trpcClient } from '@/utils/trpc';
import updateResponses from '@/mixins/updateResponses';
import QuestionColumn from '@/components/fragebogen/quantitativ/tabellenDarstellung/QuestionColumn.vue';
import ToolTipResponder from '@/components/fragebogen/quantitativ/ToolTipResponder.vue';

export default mixins(updateResponses, handleQuantitativeError).extend({
  components: {
    FrageId,
    QuestionColumn,
    ToolTipResponder,
  },
  props: {
    multipleInputTable: {
      type: Object as PropType<
        QuantitativeElementBackend['multipleInputTable']
      >,
      required: true,
    },
    responses: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    reportLocked: {
      type: Boolean,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerMultipleInputTable: this.multipleInputTable!,
    };
  },
  computed: {
    sumModel() {
      return this.innerMultipleInputTable.questions
        .map(
          (question) => this.responses[question.frontendId]?.valueNumeric || 0
        )
        .reduce((partSum, current) => partSum + current, 0);
    },
  },
  methods: {
    createInput(questionId: number, response: string) {
      trpcClient.response.createQuantitativeNumeric
        .mutate({
          response: response ? +response : null,
          questionId,
        })
        .then(this.emitResponses)
        .catch((e) =>
          this.handleError(e, 'Konnte die Antwort nicht speichern:')
        );
    },
    responder(frontendId: string): string | undefined {
      const user = this.responses[frontendId]?.organizationUser?.user;
      return user ? `${user.firstName} ${user.lastName}` : undefined;
    },
    respondedAt(frontendId: string): Date | undefined {
      return this.responses[frontendId]?.createdAt;
    },
  },
});
