
import Vue from 'vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import type { VuetifyForm } from '@/ts/types/generic';
import IconWithTooltip from '@/components/library/IconWithTooltip.vue';

export default Vue.extend({
  name: 'OrganizationsListView',
  components: {
    IconWithTooltip,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
    ],
    organizations: [] as any[],
    editedIndex: -1,
    editedItem: {
      name: '',
      id: 0,
    },
    defaultItem: {
      name: '',
      id: 0,
    },
    formRulesName: [
      (value: string) => !!value || 'Name ist erforderlich',
      (value: string) =>
        (value && value.length <= 50) ||
        `Name muss weniger als 50 Zeichen lang sein (jetzt ${value.length})`,
    ],
    error: null as null | string,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? 'Neue Organisation'
        : 'Organisation bearbeiten';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.setOrganizations();
  },

  methods: {
    async setOrganizations() {
      try {
        this.organizations = await trpcClient.organization.getAll.query();
      } catch (error) {
        this.error = getErrorMessage(error);
      }
    },

    editItem(item: any) {
      this.editedIndex = this.organizations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item: any) {
      this.editedIndex = this.organizations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await trpcClient.organization.deleteById.mutate({
          id: this.editedItem.id,
        });
        await this.setOrganizations();
        this.error = null;
      } catch (error) {
        this.error = getErrorMessage(error);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    async save() {
      const isValidated = (this.$refs.form as VuetifyForm).validate();

      if (isValidated) {
        if (this.editedIndex > -1) {
          try {
            await trpcClient.organization.updateById.mutate({
              name: this.editedItem.name,
              id: this.editedItem.id,
            });
            await this.setOrganizations();
            this.error = null;
          } catch (error) {
            this.error = getErrorMessage(error);
          }
        } else {
          try {
            await trpcClient.organization.create.mutate({
              name: this.editedItem.name,
            });
            await this.setOrganizations();
            this.error = null;
          } catch (error) {
            this.error = getErrorMessage(error);
          }
        }
        this.close();
      }
    },
  },
});
