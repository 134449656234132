
import Vue from 'vue';

export default Vue.extend({
  props: {
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
});
