import Vue from 'vue';
import { PiniaVuePlugin, createPinia } from 'pinia';
import App from '@/App.vue';
import apexchart from '@/plugins/apexchart';
import compositionAPI from '@/plugins/compositionAPI';
import fragment from '@/plugins/fragment';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import copyToClipboard from '@/plugins/copyToClipboard';
import vueQuery from '@/plugins/vueQuery';

apexchart();
fragment();
compositionAPI();
copyToClipboard();
vueQuery();

// Create Pinia instance
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
