export class PlausibilitätDialogParameter {
  static eventName = 'PlausibilitätDialogParameter';

  aktuellerWert: number;
  vorjahr: number;

  constructor(aktuellerWert: number, vorjahr: number) {
    this.aktuellerWert = aktuellerWert;
    this.vorjahr = vorjahr;
  }
}
