
import type { Component, PropType } from 'vue';
import Vue from 'vue';

import type { Textteil } from '@/components/fragebogen/fragetypen/zusammengesetzterText/ZusammengesetzterText.vue';
import type { EinfacherText } from '@/components/fragebogen/fragetypen/zusammengesetzterText/EinfacherText.vue';
import EinfacherTextVue from '@/components/fragebogen/fragetypen/zusammengesetzterText/EinfacherText.vue';

const defaultExport = Vue.extend({
  components: {
    EinfacherText: EinfacherTextVue,
  },
  props: {
    prop: {
      type: Object as PropType<BoldText>,
      required: true,
    },
  },
});
export default defaultExport;

export class BoldText implements Textteil {
  einfacherText: EinfacherText;
  component: Component = defaultExport;

  constructor(einfacherText: EinfacherText) {
    this.einfacherText = einfacherText;
  }
}
