var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"auswertung-quantitative-container"},[(_vm.isExportLoading)?_c('div',{staticClass:"export-loading-overlay"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1):_vm._e(),(_vm.hasReportData)?_c('v-template',[_c('PdfExportButton',{on:{"click":_vm.printPage}}),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":_vm.downloadCSV}},[_vm._v(" CSV-Datei herunterladen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-delimited")])],1)],1):_vm._e(),(_vm.environment)?_c('v-card',{class:{ 'on-print-break-after': !!_vm.social || !!_vm.governance },attrs:{"elevation":0}},[_c('v-card-title',{staticClass:"font-weight-bold",staticStyle:{"padding":"24px 0 12px 24px"}},[_vm._v(" Die Auswertung für  "),_c('span',{staticClass:"primary--text font-italic"},[_vm._v("Environment")])]),_vm._l((_vm.environment),function(section,i){return _c('vue-fragment',{key:section.id},[_c('div',{class:{
          'on-print-break-after': _vm.environment && i !== _vm.environment.length - 1,
        }},[_c('v-card-subtitle',{staticStyle:{"padding":"0px 0px 12px 24px"}},[_vm._v("im Bereich "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(section.name)+" ")])]),_c('v-card-text',[_c('QuantitativExpansionPanelsNew',{attrs:{"groups":section.groups,"responses":_vm.responses,"responses-previous-year":_vm.responsesPreviousYear,"report-locked":false,"auswertung":true}})],1)],1)])})],2):_vm._e(),(_vm.social)?_c('v-card',{class:{ 'on-print-break-after': !!_vm.governance },attrs:{"elevation":0}},[_c('v-card-title',{staticClass:"font-weight-bold",staticStyle:{"padding":"24px 0 12px 24px"}},[_vm._v("Die Auswertung für  "),_c('span',{staticClass:"primary--text font-italic"},[_vm._v("Social")])]),_vm._l((_vm.social),function(section,i){return _c('vue-fragment',{key:section.id},[_c('div',{class:{
          'on-print-break-after': _vm.social && i !== _vm.social.length - 1,
        }},[_c('v-card-subtitle',{staticStyle:{"padding":"0px 0px 12px 24px"}},[_vm._v("im Bereich "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(section.name)+" ")])]),_c('v-card-text',[_c('QuantitativExpansionPanelsNew',{attrs:{"groups":section.groups,"responses":_vm.responses,"responses-previous-year":_vm.responsesPreviousYear,"report-locked":false,"auswertung":true}})],1)],1)])})],2):_vm._e(),(_vm.governance)?_c('v-card',{attrs:{"elevation":0}},[_c('v-card-title',{staticClass:"font-weight-bold",staticStyle:{"padding":"24px 0 12px 24px"}},[_vm._v("Die Auswertung für  "),_c('span',{staticClass:"primary--text font-italic"},[_vm._v("Governance")])]),_vm._l((_vm.governance),function(section,i){return _c('vue-fragment',{key:section.id},[_c('div',{class:{
          'on-print-break-after': _vm.governance && i !== _vm.governance.length - 1,
        }},[_c('v-card-subtitle',{staticStyle:{"padding":"0px 0px 12px 24px"}},[_vm._v("im Bereich "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(section.name)+" ")])]),_c('v-card-text',[_c('QuantitativExpansionPanelsNew',{attrs:{"groups":section.groups,"responses":_vm.responses,"responses-previous-year":_vm.responsesPreviousYear,"report-locked":false,"auswertung":true,"no-page-break-after-last-panel":_vm.governance && i === _vm.governance.length - 1}})],1)],1)])})],2):_vm._e(),(_vm.hasReportData)?_c('v-template',[_c('PdfExportButton',{on:{"click":_vm.printPage}}),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":_vm.downloadCSV}},[_vm._v(" CSV-Datei herunterladen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-delimited")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }