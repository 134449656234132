
import type { PropType } from 'vue';
import Vue from 'vue';

import UnsereEmpfehlungBlock from '@/components/auswertung/UnsereEmpfehlungBlock.vue';

import type { ReportDataBackend } from '@/utils/backendTypes';

export default Vue.extend({
  components: {
    UnsereEmpfehlungBlock,
  },
  props: {
    reportData: {
      type: Object as PropType<ReportDataBackend>,
      required: true,
    },
  },
});
