
import Vue from 'vue';

import type { EditorConfig } from '@editorjs/editorjs';
import EditorJS from '@editorjs/editorjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import Paragraph from '@editorjs/paragraph';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import Tooltip from 'editorjs-tooltip';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import Hyperlink from 'editorjs-hyperlink';

import CopyToClipboardEntry from '@/components/fragebogen/menu/CopyToClipboardEntry.vue';

const holderId = 'editorjs';

export default Vue.extend({
  components: {
    CopyToClipboardEntry,
  },
  data() {
    return {
      value: '',
      editor: null as unknown as EditorJS,
      holderId,
    };
  },

  mounted() {
    this.editor = new EditorJS(
      editorConfig({
        holderId,
        onChange: this.save,
        placeholder: 'Einen neuen Text erstellen (mehr als plaintext 🎉🥳).',
      })
    );
  },
  methods: {
    async save() {
      this.value = JSON.stringify(await this.editor.save());
    },
  },
});

export function editorConfig(param: {
  holderId: string;
  readOnly?: boolean;
  onChange?: () => Promise<void>;
  placeholder?: string;
}): EditorConfig {
  return {
    holder: param.holderId,
    readOnly: param.readOnly,
    placeholder: param.placeholder,
    /**
     * This Tool will be used as default
     */
    tools: {
      paragraph: {
        class: Paragraph,
        config: {},
      },
      tooltip: {
        class: Tooltip,
        config: {
          underline: true,
          backgroundColor: '#616161e6',
          textColor: '#FDFEFE',
          holder: param.holderId,
        },
      },
      hyperlink: {
        class: Hyperlink,
        config: {
          target: '_blank',
          rel: 'noopener',
          availableTargets: ['_blank'],
          availableRels: ['noopener'],
          validate: true,
        },
      },
    },
    onChange: param.onChange,
  } as EditorConfig;
}
