
import Vue from 'vue';

import CO2ReportsList from './components/CO2ReportsList.vue';
import CO2ReportRequestsList from './components/CO2ReportRequestsList.vue';

export default Vue.extend({
  name: 'CO2ReportListView',
  components: {
    CO2ReportsList,
    CO2ReportRequestsList,
  },
  data: () => ({
    tab: null,
  }),
});
