
import type { Component } from 'vue';
import Vue from 'vue';
import type { Textteil } from '@/components/fragebogen/fragetypen/zusammengesetzterText/ZusammengesetzterText.vue';
import NewLine from '@/components/fragebogen/fragetypen/zusammengesetzterText/NewLine.vue';

const defaultExport = Vue.extend({
  components: { NewLine },
});
export default defaultExport;

export class DoubleNewLine implements Textteil {
  component: Component = defaultExport;

  constructor() {}
}
