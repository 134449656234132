import type { Route } from 'vue-router';
import type Router from 'vue-router';

class UrlParameter {
  paramname: string;

  constructor(paramname: string) {
    this.paramname = paramname;
  }

  parameterWertLesen(route: Route): string | null {
    return route.query[this.paramname] as string | null;
  }

  parameterWertLesenNumber(route: Route): number | null {
    const value = this.parameterWertLesen(route);
    return value !== null ? +value : null;
  }

  parameterWertSchreiben(
    router: Router,
    wert: string | number | undefined,
    route: Route
  ): Route {
    const query = {
      ...route.query,
    };
    if (wert !== undefined) {
      query[this.paramname] = wert.toString();
    }
    return router.resolve(
      {
        query,
      },
      route
    ).route;
  }
}

export const frageId = new UrlParameter('frageId');
export const tabId = new UrlParameter('tabId');
export const reportId = new UrlParameter('reportId');
export const returnToUrl = new UrlParameter('returnToUrl');
export const cardId = new UrlParameter('cardId');
