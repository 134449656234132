
import mixins from 'vue-typed-mixins';
import { modelMixin } from '@/mixins/model';
import ToolTipResponder from '@/components/fragebogen/quantitativ/ToolTipResponder.vue';

export default mixins(modelMixin).extend({
  components: {
    ToolTipResponder,
  },
  props: {
    reportLocked: {
      type: Boolean,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
    responder: {
      type: String,
      default: undefined,
    },
    respondedAt: {
      type: Date,
      default: undefined,
    },
  },
});
