
import Vue from 'vue';
import type { PropType } from 'vue';
import type { CategorySection } from '@prisma/client';

import type { OutputData } from '@editorjs/editorjs';
import EditorJS from '@editorjs/editorjs';
import { trpcClient } from '@/utils/trpc';
import FrageId from '@/components/library/FrageId.vue';
import { frageId, reportId, tabId } from '@/router/queryparam';

import CsrdDialog from '@/components/fragebogen/dialog/CsrdDialog.vue';
import DnkDialog from '@/components/fragebogen/dialog/DnkDialog.vue';
import GriDialog from '@/components/fragebogen/dialog/GriDialog.vue';
import PaiDialog from '@/components/fragebogen/dialog/PaiDialog.vue';
import KommentarFeld from '@/components/library/KommentarFeld.vue';

import DeleteEntry from '@/components/fragebogen/menu/DeleteEntry.vue';
import CopyToClipboardEntry from '@/components/fragebogen/menu/CopyToClipboardEntry.vue';
import MailToEntry from '@/components/fragebogen/menu/MailToEntry.vue';
import QRCodeEntry from '@/components/fragebogen/menu/QRCodeEntry.vue';
import { QualitativeProgress } from '@/ts/events/QualitativeProgress';
import { getErrorMessage } from '@/utils/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import { editorConfig } from '@/views/EditorJsView.vue';
import type { QualitativeFrageBackend } from '@/utils/backendTypes';

import { useStore } from '@/store';
import IconWithTooltip from '@/components/library/IconWithTooltip.vue';

export default Vue.extend({
  components: {
    FrageId,
    KommentarFeld,
    DeleteEntry,
    CopyToClipboardEntry,
    MailToEntry,
    QRCodeEntry,
    IconWithTooltip,
  },
  props: {
    frage: {
      type: Object as PropType<QualitativeFrageBackend>,
      required: true,
    },
    demoFrage: {
      type: Boolean,
      default: false,
    },
    fragenummer: {
      type: Number,
      required: true,
    },
    categorySection: {
      type: String as PropType<CategorySection>,
      required: true,
    },
    reportLocked: {
      type: Boolean,
      required: true,
    },
    tabId: {
      type: Number,
      required: true,
    },
  },
  data() {
    let latestResponse;

    if (this.frage.responses && this.frage.responses[0]) {
      latestResponse = this.frage.responses[0];
    }
    const initialModel = {
      isEssential: latestResponse ? latestResponse.isEssential : true,
      response: latestResponse?.option?.frontendId || '',
      comment: latestResponse?.comment || '',
    };
    return {
      model: { ...initialModel },
      initialModel,
      eingeklappt: true,
      CsrdDialog,
      DnkDialog,
      GriDialog,
      PaiDialog,
      editor: null as null | EditorJS,
      showEditor: true,
      store: useStore(),
    };
  },
  async mounted() {
    try {
      const jsonDescription = JSON.parse(
        this.frage.description || '{}'
      ) as OutputData;
      const config = editorConfig({
        holderId: `editorjs${this.frage.id}`,
        readOnly: true,
      });
      this.editor = new EditorJS(config);
      await this.editor.isReady;
      this.editor.render(jsonDescription);
    } catch (error) {
      this.showEditor = false;
    }
  },
  methods: {
    emitUpdateMethod() {
      if (this.demoFrage) {
        return;
      }
      trpcClient.response.createQualitative
        .mutate({
          questionCode: this.frage.frontendId,
          responseId: this.model.response,
          isEssential: this.model.isEssential,
          comment: this.model.comment,
        })
        .then(() => {
          trpcClient.question.getQualitativeProgressBySection
            .query(this.categorySection)
            .then((progressInPercentages) => {
              this.$root.$emit(
                QualitativeProgress.eventName(this.categorySection),
                new QualitativeProgress(
                  this.categorySection,
                  progressInPercentages,
                  progressInPercentages === 100
                )
              );
            });
        })
        .catch((error) => {
          this.model = { ...this.initialModel };

          const errorMessage = getErrorMessage(error);
          const snackbarText = `Konnte die Antwort nicht auswählen: ${errorMessage}`;
          this.$root.$emit(
            SnackbarAnzeigen.eventName,
            new SnackbarAnzeigen('error', snackbarText)
          );
        });
    },
    wenigerOderMehr(): { anzeigeText: String; icon: String } {
      return this.eingeklappt
        ? {
            anzeigeText: 'Mehr Informationen',
            icon: 'mdi-chevron-down',
          }
        : {
            anzeigeText: 'Weniger Informationen',
            icon: 'mdi-chevron-up',
          };
    },
    frageHighlighten(): boolean {
      const selectedOrganizationReportId =
        this.store.selectedOrganizationReportId;
      const reportIdFromURL = reportId.parameterWertLesenNumber(this.$route);
      const frageIdFromUrl = frageId.parameterWertLesen(this.$route);
      const highlight =
        reportIdFromURL === selectedOrganizationReportId &&
        frageIdFromUrl === this.frage.frontendId;

      return highlight;
    },
    mailToLink(): string {
      return `mailto:?subject=${encodeURIComponent(
        this.betreff()
      )}&body=${encodeURIComponent(this.eMailInhalt())}`;
    },
    betreff(): string {
      return 'Frage zum EB-Sustainability-Scoring';
    },
    frageTitel(): string {
      return this.frage.text;
    },
    frageLink(): string {
      const routeWithQuery = reportId.parameterWertSchreiben(
        this.$router,
        this.store.selectedOrganizationReportId,
        tabId.parameterWertSchreiben(
          this.$router,
          this.tabId,
          frageId.parameterWertSchreiben(
            this.$router,
            this.frage.frontendId,
            this.$route
          )
        )
      );
      return new URL(
        this.$router.resolve(
          { query: routeWithQuery.query },
          routeWithQuery
        ).href,
        window.location.origin
      ).href;
    },
    eMailInhalt(): string {
      return `Hallo,

ich benötige Unterstützung bei der Frage ${
        this.fragenummer
      } aus dem EB-Sustainability-Scoring:

${this.frageTitel()}

Hier ist der Link zum EB-Sustainability-Scoring:

${this.frageLink()}

Zur Anmeldung nutze die bekannte Kennung.

Vielen Dank und beste Grüße,`;
    },
  },
});
