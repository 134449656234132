
import Vue from 'vue';

import KartenInhalte from '@/components/homepage/KartenInhalteEntry';
import InfoCardListView from '@/views/home/InfoCardListView.vue';

export default Vue.extend({
  name: 'EntryView',
  components: {
    InfoCardListView,
  },
  data: () => ({
    localStorage,
    KartenInhalte,
  }),
});
