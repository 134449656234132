
import Vue from 'vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';

export default Vue.extend({
  name: 'DeleteThemeDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    themeId: {
      type: Number,
      required: true,
      default: 0,
    },
    refetchTreeDataFn: Function,
  },
  data: () => ({
    dialog: false,
  }),

  watch: {
    value(val: boolean) {
      this.dialog = val;
    },
    dialog(val: boolean) {
      this.$emit('input', val);
    },
  },

  methods: {
    closeDialog() {
      this.$emit('input', false);
    },
    async deleteTheme() {
      try {
        await trpcClient.materialityThemes.deleteById.mutate({
          id: this.themeId,
        });

        this.refetchTreeDataFn();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
      }

      this.closeDialog();
    },
  },
});
