
import type { PropType } from 'vue';
import Vue from 'vue';
import humanizeDuration from 'humanize-duration';
import IconWithTooltip from '@/components/library/IconWithTooltip.vue';

export default Vue.extend({
  components: {
    IconWithTooltip,
  },
  props: {
    responder: {
      type: String,
      default: undefined,
    },
    respondedAt: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    auswertung: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    formattedTimestamp(): String {
      const weekday = this.respondedAt?.toLocaleString('de-DE', {
        weekday: 'short',
      });
      const germanDateAndTime = this.respondedAt?.toLocaleString('de-DE');
      return `${weekday}., ${germanDateAndTime}`;
    },
    formattedDuration(): String {
      const millisecondsSinceResponse =
        new Date().getTime() - this.respondedAt.getTime();

      return humanizeDuration(millisecondsSinceResponse, {
        language: 'de',
        units: ['y', 'mo', 'w', 'd', 'h', 'm'],
        round: true,
        largest: 2,
        conjunction: ' und ',
      });
    },
  },
});
