
import type { PropType } from 'vue';
import Vue from 'vue';
import type {
  CategoryDataBackend,
  ReportQuestionBackend,
} from '@/utils/backendTypes';

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    categories: {
      type: Array as PropType<CategoryDataBackend[]>,
      required: true,
    },
  },
  methods: {
    notEmptyRecommendedActions(questions: ReportQuestionBackend) {
      return questions.filter((question) => question.recommendedAction);
    },
  },
});
