
import Vue from 'vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import { getUser } from '@/components/login/login';
import PageTitelAndSubtitle from '@/components/library/PageTitelAndSubtitle.vue';
import type { LoggedInUser } from '@/ts/types/generic';
import type { DataItem } from '@/views/report-template/components/MaterialityThemesTreeSelection.vue';
import MaterialityThemesTreeSelection from '@/views/report-template/components/MaterialityThemesTreeSelection.vue';

export default Vue.extend({
  name: 'OrganizationReportView',

  components: {
    PageTitelAndSubtitle,
    MaterialityThemesTreeSelection,
  },

  data: () => ({
    organizationReportId: undefined as undefined | number,
    organizationReportTitle: '',
    selectedMaterialityThemes: [] as (number | string)[],
    materialityThemes: [] as DataItem[],
    openNodes: [] as (number | string)[],
  }),

  async created() {
    const id = this.$route.params.organizationReportId;
    const organizationReportId = Number(id);
    const idIsSet = !!(organizationReportId && organizationReportId > 0);

    if (!idIsSet) {
      this.$router.push('/');
    }

    try {
      const organizationReport =
        await trpcClient.organizationReport.getById.query({
          id: organizationReportId,
        });

      this.organizationReportId = organizationReportId;
      this.organizationReportTitle = `"${organizationReport.name}" (Jahr: ${organizationReport.reportTemplate.year})`;

      await this.setMaterialityFilter();
    } catch (error) {
      this.$router.push('/');
    }
  },

  methods: {
    goBack() {
      const user = getUser() as LoggedInUser;

      const isAdminUser = user.role === 'ADMIN';
      const isParentOrgOwner = user.extendedRole === 'PARENT_ORG_OWNER';
      const isSubOrgOwner = user.extendedRole === 'SUB_ORG_OWNER';

      const parentId = this.$route.params.parentId;
      const organizationId = this.$route.params.id;

      if (isAdminUser) {
        if (parentId) {
          this.$router.push(`/organizations/${parentId}/sub/${organizationId}`);
        } else {
          this.$router.push(`/organizations/${organizationId}`);
        }
      } else if (isParentOrgOwner) {
        if (parentId) {
          this.$router.push(`/organizations/${parentId}/sub/${organizationId}`);
        } else {
          this.$router.push(`/organization`);
        }
        this.$router.push('/organization');
      } else if (isSubOrgOwner) {
        this.$router.push('/management');
      }
    },
    async setMaterialityFilter() {
      try {
        const data =
          await trpcClient.materialityFilter.getAllByOrganizationReportId.query(
            {
              organizationReportId: this.organizationReportId as number,
            }
          );

        this.setData(data);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
        this.$router.push('/');
      }
    },
    setData(data: DataItem[]) {
      this.materialityThemes = data;

      const selectedNodes: (number | string)[] = [];
      const allNodes: (number | string)[] = [];

      for (const sectionNode of data) {
        allNodes.push(sectionNode.id);

        if (sectionNode.children && sectionNode.children.length > 0) {
          for (const categoryNode of sectionNode.children) {
            allNodes.push(categoryNode.id);

            if (categoryNode.children && categoryNode.children.length > 0) {
              for (const subThemeNode of categoryNode.children) {
                allNodes.push(subThemeNode.id);

                if (subThemeNode.isSelected) {
                  selectedNodes.push(subThemeNode.id);
                }

                if (subThemeNode.children && subThemeNode.children.length > 0) {
                  for (const subSubThemeNode of subThemeNode.children) {
                    allNodes.push(subSubThemeNode.id);

                    if (subSubThemeNode.isSelected) {
                      selectedNodes.push(subSubThemeNode.id);
                    }
                  }
                }
              }
            }
          }
        }
      }

      this.openNodes = allNodes;
      this.selectedMaterialityThemes = selectedNodes;
    },
    async saveMaterialityFilter() {
      // get rid of category ids
      const preparedMaterialityThemesIds =
        this.selectedMaterialityThemes.filter(
          (id) => typeof id === 'number'
        ) as number[];

      // check if some of the themes are not selected
      if (preparedMaterialityThemesIds.length === 0) {
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen(
            'error',
            'Sie müssen einige der Wesentlichkeitsthemen auswählen.'
          )
        );
        return;
      }

      try {
        await trpcClient.materialityFilter.setMaterialityFilter.mutate({
          organizationReportId: this.organizationReportId as number,
          materialityThemesIds: preparedMaterialityThemesIds,
        });

        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen(
            'success',
            'Wesentlichkeitsfiltereinstellungen wurden erfolgreich gespeichert.'
          )
        );

        this.goBack();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
      }
    },
  },
});
