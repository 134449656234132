
import Vue from 'vue';
import { nextNavigationPoint } from '@/components/navigation/navigation';
import ButtonFuerNavigation from '@/components/library/ButtonFuerNavigation.vue';

export default Vue.extend({
  components: { ButtonFuerNavigation },
  computed: {
    navigationsziel() {
      return nextNavigationPoint(this.$route);
    },
  },
});
