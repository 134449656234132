
import Vue from 'vue';
export const headerFrage = 'Frage';
export const headerAntwort = 'Antwort';
export default Vue.extend({
  data() {
    return {
      headerFrage,
      headerAntwort,
    };
  },
});
