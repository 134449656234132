
import Vue from 'vue';
import MaterialityThemesTree from './components/MaterialityThemesTree.vue';
import PageTitelAndSubtitle from '@/components/library/PageTitelAndSubtitle.vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';

export default Vue.extend({
  name: 'ReportTemplateView',
  components: {
    MaterialityThemesTree,
    PageTitelAndSubtitle,
  },
  data: () => ({
    reportTemplateId: undefined as undefined | number,
    reportTemplateTitle: '',
    materialityThemes: [] as any[],
    legendInfo: [
      {
        icon: 'mdi-plus',
        text: 'ein Thema oder ein Unterthema erstellen.',
      },
      {
        icon: 'mdi-pencil',
        text: 'ein Thema oder ein Unterthema bearbeiten.',
      },
      {
        icon: 'mdi-chevron-up',
        text: 'ein Thema oder ein Unterthema nach oben bewegen.',
      },
      {
        icon: 'mdi-chevron-down',
        text: 'ein Thema oder ein Unterthema nach unten bewegen.',
      },
      {
        icon: 'mdi-file-check',
        text: 'Berichtsstandards zur Wesentlichkeit Thema auswählen',
      },
      {
        icon: 'mdi-delete',
        text: 'ein Thema oder ein Unterthema löschen.',
      },
    ],
  }),

  async created() {
    const id = this.$route.params.id;

    const reportTemplateId = Number(id);
    const idIsSet = !!(reportTemplateId && reportTemplateId > 0);

    if (!idIsSet) {
      this.$router.push('/report-template');
    }

    try {
      const reportTemplate = await trpcClient.reportTemplate.getById.query({
        id: reportTemplateId,
      });

      this.reportTemplateId = reportTemplateId;
      this.reportTemplateTitle = `"${reportTemplate.label}" (Jahr: ${reportTemplate.year})`;

      await this.setMaterialityThemes();
    } catch (error) {
      this.$router.push('/report-template');
    }
  },

  methods: {
    goBack() {
      this.$router.push('/report-template');
    },
    async setMaterialityThemes() {
      try {
        const data =
          await trpcClient.materialityThemes.getAllByReportTemplateId.query({
            reportTemplateId: this.reportTemplateId as number,
          });

        this.materialityThemes = data;
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
        this.$router.push('/report-template');
      }
    },
  },
});
