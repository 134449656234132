
import type { PropType } from 'vue';
import Vue from 'vue';
import InfoBanner from '@/components/library/InfoBanner.vue';

export interface PageTitleAndSubtitleTS {
  title: string;
  subtitle: string;
}

export default Vue.extend({
  components: {
    InfoBanner,
  },
  props: {
    pageTitleAndSubtitle: {
      type: Object as PropType<PageTitleAndSubtitleTS>,
      required: true,
    },
  },
  data() {
    return {
      model: 0,
    };
  },
});
