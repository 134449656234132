
import type { Component, PropType } from 'vue';
import Vue from 'vue';

export default Vue.extend({
  props: {
    zusammengesetzterText: {
      type: Object as PropType<ZusammengesetzterText>,
      required: true,
    },
  },
});

export interface ZusammengesetzterText {
  textteile: string | Textteil[];
}

export interface Textteil {
  component: Component;
}
