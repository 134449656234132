export type SnackbarAnzeigenType = 'success' | 'info' | 'warning' | 'error';
export class SnackbarAnzeigen {
  static eventName = 'SnackbarAnzeigen';

  type: SnackbarAnzeigenType;
  inhalt: string;
  constructor(type: SnackbarAnzeigenType, inhalt: string) {
    this.type = type;
    this.inhalt = inhalt;
  }
}
