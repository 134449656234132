
import type { PropType } from 'vue';
import Vue from 'vue';

import JSConfetti from 'js-confetti';
import type { NavigationsPunkt } from '@/components/navigation/navigation';
import { QualitativeProgress } from '@/ts/events/QualitativeProgress';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import { trpcClient } from '@/utils/trpc';

const jsConfetti = new JSConfetti();

export default Vue.extend({
  props: {
    entry: {
      type: Object as PropType<NavigationsPunkt>,
      required: true,
    },
  },
  data() {
    return {
      komplettBeantwortet: false,
      aktuellerProgress: 0,
    };
  },
  mounted() {
    const categorySection = this.entry.categorySection;
    if (categorySection) {
      trpcClient.question.getQualitativeProgressBySection
        .query(categorySection)
        .then((progressInPercentages) => {
          this.$root.$emit(
            QualitativeProgress.eventName(categorySection),
            new QualitativeProgress(categorySection, progressInPercentages)
          );
        });

      this.$root.$on(
        QualitativeProgress.eventName(categorySection),
        (event: QualitativeProgress) => {
          if (event.categorySection === categorySection) {
            const progressBefore = this.aktuellerProgress;
            this.aktuellerProgress = event.percent;
            this.komplettBeantwortet = event.percent === 100;
            if (progressBefore !== 100 && event.showConfetti) {
              jsConfetti.addConfetti();

              this.$root.$emit(
                SnackbarAnzeigen.eventName,
                new SnackbarAnzeigen(
                  'success',
                  `🥳 Alle Fragen im Bereich ${this.entry.nameInDerNavigation} beantwortet 🥳`
                )
              );
            }
          }
        }
      );
    }
  },
});
