import { createTRPCProxyClient } from '@trpc/client';
import { httpBatchLink } from '@trpc/client/links/httpBatchLink';
import superjson from 'superjson';
import type { AppRouter } from 'server/src/api/root';

const url = `${process.env.VUE_APP_API_URL || ''}/api/trpc`;

export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: url ?? '/api/trpc',
      async fetch(url, options) {
        const result = await fetch(url, {
          ...options,
          credentials: 'include',
        });

        if (result.status === 401) {
          localStorage.removeItem('user');
          trpcClient.user.logout.mutate();
          location.reload();
        }
        return result;
      },
    }),
  ],
  transformer: superjson,
});
