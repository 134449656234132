
import Vue from 'vue';
import { DialogEvents } from '@/ts/events/Dialog';

export default Vue.extend({
  props: {
    dialogTitel: {
      type: String,
      required: true,
    },
    okButtonText: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      default: 'Schließen',
    },
  },
  data() {
    return {
      DialogEvents,
    };
  },
});
