
import Vue from 'vue';

import InfoKarte from '@/components/homepage/InfoKarte.vue';
import { cardId } from '@/router/queryparam';
import { ScrollToId } from '@/ts/ScrollTo';

export default Vue.extend({
  components: {
    InfoKarte,
  },
  props: {
    kartenInhalte: {
      type: Array as () => Array<any>,
      required: true,
    },
  },
  data: () => ({
    localStorage,
  }),
  mounted() {
    const cardIdFromURL = cardId.parameterWertLesen(this.$route);
    if (
      cardIdFromURL &&
      this.kartenInhalte.map((x) => x.karteId).includes(cardIdFromURL)
    ) {
      new ScrollToId(cardIdFromURL).scrollToId();
    }
  },
});
