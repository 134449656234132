
import Vue from 'vue';
import { DialogEvents } from '@/ts/events/Dialog';
import DialogKarte from '@/components/library/DialogKarte.vue';

export default Vue.extend({
  components: { DialogKarte },
  props: {
    toolTipText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
      dialogSchließenEventName: DialogEvents.closeDialog,
    };
  },
});
