
import Vue from 'vue';
import { trpcClient } from '@/utils/trpc';
import { downloadFileInsideFrontend } from '@/utils/generic';

export default Vue.extend({
  data() {
    return {
      who: '',
      data: {
        qualitativeQuestionsCount: 0,
        qualitativeResponseCount: 0,
        quantitativeQuestionCount: 0,
        quantitativeResponseCount: 0,
      },
      downloading: false,
    };
  },
  computed: {
    answeredPercent(): number {
      return (this.answersCount * 100) / this.questionsCount || 0;
    },
    answersCount() {
      return (
        this.data.qualitativeResponseCount +
          this.data.quantitativeResponseCount || 0
      );
    },
    questionsCount() {
      return (
        this.data.qualitativeQuestionsCount +
          this.data.quantitativeQuestionCount || 0
      );
    },
  },
  async mounted() {
    const response = await trpcClient.question.getVrRiskScoreReport.query();
    this.data = response;
  },
  methods: {
    async downloadCsv() {
      this.downloading = true;
      const vrRisikoDataCSV =
        await trpcClient.response.getVrRisikoDataCSV.query({
          who: this.who,
        });

        downloadFileInsideFrontend(vrRisikoDataCSV, 'VR-ESG-RisikoScore.csv', 'csv');
      this.downloading = false;
    },
  },
});
