var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',_vm._l(([
        'hundred-percent-of-primary-color',
        'eighty-percent-of-primary-color',
        'sixty-percent-of-primary-color',
        'fourty-percent-of-primary-color',
        'twenty-percent-of-primary-color',
      ]),function(percentOfPrimary){return _c('v-col',{key:percentOfPrimary,attrs:{"cols":"1"}},[_c('v-sheet',{class:percentOfPrimary},[_c('br'),_c('br')]),_vm._v(" "+_vm._s(percentOfPrimary)+" ")],1)}),1),_c('v-row',_vm._l(([
        'lighten-1',
        'lighten-2',
        'lighten-3',
        'lighten-4',
        'lighten-5',
      ]),function(lighten){return _c('v-col',{key:lighten,attrs:{"cols":"1"}},[_c('v-sheet',{staticClass:"primary",class:lighten},[_c('br'),_c('br')]),_vm._v(" "+_vm._s(lighten)+" ")],1)}),1),_c('v-row',_vm._l((['darken-1', 'darken-2', 'darken-3', 'darken-4']),function(darken){return _c('v-col',{key:darken,attrs:{"cols":"1"}},[_c('v-sheet',{staticClass:"primary",class:darken},[_c('br'),_c('br')]),_vm._v(" "+_vm._s(darken)+" ")],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }