import Vue from 'vue';

export const modelMixin = Vue.extend({
  props: {
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: undefined,
    },
  },
  data() {
    return {
      modelFromMixin: this.value,
    };
  },
  watch: {
    modelFromMixin(newValue) {
      this.$emit('input', newValue);
    },
    value(newValue) {
      this.modelFromMixin = newValue;
    },
  },
});
