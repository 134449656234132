
import Vue from 'vue';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';

export default Vue.extend({
  props: {
    inhalt: {
      type: String,
      required: true,
    },
  },
  methods: {
    clipboardFeedbackSnackbar(erfolgreich: boolean): void {
      let snackbarAnzeigen;
      if (erfolgreich) {
        snackbarAnzeigen = new SnackbarAnzeigen(
          'success',
          'Erfolgreich kopiert 😊'
        );
      } else {
        snackbarAnzeigen = new SnackbarAnzeigen(
          'error',
          'Nicht erfolgreich kopiert 😔'
        );
      }
      this.$root.$emit(SnackbarAnzeigen.eventName, snackbarAnzeigen);
    },
  },
});
