
import type { Component, PropType } from 'vue';
import Vue from 'vue';
import QualitativeFrage from '@/components/fragebogen/fragetypen/QualitativeFrage.vue';
import type { Textteil } from '@/components/fragebogen/fragetypen/zusammengesetzterText/ZusammengesetzterText.vue';
import type { QualitativeFrageBackend } from '@/utils/backendTypes';

const defaultExport = Vue.extend({
  components: { QualitativeFrage },
  props: {
    prop: {
      type: Object as PropType<EinfacheFrage>,
      required: true,
    },
  },
  data() {
    return {
      EKLQ011: {
        id: 46,
        categoryId: 9,
        frontendId: 'EKLQ011',
        text: 'Wie werden durch die Organisation THG-Bruttoemissionen nach Scope 3 erfasst?',
        description:
          'Die THG-Bruttoemissionen nach Scope 3 umfassen Emissionen entlang der Wertschöpfungskette einer Organisation. Für viele Organisationen machen die THG-Bruttoemissionen nach Scope 3 den Großteil der Emissionen aus, jedoch sind sie zugleich besonders herausfordernd zu erfassen. Nach dem Rahmenwerk des Greenhouse Gas Protocols umfassen die Scope 3 Emissionen insgesamt 15 Kategorien. Weiterführende Informationen unter: https://ghgprotocol.org/corporate-standard  Im Rahmen der aktuellen ESRS-Entwurfsfassung werden die Scope 3 Emissionen nach folgenden Kategorien aufgeschlüsselt: vorgelagerter Einkauf, nachgelagerte verkaufte Produkte, Warentransport, Reisen und Finanzinvestitionen.',
        responseIntro: 'THG-Bruttoemissionen nach Scope 3 werden',
        summaryQuestionText: 'Scope 3 Emissionen',
        weight: 10,
        showEssential: true,
        CSRD: 'ESRS E1 DR 9',
        reportStandardCSRDId: null,
        DNK: 'DNK 13 Klimarelevante Emissionen, Aspekt 1',
        GRI: 'GRI 305-3',
        PAI: 'PAI 1 - GHG Emissions',
        options: [
          {
            id: 173,
            questionId: 46,
            frontendId: '1',
            text: 'nicht erfasst.',
            order: 1,
            points: 1,
            recommendedAction: null,
          },
          {
            id: 174,
            questionId: 46,
            frontendId: '3',
            text: 'mit Hilfe von Durchschnittswerten vollständig erfasst.',
            order: 3,
            points: 3,
            recommendedAction: null,
          },
          {
            id: 175,
            questionId: 46,
            frontendId: '4',
            text: 'für ausgewählte Kategorien mit Hilfe von konkreten Informationen (z. B. Informationen von Zuliefern oder Dienstleistern) erfasst.',
            order: 4,
            points: 4,
            recommendedAction: null,
          },
          {
            id: 176,
            questionId: 46,
            frontendId: '5',
            text: 'mit Hilfe von konkreten Informationen (z. B. Informationen von Zuliefern oder Dienstleistern) vollständig erfasst.',
            order: 5,
            points: 5,
            recommendedAction: null,
          },
        ],
        responses: [],
      } as QualitativeFrageBackend,
    };
  },
});

export default defaultExport;

export class EinfacheFrage implements Textteil {
  component: Component = defaultExport;

  constructor() {}
}
