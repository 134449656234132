
import mixins from 'vue-typed-mixins';
import type { CategorySection } from '@prisma/client';

import { getUser } from './components/login/login';
import type { ESGStore } from '@/store';
import { useStore } from '@/store';
import { trpcClient } from '@/utils/trpc';
import PlausibiltaetDialog from '@/components/fragebogen/dialog/PlausibiltaetDialog.vue';

import ESGNavigation from '@/components/navigation/ESGNavigation.vue';
import update from '@/mixins/update';
import ESGSnackbar from '@/components/library/ESGSnackbar.vue';

import { QualitativeProgress } from '@/ts/events/QualitativeProgress';
import type { PageTitleAndSubtitleTS } from '@/components/library/PageTitelAndSubtitle.vue';
import PageTitelAndSubtitle from '@/components/library/PageTitelAndSubtitle.vue';

import pageTitleAndSubtitle from '@/components/navigation/pageTitleAndSubtitle';
import LogoutEntry from '@/components/navigation/LogoutEntry.vue';
import OrganizationReportSelector from '@/components/report/OrganizationReportSelector.vue';
import SwitchReportDialog from '@/components/report/SwitchReportDialog.vue';
import { reportId } from '@/router/queryparam';
import { DialogEvents } from '@/ts/events/Dialog';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import { BrandColorChanged } from '@/ts/events/BrandColorChanged';
import { BrandLogoChanged } from '@/ts/events/BrandLogoChanged';
import { ebPurple } from '@/plugins/vuetify';

export default mixins(update).extend({
  name: 'App',
  components: {
    ESGNavigation,
    PlausibiltaetDialog,
    ESGSnackbar,
    OrganizationReportSelector,
    PageTitelAndSubtitle,
    LogoutEntry,
    SwitchReportDialog,
  },
  data() {
    return {
      drawerZeigen: !this.$vuetify.breakpoint.mobile,
      updateKey: '',
      isLoggedIn: getUser() !== null,
      pageTitle: '',
      pageSubtitle: '',
      pageTitleAndSubtitleObject: null as unknown as PageTitleAndSubtitleTS,
      isRegularUser: false,
      store: useStore(),
      showDialogToSwitchReport: false,
      dialogSchließenEventName: DialogEvents.closeDialog,
      storeLoaded: false,
      imageUrl: null as null | string,
    };
  },
  watch: {
    '$route.path': function () {
      this.changeTitleAndSubtitle();
    },
  },
  async created() {
    const user = getUser();
    if (user && user.role === 'REGULAR') {
      await this.setUserData(this.store);
      this.calcShowDialogToSwitchReport(this.store);
    }
    this.storeLoaded = true;
  },
  async mounted() {
    this.changeTitleAndSubtitle();
    this.$root.$on('login', async () => {
      this.isLoggedIn = true;
      const user = getUser();
      if (user && user.role === 'REGULAR') {
        await this.setUserData(this.store);
      }
    });
    this.$root.$on('logout', async () => {
      this.isLoggedIn = false;
    });
    this.$root.$on(
      [BrandColorChanged.eventName, BrandLogoChanged.eventName],
      async () => {
        this.setBranding();
      }
    );

    const user = getUser();
    if (user && user.role === 'REGULAR') {
      await this.setUserData(this.store);
    }

    window.addEventListener('storage', this.userLoggedInListener);
  },
  methods: {
    userLoggedInListener(event: StorageEvent) {
      if (event.key === 'user') {
        location.reload();
      }
    },
    changeTitleAndSubtitle() {
      this.pageTitleAndSubtitleObject = pageTitleAndSubtitle(this.$route);
    },
    async setUserData(store: ESGStore) {
      this.isRegularUser = true;
      await this.loadProgress();
      await store.setOrganizationReports();

      await this.setBranding();
    },
    async setBranding() {
      const branding = await trpcClient.branding.getBranding.query();
      let imageUrl = null;
      if (branding?.brandLogoMimeType && branding?.brandLogo) {
        imageUrl = `data:${branding.brandLogoMimeType};base64,${branding.brandLogo}`;
      }
      this.imageUrl = imageUrl;
      let primaryColor = ebPurple;
      if (branding?.brandColor) {
        primaryColor = branding.brandColor;
      }
      this.$vuetify.theme.currentTheme.primary = primaryColor;
    },
    calcShowDialogToSwitchReport(store: ESGStore) {
      const reportIdFromURL = reportId.parameterWertLesenNumber(this.$route);

      if (reportIdFromURL === null) {
        return;
      }

      const userHasNoAccessToReport = !store.organizations.find((org) =>
        org.organizationReports.find((report) => report.id === reportIdFromURL)
      );
      if (userHasNoAccessToReport) {
        const snackbarText = `Der entsprechende Bericht konnte nicht gefunden werden. Entweder existiert dieser Bericht nicht oder Sie haben keinen Zugriff auf diesen Bericht.`;
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('info', snackbarText)
        );
        return;
      }
      const selectedOrganizationReportId = store.selectedOrganizationReportId;
      if (reportIdFromURL !== selectedOrganizationReportId) {
        this.showDialogToSwitchReport = true;
      }
    },
    async loadProgress() {
      const allQualitativeProgress =
        await trpcClient.question.getAllQualitativeProgress.query();

      for (const [categorySectionString, percent] of Object.entries(
        allQualitativeProgress
      )) {
        const categorySection = categorySectionString as CategorySection;
        this.$root.$emit(
          QualitativeProgress.eventName(categorySection),
          new QualitativeProgress(categorySection, percent)
        );
      }
    },

    removeFrageIds() {
      const elements = Array.from(
        document.querySelectorAll('.js-remove-on-double-click')
      ) as HTMLElement[];
      elements.forEach((x) => {
        if (x.style.display === 'none') {
          x.style.display = 'inline';
        } else {
          x.style.display = 'none';
        }
      });
    },
    useRequireForWebpackToFindAssets() {
      return require('@/assets/eb-logo.svg');
    },
    async rerenderApp() {
      this.updateKey = new Date().toString();
      await this.loadProgress();
    },
  },
});
