
import type { PropType } from 'vue';
import Vue from 'vue';
import ZusammengesetzterText from '@/components/fragebogen/fragetypen/zusammengesetzterText/ZusammengesetzterText.vue';
import type KartenInhalt from '@/components/homepage/KartenInhalt';

export default Vue.extend({
  components: { ZusammengesetzterText },
  props: {
    kartenTitel: {
      type: String,
      required: true,
    },
    ersteKarte: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonText: {
      type: String,
      required: false,
      default: undefined,
    },
    wirdAngezeigt: {
      type: Boolean,
      required: true,
    },
    htmlKartenBody: {
      type: Object as PropType<KartenInhalt>,
      required: false,
      default: undefined,
    },
    cardWithBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
    karteId: {
      type: String,
      required: true,
    },
  },
  computed: {
    dataTestIdKartenTitelValue(): string {
      return `title-${this.kartenTitel}`;
    },
    dataTestIdWeiterButtonValue(): string {
      return `button-${this.buttonText}`;
    },
  },
});
