import type { Route } from 'vue-router';
import { CategorySection } from '@prisma/client';
import type { UserExtendedRole } from '@/ts/types/generic';

export class NavigationsElternPunkt {
  nameInDerNavigation: string;
  children: Array<NavigationsPunkt>;
  oberkategorieName?: String;

  constructor(
    nameInDerNavigation: string,
    children: Array<NavigationsPunkt>,
    oberkategorieName?: String
  ) {
    this.nameInDerNavigation = nameInDerNavigation;
    this.children = children;
    this.oberkategorieName = oberkategorieName;
  }
}
export class NavigationsPunkt {
  pathInDerNavigation: string;
  nameInDerNavigation: string;
  icon: string;
  categorySection?: CategorySection;

  constructor(
    pathInDerNavigation: string,
    nameInDerNavigation: string,
    icon: string,
    categorySection?: CategorySection
  ) {
    this.pathInDerNavigation = pathInDerNavigation;
    this.nameInDerNavigation = nameInDerNavigation;
    this.icon = icon;
    this.categorySection = categorySection;
  }
}
export const allgemeinOrganisation = new NavigationsPunkt(
  '/fragen/quantitativ/organisation',
  'Organisation',
  'mdi-account'
);

export const homeFurtherInfo = new NavigationsPunkt(
  '/weitere-informationen',
  'Weitere Informationen',
  'mdi-information'
);

export const allgemeineAngaben = new NavigationsPunkt(
  '/fragen/qualitativ/allgemein',
  'Allgemein',
  'mdi-strategy',
  CategorySection.QUALITATIVE_GENERAL
);

export const qualitativEnvironment = new NavigationsPunkt(
  '/fragen/qualitativ/environment',
  'Environment',
  'mdi-leaf',
  CategorySection.QUALITATIVE_ENVIRONMENT
);

export const qualitativSocial = new NavigationsPunkt(
  '/fragen/qualitativ/social',
  'Social',
  'mdi-account-group',
  CategorySection.QUALITATIVE_SOCIAL
);

export const qualitativGovernance = new NavigationsPunkt(
  '/fragen/qualitativ/governance',
  'Governance',
  'mdi-scale-balance',
  CategorySection.QUALITATIVE_GOVERNANCE
);

export const qualitativAuswertung = new NavigationsPunkt(
  '/auswertung/qualitativ',
  'Auswertung',
  'mdi-chart-arc'
);

export const qualitativVrEsgRisiko = new NavigationsPunkt(
  '/fragen/qualitativ/vr-esg-risiko',
  'VR-Qualitative Fragen',
  'mdi-file-question'
);

export const quantitativVrEsgRisiko = new NavigationsPunkt(
  '/fragen/quantitativ/vr-esg-risiko',
  'VR-Quantitative Fragen',
  'mdi-clipboard-list'
);

export const overviewVrEsgRisiko = new NavigationsPunkt(
  '/fragen/vr-esg-risiko/overview',
  'Übersicht',
  'mdi-download'
);

export const quantitativEnvironment = new NavigationsPunkt(
  '/fragen/quantitativ/environment',
  'Environment',
  'mdi-leaf'
);
export const quantitativSocial = new NavigationsPunkt(
  '/fragen/quantitativ/social',
  'Social',
  'mdi-account-group'
);
export const quantitativGovernance = new NavigationsPunkt(
  '/fragen/quantitativ/governance',
  'Governance',
  'mdi-scale-balance'
);
export const quantitativAuswertung = new NavigationsPunkt(
  '/auswertung/quantitativ',
  'Übersicht',
  'mdi-chart-arc'
);
export const quantitativGradzielAuswertung = new NavigationsPunkt(
  '/auswertung/gradziel',
  '1,5 Gradziel',
  'mdi-bullseye-arrow'
);

export const quantitativAuswertungUpload = new NavigationsPunkt(
  '/auswertung/quantitativ/upload',
  'Antworten hochladen',
  'mdi-upload'
);

export const homeEntry = new NavigationsPunkt('/entry', 'Einstieg', 'mdi-home');

export const organizationList = new NavigationsPunkt(
  '/organizations',
  'Organisationen',
  'mdi-office-building'
);

export const parentOrganizationAsOwner = new NavigationsPunkt(
  '/organization',
  'Verwaltung',
  'mdi-sitemap'
);

export const subOrganizationAsOwner = new NavigationsPunkt(
  '/management',
  'Verwaltung',
  'mdi-sitemap'
);

export const reportTemplateList = new NavigationsPunkt(
  '/report-template',
  'Berichte',
  'mdi-file-chart'
);

export const editorJs = new NavigationsPunkt(
  '/editorjs',
  'Editor',
  'mdi-file-document-edit'
);

export const co2Reports = new NavigationsPunkt(
  '/co2reports',
  '1,5 Gradziel Berichte',
  'mdi-bullseye-arrow'
);
export const faqs = new NavigationsPunkt(
  '/faqs',
  'FAQs',
  'mdi-frequently-asked-questions'
);

export const contact = new NavigationsPunkt(
  '/contact',
  'Kontakt',
  'mdi-tooltip-question'
);

export const navigationRegular = (
  userExtendedRole: UserExtendedRole,
  aggregatedReport: boolean
): Array<NavigationsElternPunkt> => {
  const userIsParentOrgOwner = userExtendedRole === 'PARENT_ORG_OWNER';
  const userIsSubOrgOwner = userExtendedRole === 'SUB_ORG_OWNER';

  const orgUserManagement: NavigationsPunkt[] = [];

  if (userIsParentOrgOwner) {
    orgUserManagement.push(parentOrganizationAsOwner);
  }

  if (userIsSubOrgOwner) {
    orgUserManagement.push(subOrganizationAsOwner);
  }

  const navigation: NavigationsElternPunkt[] = [
    new NavigationsElternPunkt('Informationen', [
      homeEntry,
      homeFurtherInfo,
      faqs,
    ]),
  ];

  navigation.push(
    new NavigationsElternPunkt('Startseite', [...orgUserManagement])
  );

  if (aggregatedReport === false) {
    navigation.push(
      new NavigationsElternPunkt('VR-ESG-RisikoScore', [
        qualitativVrEsgRisiko,
        quantitativVrEsgRisiko,
        overviewVrEsgRisiko,
      ]),
      new NavigationsElternPunkt('Allgemein', [allgemeinOrganisation]),
      new NavigationsElternPunkt('Qualitativ', [
        allgemeineAngaben,
        qualitativEnvironment,
        qualitativSocial,
        qualitativGovernance,
        qualitativAuswertung,
      ])
    );
  }
  navigation.push(
    new NavigationsElternPunkt('Quantitativ', [
      quantitativEnvironment,
      quantitativSocial,
      quantitativGovernance,
      quantitativAuswertung,
      quantitativAuswertungUpload,
      quantitativGradzielAuswertung,
    ])
  );

  navigation.push(
    new NavigationsElternPunkt('Unterstützung', [
      contact
    ])
  );

  return navigation;
};

export const navigationAdmin: Array<NavigationsElternPunkt> = [
  new NavigationsElternPunkt('Verwaltung', [
    organizationList,
    reportTemplateList,
    co2Reports,
    editorJs,
  ]),
];

const nextNavigationPointObject: { [key: string]: NavigationsPunkt } = {
  [qualitativVrEsgRisiko.pathInDerNavigation]: quantitativVrEsgRisiko,
  [quantitativVrEsgRisiko.pathInDerNavigation]: overviewVrEsgRisiko,
  [overviewVrEsgRisiko.pathInDerNavigation]: allgemeinOrganisation,

  [allgemeinOrganisation.pathInDerNavigation]: allgemeineAngaben,

  [allgemeineAngaben.pathInDerNavigation]: qualitativEnvironment,
  [qualitativEnvironment.pathInDerNavigation]: qualitativSocial,
  [qualitativSocial.pathInDerNavigation]: qualitativGovernance,
  [qualitativGovernance.pathInDerNavigation]: qualitativAuswertung,
  [qualitativAuswertung.pathInDerNavigation]: quantitativEnvironment,

  [quantitativEnvironment.pathInDerNavigation]: quantitativSocial,
  [quantitativSocial.pathInDerNavigation]: quantitativGovernance,
  [quantitativGovernance.pathInDerNavigation]: quantitativAuswertung,
  [quantitativAuswertung.pathInDerNavigation]: quantitativAuswertungUpload,
  [quantitativAuswertungUpload.pathInDerNavigation]:
    quantitativGradzielAuswertung,
};

export function nextNavigationPoint(route: Route) {
  return nextNavigationPointObject[route.path];
}
