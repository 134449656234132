import type { CategorySection } from '@prisma/client';
export class QualitativeProgress {
  categorySection: CategorySection;
  percent: number;
  showConfetti: boolean;

  constructor(
    categorySection: CategorySection,
    percent: number,
    showConfetti = false
  ) {
    this.categorySection = categorySection;
    this.percent = percent;
    this.showConfetti = showConfetti;
  }

  public static eventName(categorySection: CategorySection): string {
    return JSON.stringify({ name: 'QualitativeProgress', categorySection });
  }
}
