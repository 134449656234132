
import type { ApexOptions } from 'apexcharts';
import Vue from 'vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import PdfExportButton from '@/components/library/PdfExportButton.vue';
import { generateAndDownloadReportPdf } from '@/utils/pdfExport';
import { xdcChartColors } from '@/ts/Colors';

interface TableRow {
  question: string;
  response: string;
  editLink: string | undefined;
  isInfoRow: boolean | undefined;
  isScopeCorrectRow: boolean | undefined;
  isScopeExtraValueRow: boolean | undefined;
  isScopeTotalRow: boolean | undefined;
  isSubheaderRow: boolean | undefined;
  scope: 1 | 2 | 3;
}

interface Co2ReportData {
  reportYear: number | null;
  organization: string | null;
  countryCode: number | null;
  gva: number | null;
  naceCode: number | null;
  // scope 1
  scope1: number | null;
  isScope1Correct?: boolean | null | undefined;
  scope1ExtraValue?: number | null | undefined;
  scope1AdjustedValue?: number | null | undefined;
  // scope 2
  scope2: number | null;
  isScope2Correct?: boolean | null | undefined;
  scope2ExtraValue?: number | null | undefined;
  scope2AdjustedValue?: number | null | undefined;
  // scope 3
  scope3: number | null;
  isScope3Correct?: boolean | null | undefined;
  scope3ExtraValue?: number | null | undefined;
  scope3AdjustedValue?: number | null | undefined;
  isScope3Proxied: boolean | null;
}

export default Vue.extend({
  components: {
    PdfExportButton,
  },
  data() {
    return {
      pdfExportLoading: false,
      payloadData: null as Co2ReportData | null,
      isReadyToCalculate: null as boolean | null,
      temperatures: null as any,
      target: null as any,
      baseYear: 2020,
      organizationName: null as string | null,
      dialog: false,
      isCalculating: false,
      isDataObsolete: false,
      scopeValues: {
        1: undefined as number | undefined,
        2: undefined as number | undefined,
        3: undefined as number | undefined,
      },
      isScopeCorrectSelectItems: [
        { label: 'Ja', value: true },
        { label: 'Nein', value: false },
      ],
      isScopeCorrect: {
        1: undefined as boolean | undefined,
        2: undefined as boolean | undefined,
        3: undefined as boolean | undefined,
      },
      scopeExtraValues: {
        1: undefined as number | undefined,
        2: undefined as number | undefined,
        3: undefined as number | undefined,
      },
      editedScope: 0 as 1 | 2 | 3,
      dialogNewReport: false,
    };
  },
  computed: {
    hasReportData() {
      return !!this.temperatures && !!this.target;
    },
    dataTable(): TableRow[] {
      if (this.payloadData) {
        const co2ReportData = this.payloadData;

        const naceOptions = {
          1: 'Gesundheitswesen (NACE Sektor 86)',
          2: 'Heime (NACE Sektor 87)',
          3: 'Sozialwesen (NACE Sektor 88)',
        };

        const countryOptions = {
          1: 'Deutschland',
          2: 'Österreich',
          3: 'Schweiz',
        };

        return [
          {
            isInfoRow: true,
            question: 'Name der Organisation',
            response: co2ReportData.organization || '-',
            editLink: '/fragen/quantitativ/organisation',
            scope: 0,
          },
          {
            isInfoRow: true,
            question: 'Berichtsjahr',
            response: co2ReportData.reportYear,
            scope: 0,
          },
          {
            isInfoRow: true,
            question: 'NACE Code',
            response:
              naceOptions[co2ReportData.naceCode as keyof typeof naceOptions] ||
              '-',
            editLink: '/fragen/quantitativ/organisation',
            scope: 0,
          },
          {
            isInfoRow: true,
            question: 'Land',
            response:
              countryOptions[
                co2ReportData.countryCode as keyof typeof countryOptions
              ] || '-',
            editLink: '/fragen/quantitativ/organisation',
            scope: 0,
          },
          {
            isInfoRow: true,
            question: 'Gross Value Added (GVA)',
            response: co2ReportData.gva
              ? `${co2ReportData.gva.toFixed(2)} (Euro)`
              : '-',
            editLink: '/fragen/quantitativ/organisation',
            scope: 0,
          },
          {
            isSubheaderRow: true,
            question: 'Scope 1',
          },
          {
            isInfoRow: true,
            question: 'Emissionen Scope 1',
            response: `${
              co2ReportData.scope1?.toFixed(2) ?? '-'
            } CO₂ - Tonnenäquivalent`,
            editLink: '/fragen/quantitativ/environment',
            scope: 1,
          },
          {
            isScopeCorrectRow: true,
            question: 'Ist der Wert für den Scope 1 korrekt?',
            scope: 1,
          },
          {
            isScopeExtraValueRow: true,
            question: 'Abweichung Scope 1',
            response: co2ReportData.scope1ExtraValue
              ? `${co2ReportData.scope1ExtraValue.toFixed(
                  2
                )} (CO₂ - Tonnenäquivalent)`
              : '-',
            scope: 1,
          },
          {
            isScopeTotalRow: true,
            question: 'Gesamt Emissionen Scope 1 (inkl. Abweichung)',
            response: `${
              co2ReportData.scope1AdjustedValue?.toFixed(2) ??
              co2ReportData.scope1?.toFixed(2) ??
              '-'
            } CO₂ - Tonnenäquivalent`,
            scope: 1,
          },
          {
            isSubheaderRow: true,
            question: 'Scope 2',
          },
          {
            isInfoRow: true,
            question: 'Emissionen Scope 2',
            response: `${
              co2ReportData.scope2?.toFixed(2) ?? '-'
            } CO₂ - Tonnenäquivalent`,
            editLink: '/fragen/quantitativ/environment',
            scope: 2,
          },
          {
            isScopeCorrectRow: true,
            question: 'Ist der Wert für den Scope 2 korrekt?',
            scope: 2,
          },
          {
            isScopeExtraValueRow: true,
            question: 'Abweichung Scope 2',
            response: co2ReportData.scope2ExtraValue
              ? `${co2ReportData.scope2ExtraValue.toFixed(
                  2
                )} (CO₂ - Tonnenäquivalent)`
              : '-',
            scope: 2,
          },
          {
            isScopeTotalRow: true,
            question: 'Gesamt Emissionen Scope 2 (inkl. Abweichung)',
            response: `${
              co2ReportData.scope2AdjustedValue?.toFixed(2) ??
              co2ReportData.scope2?.toFixed(2) ??
              '-'
            } CO₂ - Tonnenäquivalent`,
            scope: 2,
          },
          {
            isSubheaderRow: true,
            question: 'Scope 3',
          },
          {
            isInfoRow: true,
            question: 'Emissionen Scope 3',
            response: `${
              co2ReportData.scope3?.toFixed(2) ?? '-'
            } CO₂ - Tonnenäquivalent ${
              co2ReportData.isScope3Proxied
                ? '(wird basierend auf den Werten von Scope 1 und Scope 2 berechnet)'
                : ''
            }`,

            scope: 3,
          },
          {
            isScopeCorrectRow: true,
            question: 'Ist der Wert für den Scope 3 korrekt?',
            scope: 3,
          },
          {
            isScopeExtraValueRow: true,
            question: 'Abweichung Scope 3',
            response: co2ReportData.scope3ExtraValue
              ? `${co2ReportData.scope3ExtraValue.toFixed(
                  2
                )} (CO₂ - Tonnenäquivalent)`
              : '-',
            scope: 3,
          },
          {
            isScopeTotalRow: true,
            question: 'Gesamt Emissionen Scope 3 (inkl. Abweichung)',
            response: `${
              co2ReportData.scope3AdjustedValue?.toFixed(2) ??
              co2ReportData.scope3?.toFixed(2) ??
              '-'
            } CO₂ - Tonnenäquivalent ${
              co2ReportData.isScope3Proxied
                ? '(wird basierend auf den Werten von Scope 1 und Scope 2 berechnet)'
                : ''
            }`,
            scope: 3,
          },
        ] as TableRow[];
      }
      return [];
    },
    chartOptionsTemperature(): ApexOptions {
      const chartOptions = this.generateChartOptions();

      chartOptions.yaxis = {
        min: 0,
      };
      chartOptions.yaxis = {
        labels: {
          formatter(val) {
            return `${val.toFixed(1)} °C`;
          },
        },
      };
      return chartOptions;
    },

    chartOptionsTarget(): ApexOptions {
      const chartOptions = this.generateChartOptions();

      chartOptions.yaxis = {
        labels: {
          formatter(val) {
            return `${Math.round(val)} Tonnen/CO₂`;
          },
        },
      };

      // chartOptions.stroke = {
      //   curve: 'smooth',
      //   width: [5,7,5],
      //   dashArray: [0, 8, 5],
      // };

      // chartOptions.colors = [
      //   'red',
      //   'green',
      //   // ebColors['eb-dark-vio'],
      //   ebColors['eb-lilac'],
      // ];

      // console.log(chartOptions);
      return chartOptions;
    },
    temperatureTotalScopesSeries(): any {
      return [
        {
          name: 'Total Scopes',
          data: this.temperatures.totalScope,
        },
      ];
    },
    targetTotalScopesSeries(): any {
      return [
        {
          name: 'Total Scopes',
          data: this.target.emissions.total_scope,
        },
      ];
    },
  },
  created() {
    this.handleOnCreated();
  },
  methods: {
    async handleOnCreated() {
      const report = await trpcClient.co2Report.getOneByOrg.query();

      if (report) {
        this.payloadData = report.payload as Co2ReportData;
        this.temperatures = report.temperatures;
        this.target = report.target;
        this.baseYear = report.payload.reportYear as number;
        this.organizationName = report.payload.organization as string;
        this.isDataObsolete = report.isDataObsolete;
        this.setScopeValues();

        return;
      }
      this.fetchPayloadData();
    },
    resetData() {
      this.payloadData = null;
      this.isReadyToCalculate = false;
      this.temperatures = null;
      this.target = null;
      this.baseYear = 2020;
      this.organizationName = null;
      this.isDataObsolete = false;
    },
    generateChartOptions(): ApexOptions {
      const years = Array.from({ length: 2100 - this.baseYear + 1 }).map(
        (_, i) => this.baseYear + i
      );

      return {
        chart: {
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        colors: xdcChartColors(
          this.$vuetify.theme.currentTheme.primary! as string
        ),
        tooltip: {
          x: {
            show: true,
            formatter(val) {
              return `${years[val - 1]}`;
            },
          },
        },
        xaxis: {
          labels: {
            rotate: -90,
            formatter(val) {
              return parseInt(val) % 5 === 0 ? val : '';
            },
          },
          categories: years,
        },
      };
    },
    async createReport() {
      try {
        this.dialog = false;
        this.isCalculating = true;

        const res = await trpcClient.co2Report.create.mutate();
        this.temperatures = res.temperatures;
        this.target = res.target;
        this.baseYear = res.baseYear;
        this.organizationName = res.payload.organization as string;
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
      } finally {
        this.isCalculating = false;
      }
    },
    setScopeValues() {
      const co2ReportPayloadData = this.payloadData as Co2ReportData;

      for (const i of [1, 2, 3] as const) {
        // set scopeValues
        const scopeValue = co2ReportPayloadData[`scope${i}`];
        this.scopeValues[i] = scopeValue || undefined;

        // set isScopeCorrect values
        const isScopeCorrect = co2ReportPayloadData[`isScope${i}Correct`];
        if (isScopeCorrect !== undefined && isScopeCorrect !== null) {
          this.isScopeCorrect[i] = isScopeCorrect;
        }

        // set scopeExtraValues values
        const scopeExtraValue = co2ReportPayloadData[`scope${i}ExtraValue`];
        if (scopeExtraValue !== undefined || scopeExtraValue !== null) {
          this.scopeExtraValues[i] = scopeExtraValue as number;
        }
      }
    },
    async fetchPayloadData() {
      const { co2ReportPayloadData, allCo2ReportPayloadDataExists } =
        await trpcClient.co2Report.getPayloadData.query();

      this.payloadData = co2ReportPayloadData;
      this.setScopeValues();
      this.isReadyToCalculate = allCo2ReportPayloadDataExists;
    },
    async regenerateReport() {
      try {
        await trpcClient.co2Report.outdateReport.mutate();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
      }
      this.resetData();
      this.dialogNewReport = false;
      this.handleOnCreated();
    },
    async printPage() {
      const pdfUrl = `${process.env.VUE_APP_API_URL || ''}/pdf-export-gradziel`;
      const fileNamePrefix = 'export-gradziel';

      this.pdfExportLoading = true;
      try {
        await generateAndDownloadReportPdf(pdfUrl, fileNamePrefix);
      } catch (error) {
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', 'Error generating PDF')
        );
      } finally {
        this.pdfExportLoading = false;
      }
    },
    temperatureByScopesSeries(scope: number | undefined = undefined) {
      if (scope) {
        return [
          {
            name: `Scope ${scope}`,
            data: this.temperatures[`scope${scope}`],
          },
        ];
      }

      return [
        {
          name: 'Scope 1',
          data: this.temperatures.scope1,
        },
        {
          name: 'Scope 2',
          data: this.temperatures.scope2,
        },
        {
          name: 'Scope 3',
          data: this.temperatures.scope3,
        },
      ];
    },
    targetByScopesSeries(scope: number | undefined = undefined) {
      if (scope) {
        return [
          {
            name: `Scope ${scope}`,
            data: this.target.emissions[`scope${scope}`],
          },
        ];
      }

      return [
        {
          name: 'Scope 1',
          data: this.target.emissions.scope1,
        },
        {
          name: 'Scope 2',
          data: this.target.emissions.scope2,
        },
        {
          name: 'Scope 3',
          data: this.target.emissions.scope3,
        },
      ];
    },

    getTemperatureByYear(year: number, scope = 'totalScope') {
      let tempArray: number[];
      if (
        this.temperatures &&
        this.temperatures[scope] &&
        this.temperatures[scope].length !== 0
      ) {
        tempArray = this.temperatures[scope];
      } else {
        return 0;
      }

      const baseYear = this.baseYear;
      const index = year - baseYear;
      return tempArray[index] || 0;
    },

    getEmissionReductionByYear(year: number, scope: number) {
      let targetArray: number[];
      if (
        this.target?.emissions &&
        this.target.emissions[`scope${scope}`] &&
        this.target.emissions[`scope${scope}`].length !== 0
      ) {
        targetArray = this.target.emissions[`scope${scope}`];
      } else {
        return 0;
      }

      const baseYear = this.baseYear;
      const index = year - baseYear;

      let emissionBaseYear = 0;
      if (targetArray[0]) {
        emissionBaseYear = targetArray[0];
      } else {
        return 0;
      }

      let emissionTarget = 0;
      if (targetArray[index]) {
        emissionTarget = targetArray[index];
      } else {
        return 0;
      }

      return (
        ((emissionBaseYear - emissionTarget) / emissionBaseYear) *
        100
      ).toFixed(2);
    },
    async handleIsScopeCorrectChange(scope: 1 | 2 | 3) {
      try {
        await trpcClient.co2Report.updateCurrent.mutate({
          [`isScope${scope}Correct`]: this.isScopeCorrect[scope],
          isScope3Proxied: this.payloadData?.isScope3Proxied ?? false,
        });
        if (this.isScopeCorrect[scope]) {
          this.scopeExtraValues[scope] = 0;
          await trpcClient.co2Report.updateCurrent.mutate({
            [`scope${scope}ExtraValue`]: Number(this.scopeExtraValues[scope]),
            isScope3Proxied: this.payloadData?.isScope3Proxied ?? false,
          });
        }
        await this.fetchPayloadData();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
        await this.fetchPayloadData();
      }
    },
    async updateScopeExtraValue(scope: 1 | 2 | 3) {
      try {
        await trpcClient.co2Report.updateCurrent.mutate({
          [`scope${scope}ExtraValue`]: Number(this.scopeExtraValues[scope]),
          isScope3Proxied: this.payloadData?.isScope3Proxied ?? false,
        });
        await this.fetchPayloadData();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
        await this.fetchPayloadData();
      }
    },
  },
});
