
import Vue from 'vue';

import { getUser } from '../login/login';
import { trpcClient } from '@/utils/trpc';
import IconWithTooltip from '@/components/library/IconWithTooltip.vue';

export default Vue.extend({
  components: {
    IconWithTooltip,
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async logout() {
      if (getUser()) {
        localStorage.removeItem('user');
      }

      try {
        await trpcClient.user.logout.mutate();
      } catch (error) {}

      this.$root.$emit('logout');

      this.$router.push('/login');
      // todo[N3X-8011]: need to clean all data on logout. atm, handled with page reload
      location.reload();
    },
  },
});
