
import Vue from 'vue';

import { trpcClient } from '@/utils/trpc';
import { file2Base64 } from '@/ts/Files';
import type { SnackbarAnzeigenType } from '@/ts/events/SnackbarAnzeigen';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import { getErrorMessage } from '@/utils/generic';
// import { BrandLogoChanged } from '@/ts/events/BrandLogoChanged';
import { BrandColorChanged } from '@/ts/events/BrandColorChanged';
import { getUser } from '@/components/login/login';
import type { LoggedInUser } from '@/ts/types/generic';
import { getOrganizationIdFromUser } from '@/utils/user';
import { ebPurple } from '@/plugins/vuetify';

export default Vue.extend({
  name: 'OrganizationBranding',
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
    initialColor: {
      type: String,
      default: ebPurple,
    },
    initialLogo: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentImage: null as File | null,
      previewImage: this.initialLogo || null as string | null,
      isUploadImageLoading: false,
      isCurrentImageValid: false,
      primaryColor:
        this.initialColor ||
        (this.$vuetify.theme.currentTheme.primary as string),
      isUploadColorLoading: false,
      fileErrorMessages: [] as string[],
    };
  },
  watch: {
    initialColor(val) {
      this.primaryColor = val;
    },
  },
  methods: {
    setSelectedImage(file: File) {
      if (!file) {
        this.currentImage = null;
        this.isCurrentImageValid = false;
        this.previewImage = null;
        return;
      }

      // Validate file size and type
      const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (file.size <= 2_097_152 && validTypes.includes(file.type)) {
        this.currentImage = file;
        this.isCurrentImageValid = true;
        this.previewImage = URL.createObjectURL(this.currentImage);
        this.fileErrorMessages = [];
      } else {
        this.fileErrorMessages = [
          'Datei muss kleiner als 2 MB sein und im JPEG, PNG, GIF oder WEBP-Format.',
        ];
        this.currentImage = null;
        this.isCurrentImageValid = false;
        this.previewImage = null;
      }
    },
    resetBranding() {
      const user = getUser() as LoggedInUser;
      const userOrganizationId = getOrganizationIdFromUser(user);

      this.$emit('refetch-data');

      if (userOrganizationId === this.organizationId) {
        this.$root.$emit(BrandColorChanged.eventName);
      }
    },
    async setColor(isResetAction = false) {
      this.isUploadColorLoading = true;

      let snackbarText = `Primärfarbe erfolgreich geändert.`;
      let snackbarType: SnackbarAnzeigenType = 'success';

      const newColorValue = isResetAction ? null : this.primaryColor;

      await trpcClient.branding.setColor
        .mutate({
          organizationId: this.organizationId,
          color: newColorValue,
        })
        .then(() => {
          this.resetBranding();
        })
        .catch((e) => {
          snackbarText = `Primärfarbe nicht erfolgreich geändert. Fehler:${getErrorMessage(
            e
          )}`;
          snackbarType = 'error';
        })
        .finally(() => (this.isUploadColorLoading = false));

      this.$root.$emit(
        SnackbarAnzeigen.eventName,
        new SnackbarAnzeigen(snackbarType, snackbarText)
      );
    },
    async setLogo() {
      if (this.currentImage) {
        this.isUploadImageLoading = true;

        let snackbarText = `Bilddatei erfolgreich geändert.`;
        let snackbarType: SnackbarAnzeigenType = 'success';

        const file2Base64Content = await file2Base64(this.currentImage);

        await trpcClient.branding.setBrandLogo
          .mutate({
            organizationId: this.organizationId,
            image: file2Base64Content,
          })
          .then(() => {
            this.resetBranding();
          })
          .catch((error) => {
            snackbarType = 'error';
            snackbarText = `Bilddatei nicht erfolgreich geändert. Fehler: ${getErrorMessage(
              error
            )}`;
          })
          .finally(() => (this.isUploadImageLoading = false));

        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen(snackbarType, snackbarText)
        );
      }
    },
    async resetLogo() {
      this.isUploadImageLoading = true;

      let snackbarText = `Bilddatei erfolgreich zurückgesetzt.`;
      let snackbarType: SnackbarAnzeigenType = 'success';

      await trpcClient.branding.deleteLogo
        .mutate({
          organizationId: this.organizationId,
        })
        .then(() => {
          this.previewImage = null;
          this.resetBranding();
        })
        .catch((error) => {
          snackbarType = 'error';
          snackbarText = `Bilddatei nicht erfolgreich geändert. Fehler: ${getErrorMessage(
            error
          )}`;
        })
        .finally(() => (this.isUploadImageLoading = false));

      this.$root.$emit(
        SnackbarAnzeigen.eventName,
        new SnackbarAnzeigen(snackbarType, snackbarText)
      );
    },
  },
});
