
import Vue from 'vue';
import { CategorySection } from '@prisma/client';
import { trpcClient } from '@/utils/trpc';
import {
  downloadFileInsideFrontend,
  getErrorMessage,
  getFileName,
} from '@/utils/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import type {
  QuantitativeReportDataPesponsesPreviousYear,
  QuantitativeReportDataResponses,
  QuantitativeReportDataStructure,
} from '@/utils/backendTypes';
import QuantitativExpansionPanelsNew from '@/components/fragebogen/quantitativ/QuantitativExpansionPanelsNew.vue';
import PdfExportButton from '@/components/library/PdfExportButton.vue';
import { generateAndDownloadReportPdf } from '@/utils/pdfExport';

export default Vue.extend({
  components: {
    QuantitativExpansionPanelsNew,
    PdfExportButton,
  },

  data() {
    return {
      isExportLoading: false,
      responses: null as unknown as QuantitativeReportDataResponses,
      structure: null as unknown as QuantitativeReportDataStructure,
      responsesPreviousYear:
        null as unknown as QuantitativeReportDataPesponsesPreviousYear,
      error: null as null | string,
      environment:
        null as unknown as QuantitativeReportDataStructure['QUANTITATIVE_ENVIRONMENT'],
      social:
        null as unknown as QuantitativeReportDataStructure['QUANTITATIVE_SOCIAL'],
      governance:
        null as unknown as QuantitativeReportDataStructure['QUANTITATIVE_GOVERNANCE'],
    };
  },
  computed: {
    hasReportData(): boolean {
      return (
        (this.environment?.length || 0) > 0 ||
        (this.social?.length || 0) > 0 ||
        (this.governance?.length || 0) > 0
      );
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async printPage() {
      const pdfUrl = `${
        process.env.VUE_APP_API_URL || ''
      }/pdf-export-quantitativ`;
      const fileNamePrefix = 'export-quantitativ';

      this.isExportLoading = true;
      try {
        await generateAndDownloadReportPdf(pdfUrl, fileNamePrefix);
      } catch (error) {
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', 'Error generating PDF')
        );
      } finally {
        this.isExportLoading = false;
      }
    },
    async downloadCSV() {
      try {
        this.isExportLoading = true;

        const csvContent =
          await trpcClient.response.getQuantitativeDataCSV.query();

        downloadFileInsideFrontend(
          csvContent,
          'export_quantitativ_auswertung.csv',
          'csv'
        );
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
      } finally {
        this.isExportLoading = false;
      }
    },
    async downloadDocx() {
      try {
        this.isExportLoading = true;
        const docxContentBase64 =
          await trpcClient.documentsDOCX.downloadQuantitativeReport.query();

        const binaryString = window.atob(docxContentBase64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const fileType = 'docx';
        const fileName = getFileName('nachhaltigkeitserklaerung', fileType);

        downloadFileInsideFrontend(bytes, fileName, fileType);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
      } finally {
        this.isExportLoading = false;
      }
    },
    async fetchData() {
      const reportData =
        await trpcClient.question.getQuantitativeReportData.query();
      this.structure = reportData.structure;
      this.environment =
        this.structure[CategorySection.QUANTITATIVE_ENVIRONMENT]!;
      this.social = this.structure[CategorySection.QUANTITATIVE_SOCIAL]!;
      this.governance =
        this.structure[CategorySection.QUANTITATIVE_GOVERNANCE]!;
      this.responses = reportData.responses;
      this.responsesPreviousYear = reportData.responsesPreviousYear;
    },
  },
});
