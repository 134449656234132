
import type { Component, PropType } from 'vue';
import Vue from 'vue';

import type { QuantitativeElementTypes } from '@prisma/client';
import { dataAttributFrageId } from '@/ts/DataAttribute';
import SevenColumnHeader from '@/components/fragebogen/quantitativ/SevenColumnHeader.vue';
import TwoColumnHeader from '@/components/fragebogen/quantitativ/TwoColumnHeader.vue';
import CsrdDialog from '@/components/fragebogen/dialog/CsrdDialog.vue';
import DnkDialog from '@/components/fragebogen/dialog/DnkDialog.vue';
import GriDialog from '@/components/fragebogen/dialog/GriDialog.vue';
import PaiDialog from '@/components/fragebogen/dialog/PaiDialog.vue';
import KommentarFeld from '@/components/library/KommentarFeld.vue';
import EmptyLine from '@/components/fragebogen/quantitativ/tabellenDarstellung/EmptyLine.vue';
import QuantitativeNumberInput from '@/components/fragebogen/quantitativ/QuantitativeNumberInput.vue';
import SubheadingElement from '@/components/fragebogen/quantitativ/tabellenDarstellung/SubheadingElement.vue';
import TableHeadingsElement from '@/components/fragebogen/quantitativ/tabellenDarstellung/TableHeadingsElement.vue';
import QuantitativeTextInput from '@/components/fragebogen/quantitativ/QuantitativeTextInput.vue';
import DropdownElement from '@/components/fragebogen/quantitativ/tabellenDarstellung/DropdownElement.vue';

import { trpcClient } from '@/utils/trpc';

import type {
  QuantitativeGruppeBackend,
  QuantitativeResponseBackend,
} from '@/utils/backendTypes';
import CheckboxElement from '@/components/fragebogen/quantitativ/tabellenDarstellung/CheckboxElement.vue';
import InputElement from '@/components/fragebogen/quantitativ/tabellenDarstellung/InputElement.vue';
import DisabledQuantitativeNumberInput from '@/components/fragebogen/quantitativ/DisabledQuantitativeNumberInput.vue';
import GraphElementVue from '@/components/fragebogen/quantitativ/GraphElement.vue';

const elementTypeToComponent: {
  // eslint-disable-next-line no-unused-vars
  [key in QuantitativeElementTypes]: Component;
} = {
  EMPTY_LINE: EmptyLine,
  CALCULATED_FIELD: DisabledQuantitativeNumberInput,
  CHECKBOX_TABLE: CheckboxElement,
  DROPDOWN_TABLE: DropdownElement,
  NUMBER_INPUT: QuantitativeNumberInput,
  SUBHEADING: SubheadingElement,
  TABLE_HEADINGS: TableHeadingsElement,
  TEXT_INPUT: QuantitativeTextInput,
  MULTIPLE_INPUT_TABLE: InputElement,
  GRAPH: GraphElementVue,
};

export default Vue.extend({
  components: {
    KommentarFeld,
    SevenColumnHeader,
    TwoColumnHeader,
  },
  props: {
    groups: {
      type: Array as PropType<QuantitativeGruppeBackend[]>,
      required: true,
    },
    responses: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    noPageBreakAfterLastPanel: {
      type: Boolean,
      default: false,
    },
    responsesPreviousYear: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    reportLocked: {
      type: Boolean,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      window,
      dataAttributFrageId,
      CsrdDialog,
      DnkDialog,
      GriDialog,
      PaiDialog,
      value: undefined as 0 | number[] | undefined,
    };
  },
  mounted() {
    this.renderApexChartsAfterMounting();
  },
  methods: {
    renderApexChartsAfterMounting() {
      this.value = this.expandIfAuswertungOrIfOnlyOneGroup();
    },
    expandIfAuswertungOrIfOnlyOneGroup() {
      if (this.auswertung) {
        return [...Array(this.groups.length).keys()];
      }

      return this.groups.length === 1 ? 0 : undefined;
    },
    getComponent(type: QuantitativeElementTypes): Component {
      return elementTypeToComponent[type];
    },
    updateComment(comment: string, groupId: number) {
      trpcClient.quantitativeGroup.createComment.mutate({ comment, groupId });
    },
    responder(group: QuantitativeGruppeBackend): string | undefined {
      const user = group.comments[0]?.organizationUser?.user;
      return user ? `${user.firstName} ${user.lastName}` : undefined;
    },
    respondedAt(group: QuantitativeGruppeBackend): Date | undefined {
      return group.comments[0]?.createdAt;
    },
    hasNarrowQuestionColumn(group: QuantitativeGruppeBackend): boolean {
      if (group.isAdvancedTable) {
        return false;
      }

      return group.elements.some((el) =>
        ['MULTIPLE_INPUT_TABLE', 'TABLE_HEADINGS'].includes(el.type)
      );
    },
  },
});
