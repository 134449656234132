
import type { PropType } from 'vue';

import mixins from 'vue-typed-mixins';
import type {
  QuantitativeElementBackend,
  QuantitativeResponseBackend,
} from '@/utils/backendTypes';
import { trpcClient } from '@/utils/trpc';
import FrageId from '@/components/library/FrageId.vue';
import updateResponses from '@/mixins/updateResponses';
import handleQuantitativeError from '@/mixins/handleError';
import QuestionColumn from '@/components/fragebogen/quantitativ/tabellenDarstellung/QuestionColumn.vue';
import ToolTipResponder from '@/components/fragebogen/quantitativ/ToolTipResponder.vue';

export default mixins(updateResponses, handleQuantitativeError).extend({
  components: {
    FrageId,
    QuestionColumn,
    ToolTipResponder,
  },
  props: {
    checkbox: {
      type: Object as PropType<QuantitativeElementBackend['checkbox']>,
      required: true,
    },
    responses: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    reportLocked: {
      type: Boolean,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerCheckbox: this.checkbox!,
    };
  },
  methods: {
    createBoolean(questionId: number, response: boolean) {
      trpcClient.response.createQuantitativeBoolean
        .mutate({
          response,
          questionId,
        })
        .then(this.emitResponses)
        .catch((e) =>
          this.handleError(e, 'Konnte die Antwort nicht speichern:')
        );
    },
    responder(frontendId: string) {
      const user = this.responses[frontendId]?.organizationUser?.user;
      return user ? `${user.firstName} ${user.lastName}` : undefined;
    },
    respondedAt(frontendId: string) {
      return this.responses[frontendId]?.createdAt;
    },
  },
});
