
import Vue from 'vue';
import {
  headerAntwort,
  headerFrage,
} from '@/components/fragebogen/quantitativ/SevenColumnHeader.vue';
export default Vue.extend({
  props: {
    hasNarrowQuestionColumn: {
      type: Boolean, 
      default: false,
    }
  },
  data() {
    return {
      headerFrage,
      headerAntwort,
    };
  },
});
