
import type { PropType } from 'vue';
import Vue from 'vue';

import type { QuantitativeElementBackend } from '@/utils/backendTypes';

export default Vue.extend({
  props: {
    tableHeadings: {
      type: Object as PropType<QuantitativeElementBackend['tableHeadings']>,
      required: true,
    },
    advancedTable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      innerTableHeadings: this.tableHeadings!,
    };
  },
  computed: {
    headings(): Array<string | null> {
      return [
        this.innerTableHeadings.column1,
        this.innerTableHeadings.column2,
        this.innerTableHeadings.column3,
        this.innerTableHeadings.column4,
        this.innerTableHeadings.column5,
        this.innerTableHeadings.column6,
        this.innerTableHeadings.column7,
      ];
    },
    headingsForRegularTable(): Array<string | null> {
      return this.headings.filter((heading) => heading != null).slice(0, 4);
    },
  },
});
