
import Vue from 'vue';
import { faqs } from '@/components/navigation/navigation';
import InfoKarte from '@/components/homepage/InfoKarte.vue';

export default Vue.extend({
  components: {
    InfoKarte,
  },
  data() {
    return {
      faqs,
    };
  },
});
