export const fuhrpark = {
  id: 7,
  categoryId: 7,
  name: 'Fuhrpark',
  order: 1,
  icon: 'mdi-car',
  isAdvancedTable: true,
  CSRD: 'ESRS DR E1 5',
  DNK: 'DNK 11 Inanspruchnahme natürlicher Ressourcen, Aspekt 1',
  GRI: 'GRI 302-1',
  PAI: null,
  comments: [],
  elements: [
    {
      id: 44,
      groupId: 7,
      order: 1,
      type: 'SUBHEADING',
      question: [],
      subheading: {
        id: 2,
        elementId: 44,
        text: 'Verbräuche in Kilometer',
        description:
          'Indirekte Angabe der Verbräuche des Fuhrpark in Kilometer (km)',
      },
      tableHeadings: null,
      dropdown: null,
      multipleInputTable: null,
      checkbox: null,
      graph: null,
    },
    {
      id: 45,
      groupId: 7,
      order: 2,
      type: 'NUMBER_INPUT',
      question: [
        {
          id: 43,
          elementId: 45,
          multipleInputTableId: null,
          checkboxTableId: null,
          dropdownTableId: null,
          parentQuestionId: null,
          type: 'NUMERIC',
          frontendId: 'EKLQN049',
          text: 'Diesel',
          description: null,
          unit: 'km',
          isAggregated: true,
          defaultEmissionFactor: null,
          calculationFormula: null,
          proportionFormula: 'EKLQN049/(EKLQN055)',
          emissionFactor: [
            {
              id: 44,
              elementId: null,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: 43,
              type: 'EMISSION_FACTOR',
              frontendId: 'EKLQN049EF',
              text: 'Diesel - Emissionsfaktor',
              description: null,
              unit: 't CO₂/Km',
              isAggregated: false,
              defaultEmissionFactor: 0.00017082,
              calculationFormula: null,
              proportionFormula: null,
            },
          ],
        },
      ],
      subheading: null,
      tableHeadings: null,
      dropdown: null,
      multipleInputTable: null,
      checkbox: null,
      graph: null,
    },
    {
      id: 46,
      groupId: 7,
      order: 3,
      type: 'NUMBER_INPUT',
      question: [
        {
          id: 45,
          elementId: 46,
          multipleInputTableId: null,
          checkboxTableId: null,
          dropdownTableId: null,
          parentQuestionId: null,
          type: 'NUMERIC',
          frontendId: 'EKLQN050',
          text: 'Benzin',
          description: null,
          unit: 'km',
          isAggregated: true,
          defaultEmissionFactor: null,
          calculationFormula: null,
          proportionFormula: 'EKLQN050/(EKLQN055)',
          emissionFactor: [
            {
              id: 46,
              elementId: null,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: 45,
              type: 'EMISSION_FACTOR',
              frontendId: 'EKLQN050EF',
              text: 'Benzin - Emissionsfaktor',
              description: null,
              unit: 't CO₂/Km',
              isAggregated: false,
              defaultEmissionFactor: 0.00017048,
              calculationFormula: null,
              proportionFormula: null,
            },
          ],
        },
      ],
      subheading: null,
      tableHeadings: null,
      dropdown: null,
      multipleInputTable: null,
      checkbox: null,
      graph: null,
    },
    {
      id: 47,
      groupId: 7,
      order: 4,
      type: 'NUMBER_INPUT',
      question: [
        {
          id: 47,
          elementId: 47,
          multipleInputTableId: null,
          checkboxTableId: null,
          dropdownTableId: null,
          parentQuestionId: null,
          type: 'NUMERIC',
          frontendId: 'EKLQN051',
          text: 'Plug-in-Hybrid',
          description: null,
          unit: 'km',
          isAggregated: true,
          defaultEmissionFactor: null,
          calculationFormula: null,
          proportionFormula: 'EKLQN051/(EKLQN055)',
          emissionFactor: [
            {
              id: 48,
              elementId: null,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: 47,
              type: 'EMISSION_FACTOR',
              frontendId: 'EKLQN051EF',
              text: 'Plug-in-Hybrid - Emissionsfaktor',
              description: null,
              unit: 't CO₂/Km',
              isAggregated: false,
              defaultEmissionFactor: 0.00011007,
              calculationFormula: null,
              proportionFormula: null,
            },
          ],
        },
      ],
      subheading: null,
      tableHeadings: null,
      dropdown: null,
      multipleInputTable: null,
      checkbox: null,
      graph: null,
    },
    {
      id: 48,
      groupId: 7,
      order: 5,
      type: 'NUMBER_INPUT',
      question: [
        {
          id: 49,
          elementId: 48,
          multipleInputTableId: null,
          checkboxTableId: null,
          dropdownTableId: null,
          parentQuestionId: null,
          type: 'NUMERIC',
          frontendId: 'EKLQN052',
          text: 'Elektrisch',
          description: null,
          unit: 'km',
          isAggregated: true,
          defaultEmissionFactor: null,
          calculationFormula: null,
          proportionFormula: 'EKLQN052/(EKLQN055)',
          emissionFactor: [
            {
              id: 50,
              elementId: null,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: 49,
              type: 'EMISSION_FACTOR',
              frontendId: 'EKLQN052EF',
              text: 'Elektrisch - Emissionsfaktor',
              description: null,
              unit: 't CO₂/Km',
              isAggregated: false,
              defaultEmissionFactor: 0,
              calculationFormula: null,
              proportionFormula: null,
            },
          ],
        },
      ],
      subheading: null,
      tableHeadings: null,
      dropdown: null,
      multipleInputTable: null,
      checkbox: null,
      graph: null,
    },
    {
      id: 49,
      groupId: 7,
      order: 6,
      type: 'NUMBER_INPUT',
      question: [
        {
          id: 51,
          elementId: 49,
          multipleInputTableId: null,
          checkboxTableId: null,
          dropdownTableId: null,
          parentQuestionId: null,
          type: 'NUMERIC',
          frontendId: 'EKLQN053',
          text: 'LPG (Flüssiggas)',
          description: null,
          unit: 'km',
          isAggregated: true,
          defaultEmissionFactor: null,
          calculationFormula: null,
          proportionFormula: 'EKLQN053/(EKLQN055)',
          emissionFactor: [
            {
              id: 52,
              elementId: null,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: 51,
              type: 'EMISSION_FACTOR',
              frontendId: 'EKLQN053EF',
              text: 'LPG (Flüssiggas) - Emissionsfaktor',
              description: null,
              unit: 't CO₂/Km',
              isAggregated: false,
              defaultEmissionFactor: 0.00031823,
              calculationFormula: null,
              proportionFormula: null,
            },
          ],
        },
      ],
      subheading: null,
      tableHeadings: null,
      dropdown: null,
      multipleInputTable: null,
      checkbox: null,
      graph: null,
    },
    {
      id: 50,
      groupId: 7,
      order: 7,
      type: 'NUMBER_INPUT',
      question: [
        {
          id: 53,
          elementId: 50,
          multipleInputTableId: null,
          checkboxTableId: null,
          dropdownTableId: null,
          parentQuestionId: null,
          type: 'NUMERIC',
          frontendId: 'EKLQN054',
          text: 'CNG (Erdgas)',
          description: null,
          unit: 'km',
          isAggregated: true,
          defaultEmissionFactor: null,
          calculationFormula: null,
          proportionFormula: 'EKLQN054/(EKLQN055)',
          emissionFactor: [
            {
              id: 54,
              elementId: null,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: 53,
              type: 'EMISSION_FACTOR',
              frontendId: 'EKLQN054EF',
              text: 'CNG (Erdgas) - Emissionsfaktor',
              description: null,
              unit: 't CO₂/Km',
              isAggregated: false,
              defaultEmissionFactor: 0.0002819,
              calculationFormula: null,
              proportionFormula: null,
            },
          ],
        },
      ],
      subheading: null,
      tableHeadings: null,
      dropdown: null,
      multipleInputTable: null,
      checkbox: null,
      graph: null,
    },
    {
      id: 51,
      groupId: 7,
      order: 8,
      type: 'CALCULATED_FIELD',
      question: [
        {
          id: 55,
          elementId: 51,
          multipleInputTableId: null,
          checkboxTableId: null,
          dropdownTableId: null,
          parentQuestionId: null,
          type: 'CALCULATED_FIELD',
          frontendId: 'EKLQN055',
          text: 'Gesamtkilometer',
          description: null,
          unit: 'km',
          isAggregated: false,
          defaultEmissionFactor: null,
          calculationFormula:
            'EKLQN049+EKLQN050+EKLQN051+EKLQN052+EKLQN053+EKLQN054',
          proportionFormula: '(EKLQN055)/(EKLQN055)',
          emissionFactor: [],
        },
      ],
      subheading: null,
      tableHeadings: null,
      dropdown: null,
      multipleInputTable: null,
      checkbox: null,
      graph: null,
    },
    {
      id: 52,
      groupId: 7,
      order: 9,
      type: 'GRAPH',
      question: [],
      subheading: null,
      tableHeadings: null,
      dropdown: null,
      multipleInputTable: null,
      checkbox: null,
      graph: {
        id: 1,
        elementId: 52,
        type: 'BAR_CHART',
        frontendId: 'EKLQN056',
        text: 'Entwicklung der Gesamtkilometer',
        hasAbsoluteValue: true,
        sourceQuestions: [
          {
            id: 1,
            graphId: 1,
            questionId: 43,
            order: 1,
            label: 'Diesel',
            question: {
              id: 43,
              elementId: 45,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: null,
              type: 'NUMERIC',
              frontendId: 'EKLQN049',
              text: 'Diesel',
              description: null,
              unit: 'km',
              isAggregated: true,
              defaultEmissionFactor: null,
              calculationFormula: null,
              proportionFormula: 'EKLQN049/(EKLQN055)',
            },
          },
          {
            id: 2,
            graphId: 1,
            questionId: 45,
            order: 2,
            label: 'Benzin',
            question: {
              id: 45,
              elementId: 46,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: null,
              type: 'NUMERIC',
              frontendId: 'EKLQN050',
              text: 'Benzin',
              description: null,
              unit: 'km',
              isAggregated: true,
              defaultEmissionFactor: null,
              calculationFormula: null,
              proportionFormula: 'EKLQN050/(EKLQN055)',
            },
          },
          {
            id: 3,
            graphId: 1,
            questionId: 47,
            order: 3,
            label: 'Plug-in-Hybrid',
            question: {
              id: 47,
              elementId: 47,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: null,
              type: 'NUMERIC',
              frontendId: 'EKLQN051',
              text: 'Plug-in-Hybrid',
              description: null,
              unit: 'km',
              isAggregated: true,
              defaultEmissionFactor: null,
              calculationFormula: null,
              proportionFormula: 'EKLQN051/(EKLQN055)',
            },
          },
          {
            id: 4,
            graphId: 1,
            questionId: 49,
            order: 4,
            label: 'Elektrisch',
            question: {
              id: 49,
              elementId: 48,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: null,
              type: 'NUMERIC',
              frontendId: 'EKLQN052',
              text: 'Elektrisch',
              description: null,
              unit: 'km',
              isAggregated: true,
              defaultEmissionFactor: null,
              calculationFormula: null,
              proportionFormula: 'EKLQN052/(EKLQN055)',
            },
          },
          {
            id: 5,
            graphId: 1,
            questionId: 51,
            order: 5,
            label: 'LPG (Flüssiggas)',
            question: {
              id: 51,
              elementId: 49,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: null,
              type: 'NUMERIC',
              frontendId: 'EKLQN053',
              text: 'LPG (Flüssiggas)',
              description: null,
              unit: 'km',
              isAggregated: true,
              defaultEmissionFactor: null,
              calculationFormula: null,
              proportionFormula: 'EKLQN053/(EKLQN055)',
            },
          },
          {
            id: 6,
            graphId: 1,
            questionId: 53,
            order: 6,
            label: 'CNG (Erdgas)',
            question: {
              id: 53,
              elementId: 50,
              multipleInputTableId: null,
              checkboxTableId: null,
              dropdownTableId: null,
              parentQuestionId: null,
              type: 'NUMERIC',
              frontendId: 'EKLQN054',
              text: 'CNG (Erdgas)',
              description: null,
              unit: 'km',
              isAggregated: true,
              defaultEmissionFactor: null,
              calculationFormula: null,
              proportionFormula: 'EKLQN054/(EKLQN055)',
            },
          },
        ],
      },
    },
  ],
};

export const responses = {
  EKLQN904: {
    id: 54,
    createdAt: new Date('2023-10-24T10:17:49.754Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 188,
    valueNumeric: 0.3,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 188,
      elementId: 169,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN904',
      text: 'Emissionen Nah-/Fernwärme aus KWK',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN099+EKLQN100+EKLQN101',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN099: {
    id: 53,
    createdAt: new Date('2023-10-24T10:17:49.730Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 185,
    valueNumeric: 0.3,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 185,
      elementId: 166,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN099',
      text: 'Brennstoff: Stein-/Braunkohle',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN075*EKLQN075EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN504: {
    id: 52,
    createdAt: new Date('2023-10-24T10:17:49.480Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 130,
    valueNumeric: 1,
    valueNumericProportion: 0.5,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 130,
      elementId: 101,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN504',
      text: 'Nah-/Fernwärme aus KWK',
      description: null,
      unit: 'MWh',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN075+EKLQN076+EKLQN077',
      proportionFormula:
        '(EKLQN075+EKLQN076+EKLQN077)/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN075: {
    id: 51,
    createdAt: new Date('2023-10-24T10:17:49.443Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 124,
    valueNumeric: 1,
    valueNumericProportion: 0.5,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 124,
      elementId: 98,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN075',
      text: 'Brennstoff: Stein-/Braunkohle',
      description: null,
      unit: 'MWh',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula:
        'EKLQN075/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN075EF: {
    id: 50,
    createdAt: new Date('2023-10-24T10:17:49.432Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 125,
    valueNumeric: 0.3,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 125,
      elementId: null,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: 124,
      type: 'EMISSION_FACTOR',
      frontendId: 'EKLQN075EF',
      text: 'Brennstoff: Stein-/Braunkohle - Emissionsfaktor',
      description: null,
      unit: 't CO₂/MWh',
      isAggregated: false,
      defaultEmissionFactor: 0.3,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN701: {
    id: 49,
    createdAt: new Date('2023-10-24T10:17:47.089Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 217,
    valueNumeric: 0.4,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 217,
      elementId: 208,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN701',
      text: 'Scope 2',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN020',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN020: {
    id: 48,
    createdAt: new Date('2023-10-24T10:17:46.928Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 189,
    valueNumeric: 0.4,
    valueNumericProportion: 0,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 189,
      elementId: 171,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN020',
      text: 'Gesamt Emissionen Scope 2',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN905+EKLQN906',
      proportionFormula:
        '(EKLQN021+EKLQN022+EKLQN023+EKLQN023)/(EKLQN019+EKLQN020+EKLQN021+EKLQN022+EKLQN023)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN906: {
    id: 47,
    createdAt: new Date('2023-10-24T10:17:46.896Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 197,
    valueNumeric: 0.4,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 197,
      elementId: 181,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN906',
      text: 'Emissionen Nah-/Fernwärme aus Heizwerken',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN107+EKLQN108+EKLQN109',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN107: {
    id: 46,
    createdAt: new Date('2023-10-24T10:17:46.869Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 194,
    valueNumeric: 0.4,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 194,
      elementId: 178,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN107',
      text: 'Brennstoff: Stein-/Braunkohle',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN078*EKLQN078EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN016: {
    id: 45,
    createdAt: new Date('2023-10-24T10:17:49.699Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 146,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 146,
      elementId: 120,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN016',
      text: 'Energieverbrauch/Bett',
      description: null,
      unit: 'MWh/Bett',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN008/ORGALL027',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN015: {
    id: 44,
    createdAt: new Date('2023-10-24T10:17:49.672Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 145,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 145,
      elementId: 119,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN015',
      text: 'Energieverbrauch/Mitarbeiter',
      description: null,
      unit: 'MWh/pro Vollzeitkraft',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN008/ORGALL020',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN008: {
    id: 43,
    createdAt: new Date('2023-10-24T10:17:49.562Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 140,
    valueNumeric: 2,
    valueNumericProportion: 1,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 140,
      elementId: 110,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN008',
      text: 'Gesamtenergieverbrauch',
      description: null,
      unit: 'MWh',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN006+EKLQN007',
      proportionFormula:
        '(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN007: {
    id: 42,
    createdAt: new Date('2023-10-24T10:17:49.520Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 139,
    valueNumeric: 2,
    valueNumericProportion: 1,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 139,
      elementId: 109,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN007',
      text: 'Verbrauch nicht erneuerbarer Energien',
      description: null,
      unit: 'MWh',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula:
        'EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN004+EKLQN069+EKLQN072+EKLQN074+EKLQN075+EKLQN076+EKLQN078+EKLQN005',
      proportionFormula:
        '(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN004+EKLQN069+EKLQN072+EKLQN074+EKLQN075+EKLQN076+EKLQN078+EKLQN005)/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN505: {
    id: 41,
    createdAt: new Date('2023-10-24T10:17:46.677Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 137,
    valueNumeric: 1,
    valueNumericProportion: 0.5,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 137,
      elementId: 106,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN505',
      text: 'Nah-/Fernwärme aus Heizwerken',
      description: null,
      unit: 'MWh',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN078+EKLQN005+EKLQN079',
      proportionFormula:
        '(EKLQN078+EKLQN005+EKLQN079)/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN078: {
    id: 40,
    createdAt: new Date('2023-10-24T10:17:46.633Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 131,
    valueNumeric: 1,
    valueNumericProportion: 0.5,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 131,
      elementId: 103,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN078',
      text: 'Brennstoff: Stein-/Braunkohle',
      description: null,
      unit: 'MWh',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula:
        'EKLQN078/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN078EF: {
    id: 39,
    createdAt: new Date('2023-10-24T10:17:46.617Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 132,
    valueNumeric: 0.4,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 132,
      elementId: null,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: 131,
      type: 'EMISSION_FACTOR',
      frontendId: 'EKLQN078EF',
      text: 'Brennstoff: Stein-/Braunkohle - Emissionsfaktor',
      description: null,
      unit: 't CO₂/MWh',
      isAggregated: false,
      defaultEmissionFactor: 0.4,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN014: {
    id: 38,
    createdAt: new Date('2023-10-24T10:17:49.622Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 144,
    valueNumeric: 2000000,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 144,
      elementId: 118,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN014',
      text: 'Energieverbrauch/1 Mio. € Umsatz',
      description: null,
      unit: 'MWh/Mio. €',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN008/(ORGALL023/1000000)',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN106: {
    id: 28,
    createdAt: new Date('2023-10-25T07:15:19.220Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 153,
    valueNumeric: 0.0090208,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 153,
      elementId: 129,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN106',
      text: 'CNG (Erdgas)',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN054*EKLQN054EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN054: {
    id: 27,
    createdAt: new Date('2023-10-25T07:15:18.910Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 53,
    valueNumeric: 32,
    valueNumericProportion: 0.5079365079365079,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 53,
      elementId: 50,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN054',
      text: 'CNG (Erdgas)',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN054/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN054EF: {
    id: 26,
    createdAt: new Date('2023-10-24T08:01:52.933Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 54,
    valueNumeric: 0.0002819,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 54,
      elementId: null,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: 53,
      type: 'EMISSION_FACTOR',
      frontendId: 'EKLQN054EF',
      text: 'CNG (Erdgas) - Emissionsfaktor',
      description: null,
      unit: 't CO₂/Km',
      isAggregated: false,
      defaultEmissionFactor: 0.0002819,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN105: {
    id: 25,
    createdAt: new Date('2023-10-25T07:15:17.923Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 152,
    valueNumeric: 0.00509168,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 152,
      elementId: 128,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN105',
      text: 'LPG (Flüssiggas)',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN053*EKLQN053EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN053: {
    id: 24,
    createdAt: new Date('2023-10-25T07:15:17.661Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 51,
    valueNumeric: 16,
    valueNumericProportion: 0.253968253968254,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 51,
      elementId: 49,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN053',
      text: 'LPG (Flüssiggas)',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN053/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN053EF: {
    id: 23,
    createdAt: new Date('2023-10-24T08:01:43.533Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 52,
    valueNumeric: 0.00031823,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 52,
      elementId: null,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: 51,
      type: 'EMISSION_FACTOR',
      frontendId: 'EKLQN053EF',
      text: 'LPG (Flüssiggas) - Emissionsfaktor',
      description: null,
      unit: 't CO₂/Km',
      isAggregated: false,
      defaultEmissionFactor: 0.00031823,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN104: {
    id: 22,
    createdAt: new Date('2023-10-25T07:15:16.739Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 151,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 151,
      elementId: 127,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN104',
      text: 'Elektrisch',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN052*EKLQN052EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN052: {
    id: 21,
    createdAt: new Date('2023-10-25T07:15:16.340Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 49,
    valueNumeric: 8,
    valueNumericProportion: 0.126984126984127,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 49,
      elementId: 48,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN052',
      text: 'Elektrisch',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN052/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN052EF: {
    id: 20,
    createdAt: new Date('2023-10-24T08:01:37.024Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 50,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 50,
      elementId: null,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: 49,
      type: 'EMISSION_FACTOR',
      frontendId: 'EKLQN052EF',
      text: 'Elektrisch - Emissionsfaktor',
      description: null,
      unit: 't CO₂/Km',
      isAggregated: false,
      defaultEmissionFactor: 0,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN103: {
    id: 19,
    createdAt: new Date('2023-10-25T07:15:14.533Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 150,
    valueNumeric: 0.00044028,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 150,
      elementId: 126,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN103',
      text: 'Plug-in-Hybrid',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN051*EKLQN051EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN051: {
    id: 18,
    createdAt: new Date('2023-10-25T07:15:14.254Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 47,
    valueNumeric: 4,
    valueNumericProportion: 0.06349206349206349,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 47,
      elementId: 47,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN051',
      text: 'Plug-in-Hybrid',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN051/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN051EF: {
    id: 17,
    createdAt: new Date('2023-10-24T08:01:35.775Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 48,
    valueNumeric: 0.00011007,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 48,
      elementId: null,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: 47,
      type: 'EMISSION_FACTOR',
      frontendId: 'EKLQN051EF',
      text: 'Plug-in-Hybrid - Emissionsfaktor',
      description: null,
      unit: 't CO₂/Km',
      isAggregated: false,
      defaultEmissionFactor: 0.00011007,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN102: {
    id: 16,
    createdAt: new Date('2023-10-25T07:15:09.546Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 149,
    valueNumeric: 0.00034096,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 149,
      elementId: 125,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN102',
      text: 'Benzin',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN050*EKLQN050EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN050: {
    id: 15,
    createdAt: new Date('2023-10-25T07:15:09.298Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 45,
    valueNumeric: 2,
    valueNumericProportion: 0.03174603174603174,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 45,
      elementId: 46,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN050',
      text: 'Benzin',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN050/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN050EF: {
    id: 14,
    createdAt: new Date('2023-10-24T08:01:35.041Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 46,
    valueNumeric: 0.00017048,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 46,
      elementId: null,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: 45,
      type: 'EMISSION_FACTOR',
      frontendId: 'EKLQN050EF',
      text: 'Benzin - Emissionsfaktor',
      description: null,
      unit: 't CO₂/Km',
      isAggregated: false,
      defaultEmissionFactor: 0.00017048,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN703: {
    id: 13,
    createdAt: new Date('2023-10-25T07:15:19.447Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 219,
    valueNumeric: 0.71506454,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 219,
      elementId: 210,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN703',
      text: 'Gesamt',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN017+EKLQN020+EKLQN027',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN700: {
    id: 12,
    createdAt: new Date('2023-10-25T07:15:19.418Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 216,
    valueNumeric: 0.31506454,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 216,
      elementId: 207,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN700',
      text: 'Scope 1',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN017',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN048: {
    id: 11,
    createdAt: new Date('2023-10-25T07:15:19.392Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 222,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 222,
      elementId: 215,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN048',
      text: 'CO₂/Bett',
      description: null,
      unit: 'CO₂/Bett',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN043/ORGALL027',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN047: {
    id: 10,
    createdAt: new Date('2023-10-25T07:15:19.364Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 221,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 221,
      elementId: 214,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN047',
      text: 'CO₂/Vollzeitkraft',
      description: null,
      unit: 'CO₂/pro Vollzeitkraft',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN043/ORGALL020',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN046: {
    id: 9,
    createdAt: new Date('2023-10-25T07:15:19.338Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 220,
    valueNumeric: 715064.54,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 220,
      elementId: 213,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN046',
      text: 'CO₂/1 Mio. € Umsatz',
      description: null,
      unit: 'CO₂/Mio. Euro',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN043/(ORGALL023/1000000)',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN043: {
    id: 8,
    createdAt: new Date('2023-10-25T07:15:19.316Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 215,
    valueNumeric: 0.71506454,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 215,
      elementId: 203,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN043',
      text: 'Gesamt Emissionen (Scope 1,2,3)',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN027+EKLQN017+EKLQN020',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN024: {
    id: 7,
    createdAt: new Date('2023-10-25T07:15:19.291Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 198,
    valueNumeric: 0.71506454,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 198,
      elementId: 183,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN024',
      text: 'Gesamt Emissionen Scope 1 und Scope 2',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN017+EKLQN020',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN017: {
    id: 6,
    createdAt: new Date('2023-10-25T07:15:19.271Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 147,
    valueNumeric: 0.31506454,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 147,
      elementId: 122,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN017',
      text: 'Gesamt Emissionen Scope 1',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN900+EKLQN901+EKLQN902+EKLQN903+EKLQN904',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN900: {
    id: 5,
    createdAt: new Date('2023-10-25T07:15:19.246Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 162,
    valueNumeric: 0.01506454,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 162,
      elementId: 139,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN900',
      text: 'Emissionen Fuhrpark',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula:
        'EKLQN081+EKLQN102+EKLQN103+EKLQN104+EKLQN105+EKLQN106+EKLQN960+EKLQN961+EKLQN962+EKLQN963+EKLQN964+EKLQN965+EKLQN966+EKLQN967',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN081: {
    id: 4,
    createdAt: new Date('2023-10-25T07:15:07.607Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 148,
    valueNumeric: 0.00017082,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 148,
      elementId: 124,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN081',
      text: 'Diesel',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN049*EKLQN049EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN055: {
    id: 3,
    createdAt: new Date('2023-10-25T07:15:18.971Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 55,
    valueNumeric: 63,
    valueNumericProportion: 1,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 55,
      elementId: 51,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN055',
      text: 'Gesamtkilometer',
      description: null,
      unit: 'km',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula:
        'EKLQN049+EKLQN050+EKLQN051+EKLQN052+EKLQN053+EKLQN054',
      proportionFormula: '(EKLQN055)/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN049: {
    id: 2,
    createdAt: new Date(new Date('2023-10-25T07:15:07.305Z')),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 43,
    valueNumeric: 1,
    valueNumericProportion: 0.01587301587301587,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 43,
      elementId: 45,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN049',
      text: 'Diesel',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN049/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN049EF: {
    id: 1,
    createdAt: new Date('2023-10-24T08:00:53.804Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 44,
    valueNumeric: 0.00017082,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 44,
      elementId: null,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: 43,
      type: 'EMISSION_FACTOR',
      frontendId: 'EKLQN049EF',
      text: 'Diesel - Emissionsfaktor',
      description: null,
      unit: 't CO₂/Km',
      isAggregated: false,
      defaultEmissionFactor: 0.00017082,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 1,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
};

export const responsesPreviousYear = {
  VRRC007: {
    id: 57,
    createdAt: new Date('2023-10-24T11:29:12.606Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 6,
    valueNumeric: 4,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 6,
      elementId: 7,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'VRRC007',
      text: 'Umsatz Standort 3',
      description:
        'Wenn Sie die Frage (Gibt es mehrere Standorte mit unterschiedlichen Postleitzahlen?) mit „Ja“ beantwortet haben, geben Sie bitte alle Postleitzahlen mit dem anteiligen Umsatz Ihres Unternehmens in Prozent an.',
      unit: 'Euro',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  VRRC005: {
    id: 56,
    createdAt: new Date('2023-10-24T11:29:12.604Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 4,
    valueNumeric: 3,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 4,
      elementId: 5,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'VRRC005',
      text: 'Umsatz Standort 2',
      description:
        'Wenn Sie die Frage (Gibt es mehrere Standorte mit unterschiedlichen Postleitzahlen?) mit „Ja“ beantwortet haben, geben Sie bitte alle Postleitzahlen mit dem anteiligen Umsatz Ihres Unternehmens in Prozent an.',
      unit: 'Euro',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  VRRC003: {
    id: 55,
    createdAt: new Date('2023-10-24T11:29:12.535Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 2,
    valueNumeric: 2,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 2,
      elementId: 3,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'VRRC003',
      text: 'Umsatz Standort 1',
      description:
        'Wenn Sie die Frage (Gibt es mehrere Standorte mit unterschiedlichen Postleitzahlen?) mit „Ja“ beantwortet haben, geben Sie bitte alle Postleitzahlen mit dem anteiligen Umsatz Ihres Unternehmens in Prozent an.',
      unit: 'Euro',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN904: {
    id: 54,
    createdAt: new Date('2023-10-24T10:17:49.754Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 188,
    valueNumeric: 0.3,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 188,
      elementId: 169,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN904',
      text: 'Emissionen Nah-/Fernwärme aus KWK',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN099+EKLQN100+EKLQN101',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN099: {
    id: 53,
    createdAt: new Date('2023-10-24T10:17:49.730Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 185,
    valueNumeric: 0.3,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 185,
      elementId: 166,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN099',
      text: 'Brennstoff: Stein-/Braunkohle',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN075*EKLQN075EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN504: {
    id: 52,
    createdAt: new Date('2023-10-24T10:17:49.480Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 130,
    valueNumeric: 1,
    valueNumericProportion: 0.5,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 130,
      elementId: 101,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN504',
      text: 'Nah-/Fernwärme aus KWK',
      description: null,
      unit: 'MWh',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN075+EKLQN076+EKLQN077',
      proportionFormula:
        '(EKLQN075+EKLQN076+EKLQN077)/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN075: {
    id: 51,
    createdAt: new Date('2023-10-24T10:17:49.443Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 124,
    valueNumeric: 1,
    valueNumericProportion: 0.5,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 124,
      elementId: 98,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN075',
      text: 'Brennstoff: Stein-/Braunkohle',
      description: null,
      unit: 'MWh',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula:
        'EKLQN075/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN701: {
    id: 49,
    createdAt: new Date('2023-10-24T10:17:47.089Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 217,
    valueNumeric: 0.4,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 217,
      elementId: 208,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN701',
      text: 'Scope 2',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN020',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN020: {
    id: 48,
    createdAt: new Date('2023-10-24T10:17:46.928Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 189,
    valueNumeric: 0.4,
    valueNumericProportion: 0,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 189,
      elementId: 171,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN020',
      text: 'Gesamt Emissionen Scope 2',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN905+EKLQN906',
      proportionFormula:
        '(EKLQN021+EKLQN022+EKLQN023+EKLQN023)/(EKLQN019+EKLQN020+EKLQN021+EKLQN022+EKLQN023)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN906: {
    id: 47,
    createdAt: new Date('2023-10-24T10:17:46.896Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 197,
    valueNumeric: 0.4,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 197,
      elementId: 181,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN906',
      text: 'Emissionen Nah-/Fernwärme aus Heizwerken',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN107+EKLQN108+EKLQN109',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN107: {
    id: 46,
    createdAt: new Date('2023-10-24T10:17:46.869Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 194,
    valueNumeric: 0.4,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 194,
      elementId: 178,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN107',
      text: 'Brennstoff: Stein-/Braunkohle',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN078*EKLQN078EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN016: {
    id: 45,
    createdAt: new Date('2023-10-24T10:17:49.699Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 146,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 146,
      elementId: 120,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN016',
      text: 'Energieverbrauch/Bett',
      description: null,
      unit: 'MWh/Bett',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN008/ORGALL027',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN015: {
    id: 44,
    createdAt: new Date('2023-10-24T10:17:49.672Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 145,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 145,
      elementId: 119,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN015',
      text: 'Energieverbrauch/Mitarbeiter',
      description: null,
      unit: 'MWh/pro Vollzeitkraft',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN008/ORGALL020',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN008: {
    id: 43,
    createdAt: new Date('2023-10-24T10:17:49.562Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 140,
    valueNumeric: 2,
    valueNumericProportion: 1,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 140,
      elementId: 110,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN008',
      text: 'Gesamtenergieverbrauch',
      description: null,
      unit: 'MWh',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN006+EKLQN007',
      proportionFormula:
        '(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN007: {
    id: 42,
    createdAt: new Date('2023-10-24T10:17:49.520Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 139,
    valueNumeric: 2,
    valueNumericProportion: 1,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 139,
      elementId: 109,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN007',
      text: 'Verbrauch nicht erneuerbarer Energien',
      description: null,
      unit: 'MWh',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula:
        'EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN004+EKLQN069+EKLQN072+EKLQN074+EKLQN075+EKLQN076+EKLQN078+EKLQN005',
      proportionFormula:
        '(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN004+EKLQN069+EKLQN072+EKLQN074+EKLQN075+EKLQN076+EKLQN078+EKLQN005)/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN505: {
    id: 41,
    createdAt: new Date('2023-10-24T10:17:46.677Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 137,
    valueNumeric: 1,
    valueNumericProportion: 0.5,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 137,
      elementId: 106,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN505',
      text: 'Nah-/Fernwärme aus Heizwerken',
      description: null,
      unit: 'MWh',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN078+EKLQN005+EKLQN079',
      proportionFormula:
        '(EKLQN078+EKLQN005+EKLQN079)/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN078: {
    id: 40,
    createdAt: new Date('2023-10-24T10:17:46.633Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 131,
    valueNumeric: 1,
    valueNumericProportion: 0.5,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 131,
      elementId: 103,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN078',
      text: 'Brennstoff: Stein-/Braunkohle',
      description: null,
      unit: 'MWh',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula:
        'EKLQN078/(EKLQN001+EKLQN002+EKLQN057+EKLQN058+EKLQN059+EKLQN060+EKLQN061+EKLQN062+EKLQN063+EKLQN064+EKLQN065+EKLQN066+EKLQN067+EKLQN068+EKLQN080+EKLQN003+EKLQN004+EKLQN069+EKLQN070+EKLQN071+EKLQN072+EKLQN073+EKLQN074+EKLQN075+EKLQN076+EKLQN077+EKLQN078+EKLQN005+EKLQN079)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN014: {
    id: 38,
    createdAt: new Date('2023-10-24T10:17:49.622Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 144,
    valueNumeric: 2000000,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 144,
      elementId: 118,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN014',
      text: 'Energieverbrauch/1 Mio. € Umsatz',
      description: null,
      unit: 'MWh/Mio. €',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN008/(ORGALL023/1000000)',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  ORGALL023: {
    id: 37,
    createdAt: new Date('2023-10-24T10:17:10.830Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 35,
    valueNumeric: 1,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 35,
      elementId: 36,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'ORGALL023',
      text: 'Umsatz',
      description: null,
      unit: 'Euro',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  ORGALL026: {
    id: 36,
    createdAt: new Date('2023-10-24T10:17:10.203Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 38,
    valueNumeric: 1,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 38,
      elementId: 39,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'ORGALL026',
      text: 'Gross Value Added (GVA)',
      description:
        'Gross Value Added ist die Summe aus EBITDA und Personalkosten und wird für Berechnung der Ausrichtung der Organisation am 1,5 Grad-Ziel verwendet. Mehr Informationen finden Sie in den Erörterungen zur XDC-Methodik unter 1,5 Grad-Ziel.',
      unit: 'Euro',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'ORGALL024+ORGALL025',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  ORGALL024: {
    id: 35,
    createdAt: new Date('2023-10-24T10:17:10.149Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 36,
    valueNumeric: 1,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 36,
      elementId: 37,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'ORGALL024',
      text: 'EBITDA',
      description:
        'EBITDA ist eine betriebswirtschaftliche Kennzahl, die den nachhaltigen operativen Cashflow vor Steuern eines Unternehmens beschreibt. EBITDA ist die Abkürzung (Akronym) für englisch: Earnings Before Interest, Taxes, Depreciation and Amortization. Übersetzt beschreibt das EBITDA also einen „Gewinn vor Zinsen, Steuern, Abschreibungen auf Sachanlagen und Abschreibungen auf immaterielle Vermögensgegenstände“.',
      unit: 'Euro',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN106: {
    id: 28,
    createdAt: new Date('2023-10-25T07:17:39.053Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 153,
    valueNumeric: 0.0180416,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 153,
      elementId: 129,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN106',
      text: 'CNG (Erdgas)',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN054*EKLQN054EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN054: {
    id: 27,
    createdAt: new Date('2023-10-25T07:17:38.772Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 53,
    valueNumeric: 64,
    valueNumericProportion: 0.5079365079365079,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 53,
      elementId: 50,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN054',
      text: 'CNG (Erdgas)',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN054/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN105: {
    id: 25,
    createdAt: new Date('2023-10-25T07:17:35.789Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 152,
    valueNumeric: 0.01018336,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 152,
      elementId: 128,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN105',
      text: 'LPG (Flüssiggas)',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN053*EKLQN053EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN053: {
    id: 24,
    createdAt: new Date('2023-10-25T07:17:35.535Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 51,
    valueNumeric: 32,
    valueNumericProportion: 0.253968253968254,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 51,
      elementId: 49,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN053',
      text: 'LPG (Flüssiggas)',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN053/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN104: {
    id: 22,
    createdAt: new Date('2023-10-25T07:17:34.518Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 151,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 151,
      elementId: 127,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN104',
      text: 'Elektrisch',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN052*EKLQN052EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN052: {
    id: 21,
    createdAt: new Date('2023-10-25T07:17:34.257Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 49,
    valueNumeric: 16,
    valueNumericProportion: 0.126984126984127,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 49,
      elementId: 48,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN052',
      text: 'Elektrisch',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN052/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN103: {
    id: 19,
    createdAt: new Date('2023-10-25T07:17:32.323Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 150,
    valueNumeric: 0.00088056,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 150,
      elementId: 126,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN103',
      text: 'Plug-in-Hybrid',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN051*EKLQN051EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN051: {
    id: 18,
    createdAt: new Date('2023-10-25T07:17:32.025Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 47,
    valueNumeric: 8,
    valueNumericProportion: 0.06349206349206349,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 47,
      elementId: 47,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN051',
      text: 'Plug-in-Hybrid',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN051/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN102: {
    id: 16,
    createdAt: new Date('2023-10-25T07:17:29.441Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 149,
    valueNumeric: 0.00068192,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 149,
      elementId: 125,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN102',
      text: 'Benzin',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN050*EKLQN050EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN050: {
    id: 15,
    createdAt: new Date('2023-10-25T07:17:29.145Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 45,
    valueNumeric: 4,
    valueNumericProportion: 0.03174603174603174,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 45,
      elementId: 46,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN050',
      text: 'Benzin',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN050/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN703: {
    id: 13,
    createdAt: new Date('2023-10-25T07:17:39.293Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 219,
    valueNumeric: 0.73012908,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 219,
      elementId: 210,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN703',
      text: 'Gesamt',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN017+EKLQN020+EKLQN027',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN700: {
    id: 12,
    createdAt: new Date('2023-10-25T07:17:39.268Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 216,
    valueNumeric: 0.33012908,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 216,
      elementId: 207,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN700',
      text: 'Scope 1',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN017',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN048: {
    id: 11,
    createdAt: new Date('2023-10-25T07:17:39.243Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 222,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 222,
      elementId: 215,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN048',
      text: 'CO₂/Bett',
      description: null,
      unit: 'CO₂/Bett',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN043/ORGALL027',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN047: {
    id: 10,
    createdAt: new Date('2023-10-25T07:17:39.216Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 221,
    valueNumeric: 0,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 221,
      elementId: 214,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN047',
      text: 'CO₂/Vollzeitkraft',
      description: null,
      unit: 'CO₂/pro Vollzeitkraft',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN043/ORGALL020',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN046: {
    id: 9,
    createdAt: new Date('2023-10-25T07:17:39.191Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 220,
    valueNumeric: 730129.0800000001,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 220,
      elementId: 213,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN046',
      text: 'CO₂/1 Mio. € Umsatz',
      description: null,
      unit: 'CO₂/Mio. Euro',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN043/(ORGALL023/1000000)',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN043: {
    id: 8,
    createdAt: new Date('2023-10-25T07:17:39.165Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 215,
    valueNumeric: 0.73012908,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 215,
      elementId: 203,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN043',
      text: 'Gesamt Emissionen (Scope 1,2,3)',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN027+EKLQN017+EKLQN020',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN024: {
    id: 7,
    createdAt: new Date('2023-10-25T07:17:39.137Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 198,
    valueNumeric: 0.73012908,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 198,
      elementId: 183,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN024',
      text: 'Gesamt Emissionen Scope 1 und Scope 2',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN017+EKLQN020',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN017: {
    id: 6,
    createdAt: new Date('2023-10-25T07:17:39.111Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 147,
    valueNumeric: 0.33012908,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 147,
      elementId: 122,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN017',
      text: 'Gesamt Emissionen Scope 1',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN900+EKLQN901+EKLQN902+EKLQN903+EKLQN904',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN900: {
    id: 5,
    createdAt: new Date('2023-10-25T07:17:39.082Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 162,
    valueNumeric: 0.03012908,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 162,
      elementId: 139,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN900',
      text: 'Emissionen Fuhrpark',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula:
        'EKLQN081+EKLQN102+EKLQN103+EKLQN104+EKLQN105+EKLQN106+EKLQN960+EKLQN961+EKLQN962+EKLQN963+EKLQN964+EKLQN965+EKLQN966+EKLQN967',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN081: {
    id: 4,
    createdAt: new Date('2023-10-25T07:17:28.772Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 148,
    valueNumeric: 0.00034164,
    valueNumericProportion: null,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 148,
      elementId: 124,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN081',
      text: 'Diesel',
      description: null,
      unit: 'Tonnen - CO₂-Äquivalent',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula: 'EKLQN049*EKLQN049EF',
      proportionFormula: null,
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN055: {
    id: 3,
    createdAt: new Date('2023-10-25T07:17:38.814Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 55,
    valueNumeric: 126,
    valueNumericProportion: 1,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 55,
      elementId: 51,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'CALCULATED_FIELD',
      frontendId: 'EKLQN055',
      text: 'Gesamtkilometer',
      description: null,
      unit: 'km',
      isAggregated: false,
      defaultEmissionFactor: null,
      calculationFormula:
        'EKLQN049+EKLQN050+EKLQN051+EKLQN052+EKLQN053+EKLQN054',
      proportionFormula: '(EKLQN055)/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
  EKLQN049: {
    id: 2,
    createdAt: new Date('2023-10-25T07:17:28.479Z'),
    organizationReportId: 1,
    organizationUserId: 1,
    questionId: 43,
    valueNumeric: 2,
    valueNumericProportion: 0.01587301587301587,
    valueBoolean: null,
    valueString: null,
    optionId: null,
    question: {
      id: 43,
      elementId: 45,
      multipleInputTableId: null,
      checkboxTableId: null,
      dropdownTableId: null,
      parentQuestionId: null,
      type: 'NUMERIC',
      frontendId: 'EKLQN049',
      text: 'Diesel',
      description: null,
      unit: 'km',
      isAggregated: true,
      defaultEmissionFactor: null,
      calculationFormula: null,
      proportionFormula: 'EKLQN049/(EKLQN055)',
    },
    organizationUser: {
      id: 1,
      type: 'OWNER',
      organizationId: 1,
      userId: 2,
      selectedOrganizationReportId: 2,
      addedByUserId: 1,
      user: {
        id: 2,
        email: 'sam@mailinator.com',
        firstName: 'Sam',
        lastName: 'Smith',
        role: 'REGULAR',
      },
    },
  },
};
