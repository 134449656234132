
import Vue from 'vue';
import type { PropType } from 'vue';
import type { MaterialityThemeTreeNode } from '../types';
import { trpcClient } from '@/utils/trpc';
import type { VuetifyForm } from '@/ts/types/generic';
import { getErrorMessage } from '@/utils/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';

export default Vue.extend({
  name: 'ThemeNameDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    editedNode: {
      type: Object as PropType<MaterialityThemeTreeNode>,
      required: true,
    },
    refetchTreeDataFn: Function,
  },
  data: () => ({
    dialog: false,
    name: undefined as undefined | string,
    nameRules: [
      (v: string) => !!v || 'Name is required',
      (v: string) =>
        (v && v.length <= 255) || 'Name must be less than 255 characters',
    ],
  }),

  computed: {
    isEditMode() {
      return !!this.editedNode.id;
    },
  },

  watch: {
    value(val: boolean) {
      this.dialog = val;
    },
    dialog(val: boolean) {
      this.$emit('input', val);
    },
    editedNode(val: MaterialityThemeTreeNode) {
      if (val.name) {
        this.name = val.name;
      }
    },
  },

  methods: {
    closeDialog() {
      this.$emit('input', false);
      this.name = undefined;
      (this.$refs.form as VuetifyForm).resetValidation();
    },
    async saveData() {
      const isValidated = (this.$refs.form as VuetifyForm).validate();

      if (isValidated) {
        try {
          // create function
          if (!this.isEditMode) {
            await trpcClient.materialityThemes.create.mutate({
              name: this.name as string,
              categoryId: this.editedNode.categoryId,
              parentMaterialityThemeId:
                this.editedNode.parentMaterialityThemeId,
            });
          }

          // edit function
          if (this.isEditMode) {
            await trpcClient.materialityThemes.updateById.mutate({
              id: this.editedNode.id as number,
              name: this.name as string,
            });
          }

          this.refetchTreeDataFn();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          this.$root.$emit(
            SnackbarAnzeigen.eventName,
            new SnackbarAnzeigen('error', errorMessage)
          );
        }

        this.closeDialog();
      }
    },
  },
});
