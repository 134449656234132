import Vue from 'vue';

export const graphWithPrintView = Vue.extend({
  data() {
    return {
      isPrintView: false,
      mediaQueryList: null as MediaQueryList | null,
    };
  },
  mounted() {
    this.checkPrintView();
    window.addEventListener('beforeprint', this.handleBeforePrint);
    window.addEventListener('afterprint', this.handleAfterPrint);
  },
  beforeDestroy() {
    window.removeEventListener('beforeprint', this.handleBeforePrint);
    window.removeEventListener('afterprint', this.handleAfterPrint);
    if (this.mediaQueryList) {
      this.mediaQueryList.removeEventListener('change', this.handleMediaChange);
    }
  },
  methods: {
    handleBeforePrint() {
      this.isPrintView = true;
    },
    handleAfterPrint() {
      this.isPrintView = false;
    },
    handleMediaChange(event: MediaQueryListEvent) {
      this.isPrintView = event.matches;
    },
    checkPrintView() {
      this.mediaQueryList = window.matchMedia('print');
      this.isPrintView = this.mediaQueryList.matches;
      this.mediaQueryList.addEventListener('change', this.handleMediaChange);
    },
  },
});
