
import type { PropType } from 'vue';
import Vue from 'vue';

const formatForDevelopment = new Intl.NumberFormat('de-DE', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  signDisplay: 'never',
});

export default Vue.extend({
  props: {
    value: {
      type: null as unknown as PropType<number | null | undefined>,
      required: true,
    },
  },
  computed: {
    formattedDevelopment(): string {
      if (Number.isNaN(this.value) || !Number.isFinite(this.value)) {
        return '-';
      }
      return formatForDevelopment.format(this.value || 0);
    },
  },
});
