import type {
  Textteil,
  ZusammengesetzterText,
} from '@/components/fragebogen/fragetypen/zusammengesetzterText/ZusammengesetzterText.vue';

export default class KartenInhalt implements ZusammengesetzterText {
  textteile: string | Textteil[];

  constructor(textteile: string | Textteil[]) {
    this.textteile = textteile;
  }
}
