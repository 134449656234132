
import type { PropType } from 'vue';
import Vue from 'vue';

const germanFormat = new Intl.NumberFormat('de-DE', {
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default Vue.extend({
  props: {
    value: {
      type: null as unknown as PropType<number | null | undefined>,
      required: true,
    },
  },
  computed: {
    formattedPreviousYear(): String {
      if (Number.isNaN(this.value) || !Number.isFinite(this.value)) {
        return '-';
      }
      return germanFormat.format(this.value || 0);
    },
  },
});
