export const dataAttributFrageId = 'data-frageid';

export function selectorFrageId(frageId: string | null) {
  return selector(dataAttributFrageId, frageId);
}

function selector(dataAttribut: string, wert: string | null) {
  // like `[data-frageId="EKLQ001"]`;
  return `[${dataAttribut}="${wert}"]`;
}
