
import type { PropType } from 'vue';
import Vue from 'vue';
import type {
  OrganizationCO2Report,
  OrganizationReport,
  ReportTemplate,
} from '@prisma/client';
import type { LoggedInUser, VuetifyForm } from '@/ts/types/generic';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import { getUser } from '@/components/login/login';
import { useStore } from '@/store';
import IconWithTooltip from '@/components/library/IconWithTooltip.vue';
import InfoBanner from '@/components/library/InfoBanner.vue';

const isMaterialityFilterEnabled =
  // eslint-disable-next-line eqeqeq
  (process.env.VUE_APP_MATERIALITY_FILTER_IS_ENABLED as string) == '1';

type Items = (OrganizationReport & {
  reportTemplate?: ReportTemplate;
  organizationCO2Reports: OrganizationCO2Report[];
})[];

export default Vue.extend({
  name: 'OrganizationReportCrud',
  components: {
    IconWithTooltip,
    InfoBanner,
  },
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
    items: {
      type: Array as PropType<Items>,
      required: true,
    },
  },
  data: () => ({
    isMaterialityFilterEnabled,
    isLoading: false,
    dialog: false,
    dialogDelete: false,
    processEnv: JSON.stringify(process.env),
    mfEnv: process.env.VUE_APP_MATERIALITY_FILTER_IS_ENABLED,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Jahr',
        align: 'start',
        sortable: true,
        value: 'year',
      },
      {
        text: 'Abgeschlossen',
        align: 'start',
        sortable: true,
        value: 'isLockedFormatted',
      },
      {
        text: 'Aggregiert',
        align: 'start',
        sortable: true,
        value: 'isAggregatedFormatted',
      },
      {
        text: '1,5 Gradziel Bericht erstellt',
        align: 'start',
        sortable: true,
        value: 'hasCO2Report',
      },
      { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: '',
      isLocked: false,
      year: '',
    },
    defaultItem: {
      id: 0,
      name: '',
      isLocked: false,
      year: '',
    },
    yearSelectOptions: [] as number[],
    formRulesName: [
      (value: string) => !!value || 'Feld ist erforderlich',
      (value: string) =>
        (value && value.length <= 50) ||
        `Name muss weniger als 50 Zeichen lang sein (jetzt ${value.length})`,
    ],
    formRulesYear: [(value: string) => !!value || 'Feld ist erforderlich'],
    error: null as null | string,
    store: useStore(),
  }),

  computed: {
    isEdit(): boolean {
      return !!(this.editedIndex > -1);
    },
    deleteDialogText() {
      const user = getUser() as LoggedInUser;
      if (user.role === 'ADMIN') {
        return `Sind Sie sicher, dass Sie diesen Bericht löschen möchten? Es werden alle Antworten innerhalb dieses Berichts entfernt.`;
      } else {
        return `Sind Sie sicher, dass Sie diesen Bericht löschen möchten?`;
      }
    },
    formattedItems(): (OrganizationReport & {
      reportTemplate?: ReportTemplate;
      isLockedFormatted?: string;
      isAggregatedormatted?: string;
      year?: number;
    })[] {
      return this.items.map((item) => ({
        ...item,
        isLockedFormatted: item.isLocked ? 'Ja' : 'Nein',
        isAggregatedFormatted: item.isAggregated ? 'Ja' : 'Nein',
        year: item.reportTemplate?.year,
        hasCO2Report: item.organizationCO2Reports.length > 0 ? 'Ja' : 'Nein',
      }));
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.setData();
  },

  methods: {
    async setData() {
      try {
        const unusedReportTemplates =
          await trpcClient.reportTemplate.getUnusedByOrganizatonId.query({
            organizationId: this.organizationId,
          });

        this.yearSelectOptions = unusedReportTemplates.map((item) => item.year);
      } catch (error) {
        this.$router.push('/');
      }
    },

    async updateData() {
      this.$emit('refetch-data');
      await this.store.setOrganizationReports();
    },

    linkToViewItem(item: any): string {
      const parentId = this.$route.params.parentId;

      if (parentId) {
        return `/organizations/${parentId}/sub/${this.organizationId}/organization-reports/${item.id}`;
      } else {
        return `/organizations/${this.organizationId}/organization-reports/${item.id}`;
      }
    },

    editItem(item: any) {
      this.editedIndex = this.formattedItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item: any) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.error = null;
      this.isLoading = true;

      try {
        await trpcClient.organizationReport.deleteById.mutate({
          id: this.editedItem.id,
        });
        await this.updateData();
        await this.setData();
      } catch (error) {
        this.error = getErrorMessage(error);
      }
      this.closeDelete();
      this.isLoading = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    async save() {
      const isValidated = (this.$refs.form as VuetifyForm).validate();
      if (isValidated) {
        this.error = null;
        this.isLoading = true;

        if (this.editedIndex > -1) {
          try {
            await trpcClient.organizationReport.updateById.mutate({
              id: this.editedItem.id,
              name: this.editedItem.name,
              isLocked: this.editedItem.isLocked,
            });
          } catch (error) {
            this.error = getErrorMessage(error);
          }
        } else {
          try {
            await trpcClient.organizationReport.create.mutate({
              organizationId: this.organizationId,
              name: this.editedItem.name,
              year: Number(this.editedItem.year),
            });
          } catch (error) {
            this.error = getErrorMessage(error);
          }
        }

        this.close();
        this.isLoading = false;
        await this.updateData();
        await this.setData();
      }
    },
  },
});
