
import type { PropType } from 'vue';
import Vue from 'vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage, isValidEmail } from '@/utils/generic';
import type {
  LoggedInUser,
  Organization,
  OrganizationUserType,
  VuetifyForm,
} from '@/ts/types/generic';
import { getUser } from '@/components/login/login';
import IconWithTooltip from '@/components/library/IconWithTooltip.vue';
import InfoBanner from '@/components/library/InfoBanner.vue';

const OrganizationUserTypeTranslation: Record<OrganizationUserType, string> = {
  MEMBER: 'Mitglied',
  OWNER: 'Besitzer',
};

interface FormattedUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  fullName: string;
  isVerified: string;
  type: string;
  typeText: string;
}

export default Vue.extend({
  name: 'OrganizationUserCrud',
  components: {
    IconWithTooltip,
    InfoBanner,
  },
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
    users: {
      type: Array as PropType<FormattedUser[]>,
      required: true,
    },
  },
  data: () => ({
    currentUserId: 0,
    organization: null as null | Organization,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'fullName',
      },
      {
        text: 'E-mail',
        align: 'start',
        sortable: true,
        value: 'email',
      },
      {
        text: 'Rolle',
        align: 'start',
        sortable: true,
        value: 'typeText',
      },
      {
        text: 'Verifiziert?',
        align: 'start',
        sortable: true,
        value: 'isVerified',
      },
      { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
    ],
    selectItems: [
      {
        value: 'OWNER',
        text: OrganizationUserTypeTranslation.OWNER,
      },
      {
        value: 'MEMBER',
        text: OrganizationUserTypeTranslation.MEMBER,
      },
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      type: '' as OrganizationUserType,
    },
    defaultItem: {
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      type: '' as OrganizationUserType,
    },
    formRulesName: [
      (value: string) => !!value || 'Feld ist erforderlich',
      (value: string) =>
        (value && value.length <= 50) ||
        `Name muss weniger als 50 Zeichen lang sein (jetzt ${value.length})`,
    ],
    formRulesEmail: [
      (value: string) => !!value || 'Email ist erforderlich',
      (value: string) =>
        (value && value.length <= 255) ||
        `Email muss weniger als 255 Zeichen lang sein`,
      (value: string) => isValidEmail(value) || 'Es ist keine gültige E-Mail',
    ],
    formRulesType: [(value: string) => !!value || 'Feld ist erforderlich'],
    error: null as null | string,
  }),

  computed: {
    formTitle() {
      const newRecord = this.editedIndex === -1;

      if (newRecord) {
        return 'Neuer Nutzer';
      } else {
        return 'Nutzer bearbeiten';
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    const user = getUser() as LoggedInUser;
    this.currentUserId = user.id;
  },

  methods: {
    async updateData() {
      this.$emit('refetch-data');
    },

    editItem(item: any) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item: any) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await trpcClient.user.deleteById.mutate({
          id: this.editedItem.id,
        });
        await this.updateData();
        this.error = null;
      } catch (error) {
        this.error = getErrorMessage(error);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    async save() {
      const isValidated = (this.$refs.form as VuetifyForm).validate();
      if (isValidated) {
        const isEditForm = this.editedIndex > -1;
        const isSaveForm = !isEditForm;

        if (isSaveForm) {
          try {
            await trpcClient.user.create.mutate({
              organizationId: this.organizationId as number,
              email: this.editedItem.email,
              firstName: this.editedItem.firstName,
              lastName: this.editedItem.lastName,
              type: this.editedItem.type,
            });
            await this.updateData();
            this.error = null;
          } catch (error) {
            await this.updateData();
            this.error = getErrorMessage(error);
          }
        }

        if (isEditForm) {
          try {
            await trpcClient.user.updateById.mutate({
              id: this.editedItem.id,
              firstName: this.editedItem.firstName,
              lastName: this.editedItem.lastName,
              type: this.editedItem.type,
            });
            await this.updateData();
            this.error = null;
          } catch (error) {
            this.error = getErrorMessage(error);
          }
        }

        this.close();
      }
    },
  },
});
