
import Vue from 'vue';
import type {
  Organization,
  OrganizationCO2Report,
  OrganizationReport,
} from '@prisma/client';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';

type Items = (OrganizationCO2Report & {
  organizationReport: OrganizationReport & {
    organization: Organization;
  };
})[];

export default Vue.extend({
  name: 'CO2ReportList',
  data: () => ({
    headers: [
      {
        text: 'Erstellt am',
        align: 'start',
        sortable: true,
        value: 'createdAt',
      },
      {
        text: 'Organisation',
        align: 'start',
        sortable: true,
        value: 'organization',
      },
      {
        text: 'Berichtsjahr',
        align: 'start',
        sortable: true,
        value: 'reportYear',
      },
      {
        text: 'Berichtsname',
        align: 'start',
        sortable: true,
        value: 'reportName',
      },
    ],
    items: [] as Items,
    error: null as null | string,
  }),
  computed: {
    formattedItems() {
      return this.items.map((item) => {
        const currentDate = new Date(item.createdAt);
        const formattedData = currentDate.toLocaleString('de-DE', {
          year: 'numeric', // Four-digit year (e.g., "2023")
          month: 'long', // Full month name (e.g., "Juli")
          day: 'numeric', // Day of the month, numeric (e.g., "27")
          hour: '2-digit', // Two-digit hour (e.g., "09")
          minute: '2-digit', // Two-digit minutes (e.g., "05")
          second: '2-digit', // Two-digit seconds (e.g., "12")
          hour12: false, // Use 24-hour clock format
        });

        return {
          createdAt: formattedData,
          organization: item.organizationReport.organization.name,
          reportYear: item.baseYear,
          reportName: item.organizationReport.name,
        };
      });
    },
  },

  created() {
    this.setItems();
  },

  methods: {
    async setItems() {
      try {
        this.items = await trpcClient.co2Report.getAll.query();
      } catch (error) {
        this.error = getErrorMessage(error);
      }
    },
  },
});
