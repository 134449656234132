
import Vue from 'vue';
import ChipsDialog from '@/components/fragebogen/dialog/ChipsDialog.vue';

export default Vue.extend({
  components: { ChipsDialog },
  props: {
    berichtsstandard: {
      type: String,
      default: undefined,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
  },
});
