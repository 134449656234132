
import Vue from 'vue';

import KartenInhalte from '@/components/homepage/KartenInhalteFurtherInfo';
import InfoCardListView from '@/views/home/InfoCardListView.vue';

export default Vue.extend({
  components: {
    InfoCardListView,
  },
  data: () => ({
    localStorage,
    KartenInhalte,
  }),
});
