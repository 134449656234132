
import Vue from 'vue';

const showFrageId = (process.env.VUE_APP_SHOW_FRAGEID as string) === '1';

export default Vue.extend({
  props: {
    frageIdString: {
      type: String,
      required: false,
      default: () => undefined,
    },
  },
  data() {
    return {
      showFrageId,
    };
  },
});
