
import type { Component, PropType } from 'vue';
import Vue from 'vue';

import type { Textteil } from '@/components/fragebogen/fragetypen/zusammengesetzterText/ZusammengesetzterText.vue';

const defaultExport = Vue.extend({
  props: {
    prop: {
      type: Object as PropType<EinfacherText>,
      required: true,
    },
  },
});
export default defaultExport;

export class EinfacherText implements Textteil {
  text: string;
  component: Component = defaultExport;

  constructor(text: string) {
    this.text = text;
  }
}
