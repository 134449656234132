
import type { Component, PropType } from 'vue';
import Vue from 'vue';
import type { Textteil } from '@/components/fragebogen/fragetypen/zusammengesetzterText/ZusammengesetzterText.vue';
import MaterialityThemesTreeSelection from '@/views/report-template/components/MaterialityThemesTreeSelection.vue';

const defaultExport = Vue.extend({
  components: {
    MaterialityThemesTreeSelection,
  },
  props: {
    prop: {
      type: Object as PropType<InfokarteMaterialityFilter>,
      required: true,
    },
  },
  data() {
    return {};
  },
});
export default defaultExport;

export class InfokarteMaterialityFilter implements Textteil {
  component: Component = defaultExport;

  constructor() {}
}
