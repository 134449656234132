import type { Route } from 'vue-router';
import {
  allgemeinOrganisation,
  allgemeineAngaben,
  overviewVrEsgRisiko,
  parentOrganizationAsOwner,
  qualitativAuswertung,
  qualitativEnvironment,
  qualitativGovernance,
  qualitativSocial,
  qualitativVrEsgRisiko,
  quantitativAuswertung,
  quantitativAuswertungUpload,
  quantitativEnvironment,
  quantitativGovernance,
  quantitativGradzielAuswertung,
  quantitativSocial,
  quantitativVrEsgRisiko,
} from '@/components/navigation/navigation';

import type { PageTitleAndSubtitleTS } from '@/components/library/PageTitelAndSubtitle.vue';

const pageTitleAndSubtitlesData: { [key: string]: PageTitleAndSubtitleTS } = {
  [parentOrganizationAsOwner.pathInDerNavigation]: {
    title: 'Verwaltung',
    subtitle: `Hier können Sie Nutzer:innen für das EB-Sustainability-Scoring anlegen, Unterorganisationen verwalten, neue Berichte für weitere Berichtsjahre anlegen sowie bestehende Berichte verwalten und aggregieren.`,
  },
  [qualitativVrEsgRisiko.pathInDerNavigation]: {
    title: 'VR-Qualitative Fragen',
    subtitle: `Der VR-ESG-RisikoScore ist ein Instrument für Kreditinstitute zur ersten groben Einordnung der Nachhaltigkeitsleistung ihrer Kund:innen. Die Bewertung basiert zunächst nur auf der Branchenzugehörigkeit und des Standortes (Postleitzahl). Die nachstehenden qualitativen Fragen dienen der Konkretisierung dieser Bewertung. Die Fragen teilen sich auf in die Bereiche Environment (Umwelt und Klima), Social (soziale Aspekte) und Governance (Unternehmensführung).`,
  },

  [quantitativVrEsgRisiko.pathInDerNavigation]: {
    title: 'VR-Quantitative Fragen',
    subtitle: `Die quantitativen Fragen haben mit Ausnahme der Frage nach den Standorten (VR-E-Physisch) im VR-ESG-RisikoScore aktuell nur informellen Charakter, werden zukünftig jedoch im VR-ESG-RisikoScore relevanter. Die strukturierte Erfassung der zentralen Daten und insbesondere zur Berechnung des CO₂-Fußabdrucks können Sie direkt im EB-Sustainability-Scoring umsetzen.`,
  },

  [overviewVrEsgRisiko.pathInDerNavigation]: {
    title: 'Download VR-ESG-RisikoScore',
    subtitle: `Hier können Sie alle Fragen und Antworten zum VR-ESG-RisikoScore in einer gebündelten CSV-Datei herunterladen und die Daten für weitere Auswertungen nutzen. Falls es mehrere Standorte mit verschiedenen Postleitzahlen gibt, stellen Sie bitte sicher, dass Sie die erste quantitative Frage zu den Standorten unter VR-E-Physisch ebenfalls beantwortet haben. Die weiteren quantitativen Fragen haben zunächst nur informellen Charakter. Sobald alle zentralen Antworten vorliegen und Sie einen Namen für den Download eingegeben haben, können Sie die Antworten als CSV-Datei herunterladen.`,
  },

  [allgemeinOrganisation.pathInDerNavigation]: {
    title: 'Organisation',
    subtitle: `Hier geht es um die Erfassung von Rahmendaten wie Anschrift und Branchenzugehörigkeit (NACE-Code), Art der Berichterstattung und um ökonomische Daten wie Bilanzsumme, Umsatz, EBITDA und Personalkosten. Insbesondere die Angaben zur Branchenzugehörigkeit, zum EBITDA und zu den Personalkosten sind zur Bestimmung der Ausrichtung am 1,5 Gradziel notwendig.`,
  },

  [allgemeineAngaben.pathInDerNavigation]: {
    title: 'Allgemein Qualitativ',
    subtitle: `Die Berichtsanforderungen zum Bereich Allgemein umfassen Grundlagen zur Umsetzung und Tiefe der Nachhaltigkeitsberichtserstattung, zur Unternehmensführung, zur Berücksichtigung von Nachhaltigkeitsaspekten in den Strategien und zum Umgang mit Auswirkungen, Chancen und Risiken im Hinblick auf Nachhaltigkeitsaspekte. Die Allgemeinen Angaben (ESRS 2) sind für alle berichtspflichten Unternehmen verbindlich offenzulegen, unabhängig von dem Vorbehalt der Wesentlichkeit.`,
  },
  [qualitativEnvironment.pathInDerNavigation]: {
    title: 'Environment Qualitativ',
    subtitle: `Die Berichtsanforderungen zum Bereich Environment umfassen fünf übergeordnete Offenlegungsanforderungen zu den Themen Klimawandel, Umweltverschmutzung, Wasser- und Meeresressourcen, Biologische Vielfalt und Ökosysteme sowie Kreislaufwirtschaft. Dabei liegt der Schwerpunkt der Offenlegungsanforderungen auf den Angaben zum Klimawandel. Neben Angaben zu Maßnahmen, Parametern und Zielen zum Thema Klimawandel stehen hier vor allem Angaben zu den Energieverbräuchen, den CO₂-Emissionen und der Ausrichtung am 1,5 Grad-Ziel im Vordergrund.`,
  },
  [qualitativSocial.pathInDerNavigation]: {
    title: 'Social Qualitativ',
    subtitle: `Die Berichtsanforderungen zum Bereich Social umfassen vier übergeordnete Offenlegungsanforderungen zu den Themen Eigene Belegschaft, Arbeitskräfte in der Wertschöpfungskette, betroffene Gemeinschaften sowie Verbraucher und Endnutzer. Dabei liegt der Schwerpunkt der Offenlegungsanforderungen auf den Angaben zu der eigenen Belegschaft. Neben Angaben zur Mitarbeiterstruktur (Alter und Geschlecht), stehen Angaben zur Mitarbeiterförderung (Schulungen und Kompetenzentwicklung), Gesundheitsschutz und Sicherheit und zur angemessenen Entlohnung im Vordergrund.`,
  },
  [qualitativGovernance.pathInDerNavigation]: {
    title: 'Governance Qualitativ',
    subtitle: `Die Berichtsanforderungen zum Bereich Governance umfasst eine übergeordnete Berichtsanforderung zur Unternehmenspolitik, mit Schwerpunkten zu den Themen Unternehmenskultur, Schutz von Hinweisgebern, politisches Engagement, Management der Beziehungen zu Lieferanten sowie zu Korruption und Bestechung. In den Berichtsanforderungen zu den allgemeinen Angaben finden sich zudem noch weitere Anforderungen zum Thema Governance (Unternehmensführung).`,
  },
  [qualitativAuswertung.pathInDerNavigation]: {
    title: 'Auswertung Qualitativ',
    subtitle: `Hier finden Sie die Auswertung und Zusammenfassung des qualitativen Teils es EB-Sustainability-Scorings zur Standortbestimmung Ihrer Nachhaltigkeitsleistung. Neben der Gesamtauswertungen finden Sie detaillierte Teilscores auf Fragelevel und erste Empfehlungen zur systematischen Verbesserung der Nachhaltigkeitsleistung im Hinblick auf die Berichtserstattung.`,
  },
  [quantitativEnvironment.pathInDerNavigation]: {
    title: 'Environment Quantitativ',
    subtitle: `Die quantitativen Berichtsanforderungen zum Bereich Environment umfassen fünf übergeordnete Offenlegungsanforderungen zu den Themen Klimawandel, Umweltverschmutzung, Wasser- und Meeresressourcen, Biologische Vielfalt und Ökosysteme sowie Kreislaufwirtschaft. Dabei liegt der Schwerpunkt der Offenlegungsanforderungen auf den Angaben zum Klimawandel. Hier stehen die Angaben zu Energieverbräuchen, den CO₂-Emissionen und der Ausrichtung am 1,5 Grad-Ziel im Vordergrund.`,
  },
  [quantitativSocial.pathInDerNavigation]: {
    title: 'Social Quantitativ',
    subtitle: `Die quantitativen Berichtsanforderungen zum Bereich Social fokussieren sich auf die eigene Belegschaft. Neben Angaben zur Mitarbeiterstruktur (Alter und Geschlecht), stehen Angaben zur Mitarbeiterförderung (Schulungen und Kompetenzentwicklung), Gesundheitsschutz und Sicherheit und zur angemessenen Entlohnung im Vordergrund.`,
  },
  [quantitativGovernance.pathInDerNavigation]: {
    title: 'Governance Quantitativ',
    subtitle: `Die Berichtsanforderungen zum Bereich Governance umfasst eine übergeordnete Berichtsanforderung zur Unternehmenspolitik, mit Schwerpunkten zu den Themen Unternehmenskultur, Schutz von Hinweisgebern, politisches Engagement, Management der Beziehungen zu Lieferanten sowie zu Korruption und Bestechung. In den Berichtsanforderungen zu den Allgemeinen Angaben finden sich zudem noch weitere Anforderungen zum Thema Unternehmensführung.`,
  },
  [quantitativAuswertung.pathInDerNavigation]: {
    title: 'Übersicht Quantitativ',
    subtitle: `Hier finden Sie eine Übersicht aller zentralen Datenpunkte, Grafiken und Kommentare. Diese Übersicht können Sie als PDF-Datei speichern und zudem können Sie alle Datenpunkte in einer gebündelten CSV-Datei herunterladen und die Daten für weitere Auswertungen nutzen. Die Daten zu den CO₂-Emissionen bilden die Grundlage für die Bestimmung des 1,5 Gradziels Ihrer Organisation. Sollten Sie Unterorganisationen angelegt haben, können Sie unter „Verwaltung“ aggregierte Berichte der quantitativen Eingaben erstellen.`,
  },

  [quantitativAuswertungUpload.pathInDerNavigation]: {
    title: 'Upload quantitative Fragen',
    subtitle: `Hier können Sie eine Vorlage aller quantitativen Fragen in einer gebündelten CSV-Datei herunterladen und die Datei zur Beantwortung der Fragen nutzen. Nachdem Sie die Antworten in die Datei eingetragen haben, können Sie die Datei in dem Tool hochladen und Ihre Antworten werden automatisch übertragen.
Das Format der Vorlage darf dabei nicht geändert werden, damit die Antworten den richtigen Fragen zugeordnet werden können. Sollten schon Antworten zu den Fragen im Tool vorliegen, werden diese an den entsprechenden Stellen überschrieben, sofern eine neue Antwort in der CSV-Datei vorliegt. Die Änderungshistorie wird jedoch weiterhin wie gewohnt in der Datenbank gespeichert. Bestehende Werte im Tool werden nicht durch leere Zellen in der CSV-Datei überschrieben.`,
  },
  [quantitativGradzielAuswertung.pathInDerNavigation]: {
    title: '1,5 Gradziel',
    subtitle: `Das 1,5 Gradziel ist ein ambitioniertes Ziel des Pariser Abkommens, das darauf abzielt, die globale Erwärmung auf 1,5 Grad Celsius über dem vorindustriellen Niveau zu begrenzen. Es erfordert drastische Maßnahmen zur Reduzierung von Treibhausgasemissionen und zur Förderung nachhaltiger Praktiken, um die negativen Auswirkungen des Klimawandels einzudämmen. Dieses Ziel ist entscheidend, um die langfristige Stabilität unseres Planeten und die Lebensgrundlagen zukünftiger Generationen zu schützen. Es erfordert weltweite Zusammenarbeit und Engagement auf allen Ebenen, um eine nachhaltige und kohlenstoffarme Zukunft zu gestalten. Um das das ambitionierte Ziel zu erreichen sind zusätzliche Anstrengungen notwendig. Daher gilt es Reduktionspfade zu bestimmen und Maßnahmen zu ergreifen, um den ökologischen Fußabdruck zu verringern und einen Beitrag zur Erreichung des Pariser 1,5 Gradziels zu leisten. Das 1,5 Gradziel bildet damit eine wichtige Leitlinie für die Ausrichtung der Geschäftsstrategie und die zukünftige Entwicklung eines Unternehmens und ist Teil der Offenlegungsanforderungen der European Sustainability Reporting Standards. Die Ausrichtung am 1,5 Gradziel wird mit dem X-Degree Compatibility (XDC) von right. based on science bestimmt.

    Bestimmen Sie jetzt die Ausrichtung Ihrer Organisation am 1,5 Gradziel!`,
  },
};

export default (route: Route) => {
  return pageTitleAndSubtitlesData[route.path] || null;
};
