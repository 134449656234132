
import Vue from 'vue';
import type { PropType } from 'vue';
import type { MaterialityThemeTreeNodeWithReportStandards } from '../types';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';

export default Vue.extend({
  name: 'ReportStandardDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    editedNode: {
      type: Object as PropType<MaterialityThemeTreeNodeWithReportStandards>,
      required: true,
    },
    refetchTreeDataFn: Function,
  },
  data: () => ({
    dialog: false,
    selectedStandards: [] as number[],
    name: undefined as undefined | string,
    nameRules: [
      (v: string) => !!v || 'Name is required',
      (v: string) =>
        (v && v.length <= 255) || 'Name must be less than 255 characters',
    ],
  }),

  watch: {
    value(value: boolean) {
      this.dialog = value;
    },
    dialog(value: boolean) {
      this.$emit('input', value);
    },
    editedNode(value: MaterialityThemeTreeNodeWithReportStandards) {
      if (value.selectedReportStandardsIds) {
        this.selectedStandards = value.selectedReportStandardsIds;
      }
    },
  },

  methods: {
    closeDialog() {
      this.$emit('input', false);
    },
    async saveData() {
      try {
        await trpcClient.materialityThemes.setReportStandards.mutate({
          id: this.editedNode.id,
          reportStandardsIds: this.selectedStandards,
        });

        this.refetchTreeDataFn();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
      }

      this.closeDialog();
    },
  },
});
