
import type { Component, PropType } from 'vue';
import Vue from 'vue';
import type { Textteil } from '@/components/fragebogen/fragetypen/zusammengesetzterText/ZusammengesetzterText.vue';
import QuantitativExpansionPanelsNew from '@/components/fragebogen/quantitativ/QuantitativExpansionPanelsNew.vue';
import {
  fuhrpark,
  responses,
  responsesPreviousYear,
} from '@/components/fragebogen/fragetypen/zusammengesetzterText/Fuhrpark';

const defaultExport = Vue.extend({
  components: { QuantitativExpansionPanelsNew },
  props: {
    prop: {
      type: Object as PropType<InfokarteFragenFuhrpark>,
      required: true,
    },
  },
  data() {
    return {
      fuhrpark,
      responses,
      responsesPreviousYear,
    };
  },
});
export default defaultExport;

export class InfokarteFragenFuhrpark implements Textteil {
  component: Component = defaultExport;

  constructor() {}
}
