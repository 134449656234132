import { DoubleNewLine } from '@/components/fragebogen/fragetypen/zusammengesetzterText/DoubleNewLine.vue';
import { EinfacheFrage } from '@/components/fragebogen/fragetypen/zusammengesetzterText/EinfacheFrage.vue';
import { EinfacherText } from '@/components/fragebogen/fragetypen/zusammengesetzterText/EinfacherText.vue';
import { InfokarteDetailAuswertung } from '@/components/fragebogen/fragetypen/zusammengesetzterText/InfokarteDetailAuswertung.vue';
import { InfokarteGesamtAuswertung } from '@/components/fragebogen/fragetypen/zusammengesetzterText/InfokarteGesamtAuswertung.vue';
import { InfokarteFragenFuhrpark } from '@/components/fragebogen/fragetypen/zusammengesetzterText/InfokarteFragenFuhrpark.vue';
import KartenInhalt from '@/components/homepage/KartenInhalt';
import router from '@/router';
import { ScrollToId } from '@/ts/ScrollTo';
import { homeFurtherInfo } from '@/components/navigation/navigation';
import { BoldText } from '@/components/fragebogen/fragetypen/zusammengesetzterText/BoldText.vue';
import { EinfacherLink } from '@/components/fragebogen/fragetypen/zusammengesetzterText/EinfacherLink.vue';
import { EinfacherTextWithNewLine } from '@/components/fragebogen/fragetypen/zusammengesetzterText/EinfacherTextWithNewLine.vue';
import { NewLine } from '@/components/fragebogen/fragetypen/zusammengesetzterText/NewLine.vue';
import { InfokarteMaterialityFilter } from '@/components/fragebogen/fragetypen/zusammengesetzterText/InfokarteMaterialityFilter.vue';

const WillkommenKarte = {
  kartenTitel: 'Willkommen',
  ersteKarte: true,
  buttonText: 'Auswertung',
  wirdAngezeigt: () => {
    return true;
  },
  clickedWeiter: () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    AuswertungKarte.anzeigen = true;
    localStorage.AuswertungKarteAnzeigen = 'true';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    new ScrollToId(AuswertungKarte.karteId).scrollToId();
  },
  htmlKartenBody: new KartenInhalt([
    new EinfacherText('Herzlich willkommen'),
    new DoubleNewLine(),
    new EinfacherText('zum EB-Sustainability-Scoring der Evangelischen Bank!'),
    new DoubleNewLine(),
    new EinfacherText(
      'Das EB-Sustainability-Scoring bietet Ihnen einen einfachen und zugleich konstruktiven Einstieg in eine nachhaltige Unternehmensführung: Es ermöglicht eine erste Standortbestimmung der aktuellen Nachhaltigkeitsleistung Ihres Unternehmens.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Auf Grundlage dieser Standortbestimmung können Lücken aufgedeckt, Handlungsfelder priorisiert und ein Zielbild anhand konkreter Kennzahlen entwickelt werden. Ein automatischer Branchenvergleich verschafft Transparenz und liefert zusätzliche Motivation zur Verbesserung der eigenen Nachhaltigkeitsleistung. Zudem unterstützt Sie das EB-Sustainability-Scoring beim Start in die Erstellung eines Nachhaltigkeitsberichts.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Die Ergebnisse des EB-Sustainability-Scorings schaffen somit eine Diskussionsgrundlage für Ihre Entscheidungsträger und Gremien zur kontinuierlichen Verbesserung und Weiterentwicklung der nachhaltigen Ausrichtung Ihres Unternehmens.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Das Themenfeld der Nachhaltigkeit ist komplex und mit der Fülle an Verordnungen, regulatorischen Anforderungen, Rahmenwerken, Fachbegriffen und Abkürzungen teils schwer zu überblicken. Das EB-Sustainability-Scoring richtet sich konsequent an den zukünftigen Berichtspflichten der neuen Nachhaltigkeitsberichtsrichtlinie der EU - der Corporate Sustainability Reporting Directive (CSRD - aus. Dadurch geben wir dem Thema - entlang der Bereiche Environment (Umwelt), Social (Soziales) und Governance (Unternehmensführung) - eine klare Struktur und schaffen Klarheit, Transparenz und ein einheitliches Verständnis von Nachhaltigkeit in der Organisation.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Für weitere Informationen lesen Sie die Einführung oder starten Sie direkt mit dem EB-Sustainability-Scoring und der Beantwortung der Fragen zu den Bereichen Allgemein, Environment, Social und Governance (auf der linken Seite)!'
    ),
  ]),
  anzeigen: false,
  cardWithBackground: true,
  karteId: 'willkommen',
};
const AuswertungKarte = {
  kartenTitel: 'Auswertung: So sieht die Auswertung aus',
  ersteKarte: false,
  buttonText: 'Qualitative Fragestellungen',
  wirdAngezeigt: () => {
    return JSON.parse(localStorage.AuswertungKarteAnzeigen || false);
  },
  clickedWeiter: () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    QualitativeKarte.anzeigen = true;
    localStorage.QualitativeKarteAnzeigen = 'true';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    new ScrollToId(QualitativeKarte.karteId).scrollToId();
  },

  htmlKartenBody: new KartenInhalt([
    new InfokarteGesamtAuswertung(),
    new EinfacherText(
      'Unmittelbar nach Beantwortung aller qualitativen Fragen erhalten Sie Ihre Auswertung und Standortbestimmung. Auf der obersten Ebene finden Sie das Gesamtscoring, das auf einen Blick die Einordnung des aktuellen Stands der Nachhaltigkeitsleistung der Organisation ermöglicht. Die Bewertung erfolgt aufgrund der leichten Verständlichkeit und Interpretierbarkeit anhand von bis zu fünf erreichbaren Punkten (von 1 = unzureichend bis 5 = hervorragend).'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Das Gesamtscoring wird in drei Teilscorings aufgeschlüsselt, mit denen die Nachhaltigkeitsleistung in den Bereichen Environment, Social und Governance getrennt voneinander ausgewiesen werden.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Nachdem sich ein entsprechender Datenhaushalt aufgebaut hat, wird neben des Scorings der eigenen Organisation auch das Scoring vergleichbarer Organisationen für die Gesamt- und Teilscorings ausgewiesen. Dies ermöglicht es, die eigene Nachhaltigkeitsleistung im Branchenvergleich zu bewerten.'
    ),
    new DoubleNewLine(),
    new InfokarteDetailAuswertung(),
    new EinfacherText(
      'Um eine tiefergehende Beurteilung zu ermöglichen, können diese Teilnoten weiter heruntergebrochen werden. Als Beispiel ist hier das Teilscoring des Nachhaltigkeitsbereichs „Environment“ dargestellt. Den CSRD-Berichtsanforderungen folgend ist dieser Bereich in die Teilbereiche Klimawandel, Umweltschutz, Wasser- und Meeresressourcen, Biologische Vielfalt und Ökosysteme sowie Ressourcennutzung und Kreislaufwirtschaft aufgeteilt. Die übersichtliche Darstellung in einem Netzdiagramm zeigt direkt relevante Handlungsfelder auf.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Fahren Sie mit dem Mauszeiger über die Punkte der entsprechenden Teilbereiche, wird das Teilscoring (hier z. B. Scoring von 3 im Teilbereich Klimawandel) angezeigt.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Um direkt Handlungsbedarf in den einzelnen Teilbereichen aufzuzeigen, sehen sie zudem  eine Liste mit den entsprechenden Fragen aus dem Bereich und Ihren gewählten Antworten. Anhand einer Ampellogik von rot (dringender Handlungsbedarf), über gelb (Handlungsbedarf) bis grün (kein akuter Handlungsbedarf) können Sie noch konkreter Handlungsbedarf ableiten.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Diese Auswertung des qualitativen Scorings dient als Ausgangspunkt für die weiteren Schritte. Die Auswertung hilft bei der Priorisierung der wichtigsten Themen und gibt Anregungen für mögliche Verbesserungen. Nicht alle (Teil-)Bereiche sind für die verschiedenen Organisationen gleichermaßen von Bedeutung. Je nach Schwerpunkt der Geschäftstätigkeit, Größe und Standort der Organisation können verschiedene Aspekte der Nachhaltigkeit einen hohen bzw. untergeordneten Stellenwert einnehmen. Dennoch ist es für die erste eigene Standortbestimmung unerlässlich, für alle Schwerpunkte und Fragestellungen zu sensibilisieren, um die Sprachfähigkeit und Transparenz innerhalb der Organisation und gegenüber den Stakeholdern sicherzustellen. Zudem kann die Auswertung zur Vorbereitung der Wesentlichkeitsanalyse mit den Stakeholdern herangezogen werden.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Weiterhin ermöglicht der qualitative Teil einen strukturierten Einstieg in die Erfassung von quantitativen Daten. Hierzu werden nicht nur Handlungsfelder aufgezeigt, sondern auch direkt auf Berichtsstandards und übergeordnete Rahmenwerke verwiesen, um zentrale Kennzahlen zu identifizieren. Der Rückgriff auf die CSRD-Berichtsanforderungen ermöglicht auch hier eine klare und einheitliche Definition auf europäischer Ebene.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Das qualitative EB-Sustainability-Scoring sollte idealerweise jährlich wiederholt und auf Aktualität überprüft werden. Der dokumentierte und messbare Fortschritt soll Motivation und Ansporn zugleich sein, um das Unternehmen kontinuierlich nachhaltiger aufzustellen.'
    ),
  ]),
  anzeigen: false,
  karteId: 'auswertung',
};
const QualitativeKarte = {
  kartenTitel: 'Qualitative Fragestellungen',
  ersteKarte: false,
  buttonText: 'Quantitative Fragestellungen',
  wirdAngezeigt: () => {
    return JSON.parse(localStorage.QualitativeKarteAnzeigen || false);
  },
  clickedWeiter: () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    QuantitativeKarte.anzeigen = true;
    localStorage.QuantitativeKarteAnzeigen = 'true';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    new ScrollToId(QuantitativeKarte.karteId).scrollToId();
  },
  htmlKartenBody: new KartenInhalt([
    new EinfacherText(
      'Die Fragestellungen des qualitativen Teils des Scorings sind immer gleich aufgebaut und hier zur Verdeutlichung anhand der Frage zur Erfassung der Treibhausgasemissionen einmal beispielhaft dargestellt. Unter der Frage an sich erhalten Sie eine Antworthilfe mit wesentlichen Informationen zum Hintergrund der jeweiligen Frage und weiterführenden Hinweisen. Mit der Mouse-over-Funktion erhalten Sie nähere Definitionen zu relevanten Begriffen aus dem weiten Feld der Nachhaltigkeit sowie Hilfestellungen zu den Systematisierungskriterien des EB-Sustainability-Scorings. Dies schafft ein einheitliches Verständnis der Nachhaltigkeitsbegriffe und hilft bei der korrekten Antwortauswahl für Ihr Unternehmen. Zudem wird schon hier auf wesentliche Kennzahlen zur Datenerhebung hingewiesen.'
    ),
    new DoubleNewLine(),
    new EinfacheFrage(),
    new EinfacherText(
      'Die Antwortmöglichkeiten folgen keiner starren Struktur und reichen von einfachen „Ja / Nein“- Fragen bis hin zu einer Vielzahl an Antwortmöglichkeiten mit verschiedenen Abstufungen. Die Abstufungen der Antwortmöglichkeiten bauen dabei aufeinander auf und stellen erhöhte Anforderungen dar. Diese Anforderungen können sich je nach Nachhaltigkeitsbereich und Fragestellung unterscheiden. Die weiteren Abstufungen bieten somit auch eine erste Orientierung für vorhandene Verbesserungspotenziale. Ziel ist es folglich, eine möglichst genaue Bestimmung der eigenen Nachhaltigkeitsleistung anhand konkreter Anforderungen zu ermöglichen.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Unter den Antwortmöglichkeiten finden Sie Verweise und Verlinkungen zu dem jeweiligen Berichtsstandard der Corporate Sustainability Reporting Directive (CSRD) sowie zu zwei weiteren relevanten Berichtsstandards, dem Deutschen Nachhaltigkeitskodex (DNK) sowie der Global Reporting Initiative (GRI). Diese Verlinkungen liefern vertiefende Erklärungen und Orientierung zur Beantwortung der jeweiligen Frage und helfen bei der Vorbereitung und Aufbereitung von Informationen für die eigene (freiwillige) Berichterstattung.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Aufgrund der Breite und Tiefe der Fragestellungen entlang der Bereiche Environment, Social und Governance ist es empfehlenswert, mehrere Expert:innen aus den Fachbereichen in die Durchführung des EB-Sustainability-Scorings einzubeziehen. Sie können jederzeit Nutzer:innen unter der Menü-Punkt Verwaltung zum EB-Sustainability-Scoring einladen.'
    ),
  ]),
  anzeigen: false,
  karteId: 'qualitativ',
};

const QuantitativeKarte = {
  kartenTitel: 'Quantitative Fragestellungen',
  ersteKarte: false,
  buttonText: 'Informationen Erfassung Fuhrpark',
  wirdAngezeigt: () => {
    return JSON.parse(localStorage.QuantitativeKarteAnzeigen || false);
  },
  clickedWeiter: () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    InformationenErfassungFuhrparkKarte.anzeigen = true;
    localStorage.InformationenFuhrparkKarteAnzeigen = 'true';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    new ScrollToId(InformationenErfassungFuhrparkKarte.karteId).scrollToId();
  },
  htmlKartenBody: new KartenInhalt([
    new EinfacherText(
      'Die Struktur der quantitativen Fragen folgt ebenfalls der Struktur der drei Bereiche Environment, Social und Governance. Schwerpunkt der Berichterstattung liegt aktuell auf den Teilbereichen Klimawandel (Environment) und eigene Belegschaft (Social).'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Zur Verdeutlichung der Eingabe von quantitativen Kennzahlen ist eine Abfrage zum Fuhrpark der Organisation beispielhaft dargestellt.'
    ),
    new DoubleNewLine(),
    new InfokarteFragenFuhrpark(),
    new DoubleNewLine(),
    new EinfacherText(
      'Nach Eingabe der entsprechenden Kennzahlen erhalten Sie direkt visuelles Feedback, um ein Gespür für die Datenpunkte zu bekommen und um die Daten auf Plausibilität zu prüfen.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Um eine Berechnung des CO₂-Fußabdrucks zu ermöglichen, sind für die verschiedenen Energieverbrauchsarten CO₂-Emissionsfaktoren hinterlegt, die eine Umrechnung in CO₂-Äquvialente ermöglicht. Aus der umfangreichen Liste von Verbrauchsarten können Sie automatisiert den CO₂-Fußabdruck Ihrer Organisation bestimmen. Dabei können Sie auf die hinterlegten Daten zurückgreifen oder eigene Faktoren, z.B. für den Strommix, hinterlegen.'
    ),
  ]),
  anzeigen: false,
  karteId: 'quantitativ',
};

const InformationenErfassungFuhrparkKarte = {
  kartenTitel: 'Informationen zur Erfassung des Fuhrparks',
  ersteKarte: false,
  buttonText: 'Wesentlichkeitsfilter',
  wirdAngezeigt: () => {
    return JSON.parse(localStorage.InformationenFuhrparkKarteAnzeigen || false);
  },
  clickedWeiter: () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    WesentlichkeitsfilterKarte.anzeigen = true;
    localStorage.WesentlichkeitsfilterKarteAnzeigen = 'true';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    new ScrollToId(WesentlichkeitsfilterKarte.karteId).scrollToId();
  },
  htmlKartenBody: new KartenInhalt([
    new BoldText(
      new EinfacherText('Environment Quantitativ')
    ),
    new DoubleNewLine(),
    new BoldText(
      new EinfacherText('Klimawandel -> Fuhrpark')
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Verbrauchs- bzw. Leistungsdaten aus dem Fuhrpark können aus unterschiedlichen Quellen bezogen werden. Sofern zentral Tankkarten ausgegeben werden, können diese Daten oftmals über den Herausgeber direkt bezogen werden. Alternativ kann das Rechnungswesen diese Daten z.B. direkt aus den eingereichten Tankquittungen ableiten. Sollten keine direkten Verbrauchsdaten zu Verfügung stehen, können die Fahrtenbücher zum Stichtag ausgelesen werden. Anhand der Kilometerstände lässt sich eine grobe Aussage über die emittierten Emissionen treffen. Die Erfassung des tatsächlichen Verbrauchs an fossilen Energieträgern gibt hingegen ein wesentlich präziseres Bild über die tatsächlichen Emissionen. So müssen die tatsächlich emittierten Emissionen nicht mit der Kilometerleistung ansteigen, wenn z.B. die Fahrzeugflotte modernisiert und entsprechend verbrauchsärmere Fahrzeuge eingesetzt werden. Auch können Schulungsmaßnahmen, z.B. über eine möglichst umweltfreundliche Fahrweise, erhebliche Einsparung bedeuten, die sich nicht in der Erfassung von Strecken ablesen lassen.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Nicht unberücksichtigt bleiben sollte auch das Vorhalten von Nachweisen für die Auditoren. Hier halten zentrale Erfassungssysteme, mit nachvollziehbaren Quellen wie z.B. die Abrechnungen der Tankkartenbetreiber, den Verwaltungsaufwand gering.'
    ),
    new DoubleNewLine(),
    new BoldText(
      new EinfacherText('Hinweise zur Standardisierung von Maßeinheiten bei Energie und Emissionen einschließlich der Berechnung letzteren')
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Auditoren verlangen für die Berichterstattung eine Umrechnung der Verbräuche in Megawattstunden (MWh) sowie die Angabe der CO₂-Emissionen in Tonnen CO₂. Aus diesem Grund werden die Verbrauchsdaten in den Grafiken zusätzlich in MWh angeben.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Basis der Umrechnungen liefert u.a. das „Informationsblatt CO₂-Faktoren“ welches das Bundesamt für Wirtschaft und Ausfuhrkontrolle (BAFA) bereitstellt.  '
    ),
    new DoubleNewLine(),
    new EinfacherLink('https://www.bafa.de/SharedDocs/Downloads/DE/Energie/eew_infoblatt_co2_faktoren_2021.html', 'https://www.bafa.de/SharedDocs/Downloads/DE/Energie/eew_infoblatt_co2_faktoren_2021.html'),
    new DoubleNewLine(),
    new EinfacherTextWithNewLine(new EinfacherText('Beispielrechnung:')),
    new EinfacherTextWithNewLine(new EinfacherText('10l Dieselkraftstoff')),
    new EinfacherTextWithNewLine(new EinfacherText('x Heizwert von Diesel (9,96 kWh)')),
    new EinfacherTextWithNewLine(new EinfacherText('= 99,6 kWh Diesel ')),
    new EinfacherTextWithNewLine(new EinfacherText('/ Umrechnungen in MWh (1/1000)')),
    new EinfacherTextWithNewLine(new EinfacherText('= 0,0996 MWh')),
    new EinfacherTextWithNewLine(new EinfacherText('Demnach entsprechen 10 Liter Diesel einem Heizwert von 0,0996 MWh.')),
    new NewLine(),
    new EinfacherTextWithNewLine(new EinfacherText('Wird nun der entsprechende Emissionsfaktor der BAFA verwendet, welcher in tCO₂ / MWh (Tonnen CO₂ pro Megawattstunde) angegeben wird, emittiert der Verbrauch von:')),
    new NewLine(),
    new EinfacherTextWithNewLine(new EinfacherText('0,0996 MWh Diesel (10l)')),
    new EinfacherTextWithNewLine(new EinfacherText('x 0,266 tCO₂/MWh (Dieselspezifischer Emissionsfaktor)')),
    new EinfacherTextWithNewLine(new EinfacherText('= 0,0264936 Tonnen CO₂')),
    new NewLine(),
    new EinfacherTextWithNewLine(new EinfacherText('Der Verbrauch von 10 Litern Diesel verursacht dementsprechend einen CO₂-Ausstoß von ~26,5 kg CO₂.')),
    new NewLine(),
    new EinfacherTextWithNewLine(new EinfacherText('Abschließender Hinweis: Werden in Gebäuden z.B. Heizöl-Brennwertkessel eingesetzt, muss dementsprechend die Berechnung der eingesetzten Energiemenge und der Emissionen auf die Brennwerte des Energieträgers sowie der daraus geänderten Emissionen zurückgegriffen werden.  ')),
  ]),
  anzeigen: false,
  cardWithBackground: true,
  karteId: 'informationenFuhrpark',
};


const WesentlichkeitsfilterKarte = {
  kartenTitel: 'Wesentlichkeitsfilter',
  ersteKarte: false,
  buttonText: 'Weitere Informationen',
  wirdAngezeigt: () => {
    return JSON.parse(localStorage.WesentlichkeitsfilterKarteAnzeigen || false);
  },
  clickedWeiter: () => {
    router.push(homeFurtherInfo.pathInDerNavigation);
  },
  htmlKartenBody: new KartenInhalt([
    new EinfacherText('Mit der Funktion des Wesentlichkeitsfilters haben Sie die Möglichkeit den Umfang des quantitativen Teils des EB-Sustainability-Scorings anzupassen. Mit dem Wesentlichkeitsfilter können Sie die Berichtsstandards auswählen, die für Ihr Unternehmen wesentlich sind. Nur die quantitativen Fragen zu den von Ihnen gewählten Themen, Unterthemen oder Unter-Unterthemen werden anschließend im quantitativen Teil des Tools angezeigt. Sollten Sie keine Auswahl treffen, werden alle quantitativen Fragen angezeigt.')
    ,
    new DoubleNewLine(),
    new EinfacherText('Die Auswahl im Wesentlichkeitsfilter findet für den jeweiligen Bericht bzw. für das jeweilige Berichtsjahr statt. Die Berichtsinhalte können also für jedes Berichtsjahr individuell ausgewählt werden. Die zugrundeliegenden regulatorischen Anforderungen (z. B. ESRS E1-1) können Sie mit einem Klick auf das Informations-Symbol einsehen.'),
    new DoubleNewLine(),
    new EinfacherText('Wählen Sie beispielsweise alle Themen aus dem Bereich Environment, dann werden die Themen Klimawandel, Umweltverschmutzung, Wasser- und Meeresressourcen, Biodiversität und Ökosysteme sowie Ressourcennutzung und Kreislaufwirtschaft im quantitativen Teil des EB-Sustainability-Scorings angezeigt. Die Themen aus den Bereichen Social und Governance würden hingegen ausgeblendet. Sie können die jeweiligen quantitativen Themen auch wieder anwählen. Sofern Themen ausgeblendet werden, diese aber angezeigt werden sollen, wenden Sie sich bitte an eine Mitarbeiter:in Ihres Unternehmens mit den entsprechenden Verwaltungsrechten.'),
    new DoubleNewLine(),
    new EinfacherText('Den Wesentlichkeitsfilter finden Sie unter Verwaltung, Berichte. Sie benötigen dementsprechend Verwaltungsrechte, um die wesentlichen Themen zu definieren. Mit einem Klick auf das Filter-Symbol gelangen zu der Themenauswahl.'),
    new DoubleNewLine(),
    new InfokarteMaterialityFilter(),

  ]),
  anzeigen: false,
  karteId: 'wesentlichkeitsfilter',
};

export default [
  WillkommenKarte,
  AuswertungKarte,
  QualitativeKarte,
  QuantitativeKarte,
  InformationenErfassungFuhrparkKarte,
  WesentlichkeitsfilterKarte
];
