import Vue from 'vue';

import { getErrorMessage } from '@/utils/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';

export default Vue.extend({
  methods: {
    handleError(error: any, prefixOfErrorMessage: string) {
      const errorMessage = getErrorMessage(error);
      const snackbarText = `${prefixOfErrorMessage} ${errorMessage}`;
      this.$root.$emit(
        SnackbarAnzeigen.eventName,
        new SnackbarAnzeigen('error', snackbarText)
      );
    },
  },
});
