
import Vue from 'vue';
import UnsereEmpfehlung from '@/components/auswertung/UnsereEmpfehlung.vue';
import BereichsNoten from '@/components/scores/BereichsNoten.vue';
import ScoreRadar from '@/components/scores/ScoreRadar.vue';
import { trpcClient } from '@/utils/trpc';
import AntwortZusammenfassung from '@/components/auswertung/AntwortZusammenfassung.vue';
import type {
  QualitativeProgress,
  ReportDataBackend,
} from '@/utils/backendTypes';
import {
  qualitativEnvironment,
  qualitativGovernance,
  qualitativSocial,
} from '@/components/navigation/navigation';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import PdfExportButton from '@/components/library/PdfExportButton.vue';
import { generateAndDownloadReportPdf } from '@/utils/pdfExport';
import {
  downloadFileInsideFrontend,
  getErrorMessage,
  getFileName,
} from '@/utils/generic';

export default Vue.extend({
  components: {
    BereichsNoten,
    ScoreRadar,
    UnsereEmpfehlung,
    AntwortZusammenfassung,
    PdfExportButton,
  },
  data() {
    return {
      reportData: {} as ReportDataBackend,
      showAuswertung: null as null | boolean,
      showLoading: null as null | boolean,
      isExportLoading: false,
      allProgress: {} as QualitativeProgress,
      qualitativEnvironment,
      qualitativSocial,
      qualitativGovernance,
    };
  },
  computed: {
    progressEnvironment(): number {
      return Math.round(this.allProgress.QUALITATIVE_ENVIRONMENT || 0);
    },
    progressSocial(): number {
      return Math.round(this.allProgress.QUALITATIVE_SOCIAL || 0);
    },
    progressGovernance(): number {
      return Math.round(this.allProgress.QUALITATIVE_GOVERNANCE || 0);
    },

    gesamtScrore() {
      return this.reportData.score;
    },
    environmentScore() {
      return this.reportData.QUALITATIVE_ENVIRONMENT?.score;
    },
    environmentCategories() {
      return this.reportData.QUALITATIVE_ENVIRONMENT?.categories;
    },
    socialScore() {
      return this.reportData.QUALITATIVE_SOCIAL?.score;
    },
    socialCategories() {
      return this.reportData.QUALITATIVE_SOCIAL?.categories;
    },
    governanceScore() {
      return this.reportData.QUALITATIVE_GOVERNANCE?.score;
    },
    governanceCategories() {
      return this.reportData.QUALITATIVE_GOVERNANCE?.categories;
    },
    isPdfExportMode() {
      return this.$route.query.pdf != null;
    },
  },
  async created() {
    this.allProgress =
      await trpcClient.question.getAllQualitativeProgress.query();

    this.showAuswertung = this.allSectionAreFullAnswered(this.allProgress);

    if (this.showAuswertung) {
      this.showLoading = true;
      this.reportData =
        await trpcClient.question.getQualitativeReportData.query();
      this.showLoading = false;
    }
  },

  methods: {
    async downloadDocx() {
      try {
        this.isExportLoading = true;

        const docxContentBase64 =
          await trpcClient.documentsDOCX.downloadQualitativeReport.query();

        const binaryString = window.atob(docxContentBase64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const fileType = 'docx';
        const fileName = getFileName('auswertung-qualitativ', fileType);

        downloadFileInsideFrontend(bytes, fileName, fileType);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
      } finally {
        this.isExportLoading = false;
      }
    },
    async printPage() {
      const pdfUrl = `${
        process.env.VUE_APP_API_URL || ''
      }/pdf-export-qualitativ`;
      const fileNamePrefix = 'export-qualitativ';
      this.isExportLoading = true;
      try {
        await generateAndDownloadReportPdf(pdfUrl, fileNamePrefix);
      } catch (error) {
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', 'Error generating PDF')
        );
      } finally {
        this.isExportLoading = false;
      }
    },
    allSectionAreFullAnswered(allProgress: QualitativeProgress): boolean {
      return (
        allProgress.QUALITATIVE_ENVIRONMENT === 100 &&
        allProgress.QUALITATIVE_SOCIAL === 100 &&
        allProgress.QUALITATIVE_GOVERNANCE === 100
      );
    },
  },
});
