import { render, staticRenderFns } from "./StackedBarChart.vue?vue&type=template&id=ce4af954&scoped=true"
import script from "./StackedBarChart.vue?vue&type=script&lang=ts"
export * from "./StackedBarChart.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce4af954",
  null
  
)

export default component.exports