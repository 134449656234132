
import Vue from 'vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import type { VuetifyForm } from '@/ts/types/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import IconWithTooltip from '@/components/library/IconWithTooltip.vue';

interface FileData {
  [key: string]: string | undefined;
}

export default Vue.extend({
  name: 'ReportTemplateListView',
  components: {
    IconWithTooltip,
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'label',
      },
      {
        text: 'Jahr',
        align: 'start',
        sortable: true,
        value: 'year',
      },
      {
        text: 'Qualitative Fragen',
        align: 'start',
        sortable: true,
        value: 'hasQualitativeQuestionsFormatted',
      },
      {
        text: 'Quantitative Fragen',
        align: 'start',
        sortable: true,
        value: 'hasQuantitativeQuestionsFormatted',
      },
      {
        text: 'Aktiviert',
        align: 'start',
        sortable: true,
        value: 'isEnabledFormatted',
      },
      { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
    ],
    items: [] as any[],
    editedIndex: -1,
    editedItem: {
      id: 0,
      label: '',
      year: String(new Date().getFullYear() - 1),
      isEnabled: false,
      qualitativeFile: null as File | null,
      quantitativeFile: null as File | null,
    },
    defaultItem: {
      id: 0,
      label: '',
      year: String(new Date().getFullYear() - 1),
      isEnabled: false,
      qualitativeFile: null,
      quantitativeFile: null,
    },
    formRulesLabel: [
      (value: string) => !!value || 'Name ist erforderlich',
      (value: string) =>
        (value && value.length <= 50) ||
        `Name muss weniger als 50 Zeichen lang sein (jetzt ${value.length})`,
    ],
    formRulesYear: [
      (value: string) => {
        return !!value || 'Jahr ist erforderlich';
      },
      (value: string) => {
        return !Number.isNaN(Number(value)) || 'Jahr ist keine gültige Zahl.';
      },
      (value: string) => {
        const valueNumber = Number(value);
        return (
          (valueNumber >= 2000 && valueNumber <= 2100) ||
          `Jahr muss zwischen 2000 un 2100 sein (jetzt ${value})`
        );
      },
    ],
    error: null as null | string,
    errorsQualitativeCSV: [] as string[],
    errorsQuantitativeCSV: [] as string[],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Neuer Bericht' : 'Bericht bearbeiten';
    },
    isIsEnabledHidden() {
      return this.editedIndex > -1;
    },
    formattedItems() {
      return this.items.map((item) => {
        return {
          ...item,
          isEnabledFormatted: item.isEnabled ? 'Ja' : 'Nein',
          hasQualitativeQuestionsFormatted: item.hasQualitativeQuestions
            ? 'Ja'
            : 'Nein',
          hasQuantitativeQuestionsFormatted: item.hasQuantitativeQuestions
            ? 'Ja'
            : 'Nein',
        };
      });
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.setItems();
  },

  methods: {
    async setReportStandards() {
      try {
        const result =
          await trpcClient.reportTemplate.setExistingReportStandards.mutate();
        if (result.success) {
          this.$root.$emit(
            SnackbarAnzeigen.eventName,
            new SnackbarAnzeigen(
              'success',
              `Die Berichtsstandards wurden erfolgreich eingestellt.`
            )
          );
        }
      } catch (error) {
        this.error = getErrorMessage(error);
      }
    },
    async setItems() {
      try {
        this.items = await trpcClient.reportTemplate.getAll.query();
      } catch (error) {
        this.error = getErrorMessage(error);
      }
    },

    editItem(item: any) {
      this.editedIndex = this.formattedItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item: any) {
      this.editedIndex = this.formattedItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await trpcClient.reportTemplate.deleteById.mutate({
          id: this.editedItem.id,
        });
        await this.setItems();
        this.error = null;
      } catch (error) {
        this.error = getErrorMessage(error);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    async save() {
      const isValidated = (this.$refs.form as VuetifyForm).validate();

      if (isValidated) {
        this.error = null;
        this.errorsQualitativeCSV = [];
        this.errorsQuantitativeCSV = [];

        if (this.editedIndex > -1) {
          await this.onUpdate();
        } else {
          await this.onCreate();
        }
      }
    },

    async onCreate() {
      try {
        this.isLoading = true;

        const hasQualitativeFile = !!this.editedItem.qualitativeFile;
        const hasQuantitativeFile = !!this.editedItem.quantitativeFile;

        const filePromises = [];

        if (hasQualitativeFile) {
          filePromises.push(
            this.readFileAsync(
              this.editedItem.qualitativeFile as File,
              'qualitativeFile'
            )
          );
        }

        if (hasQuantitativeFile) {
          filePromises.push(
            this.readFileAsync(
              this.editedItem.quantitativeFile as File,
              'quantitativeFile'
            )
          );
        }

        const fileContents = (await Promise.all(filePromises)) as Array<{
          fileName: string;
          base64Data: string;
        }>;

        const fileData: FileData = fileContents.reduce(
          (acc, { fileName, base64Data }) => {
            acc[fileName] = base64Data;
            return acc;
          },
          {} as FileData
        );

        const response = await trpcClient.reportTemplate.create.mutate({
          label: this.editedItem.label,
          year: parseInt(this.editedItem.year),
          qualitativeQuestions: fileData.qualitativeFile,
          quantitativeQuestions: fileData.quantitativeFile,
        });

        if (response.success) {
          await this.setItems();
          this.close();
          this.$root.$emit(
            SnackbarAnzeigen.eventName,
            new SnackbarAnzeigen(
              'success',
              `Die Berichtsvorlage wurde erfolgreich hinzugefügt.`
            )
          );
        }

        if (response.success === false) {
          this.errorsQualitativeCSV = response.errorsQualitative || [];
          this.errorsQuantitativeCSV = response.errorsQuantitative || [];
          this.close();
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.close();
        this.error = getErrorMessage(error);
      }
    },

    async onUpdate() {
      try {
        this.isLoading = true;

        const hasQualitativeFile = !!this.editedItem.qualitativeFile;
        const hasQuantitativeFile = !!this.editedItem.quantitativeFile;

        const filePromises = [];

        if (hasQualitativeFile) {
          filePromises.push(
            this.readFileAsync(
              this.editedItem.qualitativeFile as File,
              'qualitativeFile'
            )
          );
        }

        if (hasQuantitativeFile) {
          filePromises.push(
            this.readFileAsync(
              this.editedItem.quantitativeFile as File,
              'quantitativeFile'
            )
          );
        }

        const fileContents = (await Promise.all(filePromises)) as Array<{
          fileName: string;
          base64Data: string;
        }>;

        const fileData: FileData = fileContents.reduce(
          (acc, { fileName, base64Data }) => {
            acc[fileName] = base64Data;
            return acc;
          },
          {} as FileData
        );

        const response = await trpcClient.reportTemplate.updateById.mutate({
          id: this.editedItem.id,
          label: this.editedItem.label,
          year: parseInt(this.editedItem.year),
          isEnabled: this.editedItem.isEnabled,
          qualitativeQuestions: fileData.qualitativeFile,
          quantitativeQuestions: fileData.quantitativeFile,
        });

        if (response.success) {
          await this.setItems();
          this.close();
          this.$root.$emit(
            SnackbarAnzeigen.eventName,
            new SnackbarAnzeigen(
              'success',
              `Die Berichtsvorlage wurde erfolgreich aktualisiert.`
            )
          );
        }

        if (response.success === false) {
          this.errorsQualitativeCSV = response.errorsQualitative || [];
          this.errorsQuantitativeCSV = response.errorsQuantitative || [];
          this.close();
        }

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.close();
        this.error = getErrorMessage(error);
      }
    },

    readFileAsync(file: File, fileName: string) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          if (fileReader.result) {
            const base64Data = (fileReader.result as string).split(',')[1];
            resolve({ fileName, base64Data });
          } else {
            reject(new Error(`Failed to read ${fileName}.`));
          }
        };
        fileReader.onerror = () => {
          reject(new Error(`Error occurred while reading ${fileName}.`));
        };
        fileReader.readAsDataURL(file);
      });
    },
  },
});
