var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{attrs:{"value":_vm.value,"multiple":_vm.auswertung,"flat":_vm.auswertung,"readonly":_vm.auswertung}},_vm._l((_vm.groups),function(group,i){return _c('v-expansion-panel',{key:group.id,staticClass:"grey lighten-5 quantitative-expansion-panel",class:{
      'quantitative-expansion-panel--advanced-table': group.isAdvancedTable,
      'on-print-break-after':
        !_vm.noPageBreakAfterLastPanel && i !== _vm.groups.length - 1,
    },attrs:{"readonly":_vm.auswertung}},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._l(([
            { component: _vm.CsrdDialog, berichtsstandard: group.CSRD },
            { component: _vm.DnkDialog, berichtsstandard: group.DNK },
            { component: _vm.GriDialog, berichtsstandard: group.GRI },
            { component: _vm.PaiDialog, berichtsstandard: group.PAI },
          ].filter((x) => x.berichtsstandard)),function(berichtsstandard){return _c(berichtsstandard.component,{key:`${group.id}:${berichtsstandard.berichtsstandard}`,tag:"component",attrs:{"berichtsstandard":berichtsstandard.berichtsstandard,"auswertung":_vm.auswertung}})}),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" $expand ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex align-center"},[(group.icon)?_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v(" "+_vm._s(group.icon)+" ")]):_c('v-icon',{attrs:{"color":"red","left":""}},[_vm._v(" mdi-alien ")]),_c('h5',{staticClass:"text-h5"},[_vm._v(_vm._s(group.name))])],1)]),_c('v-expansion-panel-content',[_c('v-simple-table',{staticClass:"mb-4"},[(group.isAdvancedTable)?_c('SevenColumnHeader'):_c('TwoColumnHeader',{attrs:{"group-id":group.id,"has-narrow-question-column":_vm.hasNarrowQuestionColumn(group)}}),_c('tbody',_vm._l((group.elements),function(element){return _c(_vm.getComponent(element.type),_vm._b({key:element.id,tag:"component",attrs:{"responses":_vm.responses,"responses-previous-year":_vm.responsesPreviousYear,"advanced-table":group.isAdvancedTable,"report-locked":_vm.reportLocked,"auswertung":_vm.auswertung},on:{"new-responses":function($event){return _vm.$emit('new-responses', $event)}}},'component',element,false))}),1)],1),_c('KommentarFeld',{attrs:{"value":group.comments[0]?.comment,"report-locked":_vm.reportLocked,"auswertung":_vm.auswertung,"responder":_vm.responder(group),"responded-at":_vm.respondedAt(group)},on:{"change":function($event){return _vm.updateComment($event, group.id)}}})],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }