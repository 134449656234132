import Vue from 'vue';

import type { RouteConfig } from 'vue-router';
import VueRouter from 'vue-router';
import { getUser } from '@/components/login/login';
import LoginView from '@/views/LoginView.vue';
import PasswordForgottenView from '@/views/PasswordForgottenView.vue';
import PasswordResetView from '@/views/PasswordResetView.vue';
import VerificationView from '@/views/VerificationView.vue';
import QualitativAuswertung from '@/views/fragebogen/qualitativ/AuswertungView.vue';
import QualitativTabs from '@/views/fragebogen/qualitativ/QualitativTabsView.vue';
import QuantitativAuswertung from '@/views/fragebogen/quantitativ/AuswertungView.vue';
import QuantitativGradzielAuswertungView from '@/views/fragebogen/quantitativ/GradzielAuswertungView.vue';
import QuantitativTabs from '@/views/fragebogen/quantitativ/QuantitativTabsView.vue';
import EntryView from '@/views/home/EntryView.vue';
import FurtherInfoView from '@/views/home/FurtherInfoView.vue';
import FAQsView from '@/views/home/FAQsView.vue';

import OrganizationsListView from '@/views/organizations/OrganizationsListView.vue';
import ParentOrganizationView from '@/views/organizations/ParentOrganizationView.vue';
import SubOrganizationView from '@/views/organizations/SubOrganizationView.vue';

import {
  co2Reports,
  contact,
  editorJs,
  faqs,
  homeEntry,
  homeFurtherInfo,
  organizationList,
  overviewVrEsgRisiko,
  parentOrganizationAsOwner,
  qualitativAuswertung,
  quantitativAuswertung,
  quantitativAuswertungUpload,
  quantitativGradzielAuswertung,
  reportTemplateList,
  subOrganizationAsOwner,
} from '@/components/navigation/navigation';
import ColorsView from '@/views/ColorsView.vue';
import EditorJsView from '@/views/EditorJsView.vue';
import CO2ReportListView from '@/views/co2report/CO2ReportListView.vue';
import ReportTemplateListView from '@/views/report-template/ReportTemplateListView.vue';
import ReportTemplateView from '@/views/report-template/ReportTemplateView.vue';
import OrganizationReportView from '@/views/organization-report/OrganizationReportView.vue';

import VrEsgRisikoScoreOverview from '@/views/vrEsgRisikoScore/VrEsgRisikoScoreOverview.vue';
import QuantitativAuswertungUpload from '@/views/fragebogen/quantitativ/QuantitativAuswertungUpload.vue';
import ContactView from '@/views/support/ContactView.vue';

Vue.use(VueRouter);

const homeEntryRoute: RouteConfig = {
  path: homeEntry.pathInDerNavigation,
  component: EntryView,
};

const homeFurtherInfoRoute: RouteConfig = {
  path: homeFurtherInfo.pathInDerNavigation,
  component: FurtherInfoView,
};

const homeFaqsRoute: RouteConfig = {
  path: faqs.pathInDerNavigation,
  component: FAQsView,
};



const home: RouteConfig = {
  path: '/',
  redirect: homeEntryRoute,
};

export const vrEsgRisikoOverview: RouteConfig = {
  path: overviewVrEsgRisiko.pathInDerNavigation,
  component: VrEsgRisikoScoreOverview,
};

const login: RouteConfig = {
  path: '/login',
  component: LoginView,
  meta: { isUnauthPath: true },
};

const verification: RouteConfig = {
  path: '/verification',
  component: VerificationView,
  meta: { isUnauthPath: true },
};

const passwordForgotten: RouteConfig = {
  path: '/password-forgotten',
  component: PasswordForgottenView,
  meta: { isUnauthPath: true },
};

const passwordReset: RouteConfig = {
  path: '/password-reset',
  component: PasswordResetView,
  meta: { isUnauthPath: true },
};

const qualitativAuswertungRoute: RouteConfig = {
  path: qualitativAuswertung.pathInDerNavigation,
  component: QualitativAuswertung,
};

const tabbedQualitativQuestions: RouteConfig = {
  path: '/fragen/qualitativ/:category',
  component: QualitativTabs,
};

const tabbedQuantitativQuestions: RouteConfig = {
  path: '/fragen/quantitativ/:category',
  component: QuantitativTabs,
};

export const quantitativAuswertungRoute: RouteConfig = {
  path: quantitativAuswertung.pathInDerNavigation,
  component: QuantitativAuswertung,
};

export const quantitativAuswertungUploadRoute: RouteConfig = {
  path: quantitativAuswertungUpload.pathInDerNavigation,
  component: QuantitativAuswertungUpload,
};

const quantitativGradzielAuswertungRoute: RouteConfig = {
  path: quantitativGradzielAuswertung.pathInDerNavigation,
  component: QuantitativGradzielAuswertungView,
};

// Organization and user routes
const organizationListRoute: RouteConfig = {
  path: organizationList.pathInDerNavigation,
  component: OrganizationsListView,
  meta: { isAdminPath: true },
};

const parentOrganization: RouteConfig = {
  path: '/organizations/:id',
  component: ParentOrganizationView,
  meta: { isAdminPath: true },
};

const parentOrganizationAsOwnerRoute: RouteConfig = {
  path: parentOrganizationAsOwner.pathInDerNavigation,
  component: ParentOrganizationView,
  meta: { isParentOrgOwnerPath: true },
};

const subOrganization: RouteConfig = {
  path: '/organizations/:parentId/sub/:id',
  component: SubOrganizationView,
  meta: {
    isAdminPath: true,
    isParentOrgOwnerPath: true,
  },
};

const subOrganizationAsOwnerRoute: RouteConfig = {
  path: subOrganizationAsOwner.pathInDerNavigation,
  component: SubOrganizationView,
  meta: { isSubOrgOwnerPath: true },
};

// Report template routes
export const reportTemplateListRoute: RouteConfig & {
  nameInDerNavigation: string;
} = {
  name: 'report-template',
  path: reportTemplateList.pathInDerNavigation,
  component: ReportTemplateListView,
  nameInDerNavigation: 'Berichte',
  meta: { isAdminPath: true },
};

const reportTemplateRoute: RouteConfig = {
  path: '/report-template/:id',
  component: ReportTemplateView,
  meta: { isAdminPath: true },
};

// Report template routes
export const colorRoute: RouteConfig = {
  path: '/farben',
  component: ColorsView,
};

// Organization Report route
const organizationReportRoute: RouteConfig = {
  path: '/organizations/:id/organization-reports/:organizationReportId',
  component: OrganizationReportView,
  meta: {
    isAdminPath: true,
    isParentOrgOwnerPath: true,
    isSubOrgOwnerPath: true,
  },
};

// Organization Report route
const subOrganizationReportRoute: RouteConfig = {
  path: '/organizations/:parentId/sub/:id/organization-reports/:organizationReportId',
  component: OrganizationReportView,
  meta: {
    isAdminPath: true,
    isParentOrgOwnerPath: true,
  },
};

export const editorJsRoute: RouteConfig = {
  path: editorJs.pathInDerNavigation,
  component: EditorJsView,
  meta: { isAdminPath: true },
};

export const co2ReportsRoute: RouteConfig = {
  path: co2Reports.pathInDerNavigation,
  component: CO2ReportListView,
  meta: { isAdminPath: true },
};

export const contactRoute: RouteConfig = {
  path: contact.pathInDerNavigation,
  component: ContactView,
};




const routes: RouteConfig[] = [
  login,
  verification,
  passwordForgotten,
  passwordReset,
  home,
  homeEntryRoute,
  homeFurtherInfoRoute,
  homeFaqsRoute,
  qualitativAuswertungRoute,
  tabbedQualitativQuestions,
  tabbedQuantitativQuestions,
  quantitativAuswertungRoute,
  quantitativAuswertungUploadRoute,
  quantitativGradzielAuswertungRoute,
  organizationListRoute,
  parentOrganization,
  parentOrganizationAsOwnerRoute,
  subOrganization,
  subOrganizationAsOwnerRoute,
  reportTemplateListRoute,
  reportTemplateRoute,
  organizationReportRoute,
  subOrganizationReportRoute,
  colorRoute,
  editorJsRoute,
  co2ReportsRoute,
  reportTemplateListRoute,
  vrEsgRisikoOverview,
  contactRoute,
  {
    name: 'CatchAll',
    path: '/:pathMatch(.*)*',
    redirect: home,
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {
  const user = getUser();
  window.scrollTo(0, 0);
  const userIsSet = !!user;
  const userIsNotSet = !user;
  const isUnauthPath = !!to?.meta?.isUnauthPath;
  const isAdminPath = !!to?.meta?.isAdminPath;
  const isParentOrgOwnerPath = !!to?.meta?.isParentOrgOwnerPath;
  const isSubOrgOwnerPath = !!to?.meta?.isSubOrgOwnerPath;
  if (userIsNotSet && isUnauthPath) {
    next();
    return;
  }
  if (userIsNotSet && !isUnauthPath) {
    next(login);
    return;
  }
  if (userIsSet) {
    const isAdminUser = user.role === 'ADMIN';
    const isParentOrgOwner = user.extendedRole === 'PARENT_ORG_OWNER';
    const isSubOrgOwner = user.extendedRole === 'SUB_ORG_OWNER';
    const isMember = user.extendedRole === 'MEMBER';

    if (isAdminUser && (isUnauthPath || !isAdminPath)) {
      next(organizationListRoute);
      return;
    }
    if (
      isParentOrgOwner &&
      (isUnauthPath || isAdminPath || isSubOrgOwnerPath) &&
      !isParentOrgOwnerPath
    ) {
      next(home);
      return;
    }
    if (
      isSubOrgOwner &&
      (isUnauthPath || isAdminPath || isParentOrgOwnerPath) &&
      !isSubOrgOwnerPath
    ) {
      next(home);
      return;
    }
    if (
      isMember &&
      (isUnauthPath || isAdminPath || isParentOrgOwnerPath || isSubOrgOwnerPath)
    ) {
      next(home);
      return;
    }
    next();
  }
});

export default router;
