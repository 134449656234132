
import type { PropType } from 'vue';
import Vue from 'vue';
import { GraphTypes } from '@prisma/client';
import type {
  QuantitativeElementBackend,
  QuantitativeResponseBackend,
} from '@/utils/backendTypes';
import StackedBarChart from '@/components/library/StackedBarChart.vue';
import DonutChart from '@/components/library/DonutChart.vue';
import QuestionColumn from '@/components/fragebogen/quantitativ/tabellenDarstellung/QuestionColumn.vue';


export default Vue.extend({
  components: {
    StackedBarChart,
    DonutChart,
    QuestionColumn,
  },
  props: {
    graph: {
      type: Object as PropType<QuantitativeElementBackend['graph']>,
      required: true,
    },
    advancedTable: {
      type: Boolean,
      required: true,
    },
    responses: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    responsesPreviousYear: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerGraph: this.graph!,
      GraphTypes,
    };
  },
  computed: {
    dataPoints(): Array<DataPoint> {
      return this.innerGraph.sourceQuestions.map((x) => {
        return new DataPoint(
          this.responses[x.question.frontendId]?.valueNumeric || 0,
          x.label
        );
      });
    },
    dataPointsVorjahr(): Array<DataPoint> {
      return this.innerGraph.sourceQuestions.map((x) => {
        return new DataPoint(
          this.responsesPreviousYear[x.question.frontendId]?.valueNumeric || 0,
          x.label
        );
      });
    },
  },
});

export class DataPoint {
  value: number;
  label: string;
  constructor(value: number, label: string) {
    this.value = value;
    this.label = label;
  }
}
