
import type { ApexOptions } from 'apexcharts';
import mixins from 'vue-typed-mixins';

import { branchenVergleichColor, ownScoreColor } from '@/ts/Colors';
import { graphWithPrintView } from '@/mixins/graphWithPrintView';

const beschriftungImUhrzeigersinn = [
  'Gesamt',
  'Environment',
  'Social',
  'Governance',
];

export default mixins(graphWithPrintView).extend({
  props: {
    gesamtNote: {
      type: Number,
      required: true,
    },
    environmentNote: {
      type: Number,
      required: true,
    },
    socialNote: {
      type: Number,
      required: true,
    },
    governanceNote: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      labelsForYAxis: {
        5: 'Hervorragend',
        4: 'Gut',
        3: 'Ausreichend',
        2: 'Grundlegend',
        1: 'Unzureichend',
      } as { [x: number]: string },
    };
  },
  computed: {
    originalWerte(): { score: number; avg: number }[] {
      return [
        { score: this.gesamtNote, avg: 3.7 },
        { score: this.environmentNote, avg: 4.2 },
        { score: this.socialNote, avg: 2.5 },
        { score: this.governanceNote, avg: 3.5 },
      ];
    },
    series(): any {
      return [
        {
          name: 'Eigener Score',
          data: this.originalWerte.map((wert) => {
            return {
              x: 'name is not relevant',
              y: wert.score.toFixed(2),
            };
          }),
        },
        {
          name: 'Branchenvergleich',
          data: this.originalWerte.map((wert) => {
            return {
              x: 'name is not relevant',
              y: wert.avg,
            };
          }),
        },
      ];
    },
    chartOptions(): ApexOptions {
      const labelsForYAxis = this.labelsForYAxis;
      return {
        chart: {
          animations: {
            enabled: !this.isPrintView,
          },
        },
        xaxis: {
          categories: beschriftungImUhrzeigersinn,
          labels: {
            trim: true,
            style: {
              fontFamily: 'unset', // reset font
              fontWeight: 'bold',
            },
          },
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: 5,
          labels: {
            style: {
              fontFamily: 'unset', // reset font
              fontWeight: 'bold',
            },
            formatter(val, opts) {
              if (
                isInitialRequestForMaxvalue(opts) ||
                isRequestForAxislabel(opts)
              ) {
                return labelsForYAxis[val];
              }
              return val?.toString();
            },
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        colors: [
          ownScoreColor(this.$vuetify.theme.currentTheme.primary! as string),
          branchenVergleichColor(
            this.$vuetify.theme.currentTheme.primary! as string
          ),
        ],
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            foreColor: 'grey',
          },
          style: {
            fontFamily: 'unset',
            fontWeight: 'bold',
            colors: ['white'],
          },
          offsetY: -20,
        },
        legend: {
          show: true,
          horizontalAlign: 'left',
          fontWeight: 'bold',
          labels: {
            useSeriesColors: false,
            colors: 'grey',
          },
        },
      };

      function isRequestForAxislabel(opts: any): boolean {
        return typeof opts === 'number';
      }

      function isInitialRequestForMaxvalue(opts: any) {
        return opts?.dataPointIndex === -1;
      }
    },
  },
});
