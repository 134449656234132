
import type { PropType } from 'vue';
import Vue from 'vue';

import InfoDialog from '@/components/library/InfoDialog.vue';
import type { QuantitativeElementBackend } from '@/utils/backendTypes';

export default Vue.extend({
  components: {
    InfoDialog,
  },
  props: {
    subheading: {
      type: Object as PropType<QuantitativeElementBackend['subheading']>,
      required: true,
    },
    advancedTable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      innerSubheading: this.subheading!,
    };
  },
});
