
import Vue from 'vue';
import { DialogEvents } from '@/ts/events/Dialog';
import DialogKarte from '@/components/library/DialogKarte.vue';

export default Vue.extend({
  components: {
    DialogKarte,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      innerShowDialog: this.showDialog,
      dialogSchließenEventName: DialogEvents.closeDialog,
      dialogokButtonPressed: DialogEvents.okButtonPressed,
    };
  },
  watch: {
    showDialog() {
      this.innerShowDialog = this.showDialog;
    },
    innerShowDialog() {
      this.$emit(DialogEvents.showDialog, this.innerShowDialog);
    },
  },
});
