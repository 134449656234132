import { BoldText } from '@/components/fragebogen/fragetypen/zusammengesetzterText/BoldText.vue';
import { DoubleNewLine } from '@/components/fragebogen/fragetypen/zusammengesetzterText/DoubleNewLine.vue';
import { EinfacherLink } from '@/components/fragebogen/fragetypen/zusammengesetzterText/EinfacherLink.vue';
import { EinfacherText } from '@/components/fragebogen/fragetypen/zusammengesetzterText/EinfacherText.vue';
import { NewLine } from '@/components/fragebogen/fragetypen/zusammengesetzterText/NewLine.vue';
import { SimpleTable } from '@/components/fragebogen/fragetypen/zusammengesetzterText/SimpleTable.vue';
import KartenInhalt from '@/components/homepage/KartenInhalt';
import { allgemeinOrganisation } from '@/components/navigation/navigation';
import router from '@/router';
import { ScrollToId } from '@/ts/ScrollTo';

const EinführungKarte = {
  kartenTitel: 'Einführung',
  ersteKarte: true,
  buttonText: 'Struktur',
  wirdAngezeigt: () => {
    return true;
  },
  clickedWeiter: () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    StrukturKarte.anzeigen = true;
    localStorage.StrukturKarteAnzeigen = 'true';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    new ScrollToId(StrukturKarte.karteId).scrollToId();
  },
  htmlKartenBody: new KartenInhalt([
    new EinfacherText(
      'Nachhaltigkeit ist kein Trend oder eine Modeerscheinung, sondern elementarer Bestandteil der zukunftsfähigen Ausrichtung eines Unternehmens. Jedoch ergeben sich häufig Unsicherheiten aufgrund der Vielzahl an regulatorischen Anforderungen und Rahmenwerken, fehlender Priorisierung, fehlender Verantwortlichkeiten oder schlechter Datenverfügbarkeit. Insbesondere kleinen und mittelständischen Unternehmen (KMU) fehlen auch oft die nötigen Ressourcen, um sich dem komplexen Thema umfassend zu widmen. Das gilt ganz besonders für diejenigen Unternehmen, die zum ersten Mal eine Nachhaltigkeitsstrategie entwickeln möchten, eine Wesentlichkeitsanalyse durchführen oder mit der Nachhaltigkeitsberichterstattung beginnen.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Das EB-Sustainability-Scoring bietet diesen Unternehmen einen einfachen Einstieg in das breite und komplexe Themenfeld der Nachhaltigkeit. Durch das strukturierte Vorgehen erhalten Sie zum einen Überblick über die regulatorisch relevanten Nachhaltigkeitsfaktoren und zum anderen eine Auswertung Ihrer aktuellen Nachhaltigkeitsleistungen entlang dieser Kategorien. Darüber hinaus wird Ihnen ein Branchenvergleich als Orientierung zur Verfügung gestellt.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Auch wenn Sie keiner direkten Berichtspflicht aus den Anforderungen der CSRD unterliegen, können viele Unternehmen auch indirekt von Berichtspflichten betroffen sein, da die eigenen Stakeholder wie Dienstleister, Kund:innen und insb. Finanzdienstleister u.a. aufgrund eigener Prüf- und Berichtspflichten vermehrt Engagement und Transparenz in Sachen Nachhaltigkeit fordern und bewerten müssen.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Daher lohnt es in jedem Fall, sich mit dem Thema auseinander zu setzen und sich auf den Weg zu machen!'
    ),
  ]),
  anzeigen: false,
  cardWithBackground: true,
  karteId: 'einführung',
};

const StrukturKarte = {
  kartenTitel: 'Die Struktur des EB-Sustainability-Scorings',
  ersteKarte: false,
  buttonText: 'Wesentlichkeitsanalyse',
  wirdAngezeigt: () => {
    return JSON.parse(localStorage.StrukturKarteAnzeigen || false);
  },
  clickedWeiter: () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    WesentlichkeitsanalyseKarte.anzeigen = true;
    localStorage.WesentlichkeitsanalyseKarteAnzeigen = 'true';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    new ScrollToId(WesentlichkeitsanalyseKarte.karteId).scrollToId();
  },
  htmlKartenBody: new KartenInhalt([
    new EinfacherText(
      'Sowohl die Durchführung einer Wesentlichkeitsanalyse und insbesondere die erstmalige Datenerhebung werden als sehr aufwendig und zeitintensiv eingeschätzt. Laut dem Deutschen Nachhaltigkeitskodex (DNK) ist zum Beispiel für die Erstellung der ersten DNK-Erklärung mit einem Aufwand von ca. 25 Arbeitstagen zu rechnen. Daher ist das EB-Sustainability-Scoring in zwei Teile aufgeteilt: In einen qualitativen Teil mit ca. 100 Fragen zu den Bereichen Allgemein, Environment (Umwelt), Social (Soziales) und Governance (Unternehmensführung), der mit geringem Zeitaufwand beantwortet werden kann, und in einen quantitativen Teil zur sukzessiven Erhebung der wesentlichen nachhaltigkeitsrelevanten Daten und zum Aufbau eines Datenhaushaltes und einer Datenhistorie.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Die Struktur und die Fragen des qualitativen Teils des EB-Sustainability-Scorings basieren auf den Berichtsstandards (den European Sustainability Reporting Standards (ESRS)), die die Anforderungen der CSRD konkretisieren. Bei der Vielzahl an Berichtsstandards und Rahmenwerken kann schnell die Orientierung verloren gehen und hierdurch der Einstieg in die Nachhaltigkeitsberichtserstattung deutlich erschwert werden. Allein die aktuelle Version der ESRS-Anforderungen umfasst ca. 282 Seiten.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Diese ESRS-Berichtsstandards haben den Anspruch, die Grundlagen für eine einheitliche Nachhaltigkeitsberichterstattung innerhalb der EU zu legen. Dabei wurden bestehende Berichtsstandards- und Rahmenwerke wie die der Global Reporting Initiative (GRI), der Task Force on Climate-related Financial Disclosures (TCFD) sowie die Indikatoren mit den wesentlichen negativen Auswirkungen auf Nachhaltigkeitsfaktoren (Principle Adverse Impact Indikators (PAI)) berücksichtigt.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Um diesen Anforderungen gerecht zu werden, folgen die ESRS Berichtsstandards der Grundlogik der sogenannten doppelten Wesentlichkeit (double Materiality). Dies bedeutet, dass Sachverhalte dann als wesentlich einzustufen sind, wenn sie'
    ),
    new NewLine(),
    new EinfacherText(
      '- entweder wesentliche finanzielle Auswirkungen auf die Organisation haben (financial materiality) oder'
    ),
    new NewLine(),
    new EinfacherText(
      '- oder die Geschäftstätigkeit wesentliche Auswirkungen auf die Bereiche Environment, Social und Governance hat (impact materiality).'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Die Wesentlichkeit ist also aus Sicht der Einflüsse von außen auf die Organisation (Outside-in Perspektive) sowie hinsichtlich der Wirkung der Geschäftstätigkeit auf das Umfeld (Inside-Out Perspektive) zu betrachten.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Die einzelnen Bereiche des Scorings (Environment, Social und Governance) des EB-Sustainability-Scorings sind den ESRS-Berichtsstandards folgend wieder in Teilbereiche gegliedert. Im Bereich Environment werden Klimawandel, Umweltverschmutzung, Wasser- und Meeresressourcen, Biologische Vielfalt und Ökosysteme sowie Ressourcennutzung und Kreislaufwirtschaft adressiert. Dies ermöglicht eine zielgerichtete Abfrage der relevanten Aspekte zur Nachhaltigkeitsleistung des Unternehmens und damit eine unmittelbare Identifikation der Lücken mit Blick auf zukünftige Berichtspflichten.'
    ),
  ]),
  anzeigen: false,
  karteId: 'struktur',
};
const WesentlichkeitsanalyseKarte = {
  kartenTitel: 'Wesentlichkeitsanalyse',
  ersteKarte: false,
  buttonText: 'Hintergrundwissen',
  wirdAngezeigt: () => {
    return JSON.parse(localStorage.WesentlichkeitsanalyseKarteAnzeigen || false);
  },
  clickedWeiter: () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    HintergrundwissenKarte.anzeigen = true;
    localStorage.HintergrundwissenKarteAnzeigen = 'true';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    new ScrollToId(HintergrundwissenKarte.karteId).scrollToId();
  },
  htmlKartenBody: new KartenInhalt([
    new BoldText(new EinfacherText('Stakeholder')),
    new DoubleNewLine(),
    new EinfacherText('Im Rahmen der Wesentlichkeitsanalyse sind insbesondere die Anforderungen der Stakeholdergruppen zu berücksichtigen, denen im Rahmen der Stakeholder-analyse die höchste Priorität zugewiesen wird.'),
    new DoubleNewLine(),
    new EinfacherText('Die CSRD sieht vor, dass bei der Bestimmung der Wesentlichkeit der Themen die Anforderungen der Stakeholder der Unternehmen berücksichtigt werden müssen. Dabei werden zwei – nicht überschneidungsfreie – Gruppen von Stakeholdern unterschieden:'),
    new NewLine(),
    new EinfacherText('1. Zu den „betroffenen Stakeholdern“ zählen Einzelpersonen oder Gruppen entlang der gesamten Wertschöpfungskette des Unternehmens, deren Interessen durch die Aktivitäten des Unternehmens beeinflusst werden oder werden können, wobei dieser Einfluss positiv oder negativ sein kann. Häufig können beispielsweise Mitarbeitende und Kunden als Stakeholder im unmittelbaren Umfeld des Unternehmens identifiziert werden.'),
    new NewLine(),
    new EinfacherText('2. Zu den „Nutzern von Nachhaltigkeitsberichten“ gehören Einzelpersonen und Gruppen, die sich auf Basis der Finanz- und Nachhaltigkeitsberichterstattung ein umfassendes Bild der entsprechenden Leistungen und Auswirkungen des Unternehmens machen wollen, z. B. Investoren, Geschäftspartner und zivilgesellschaftliche Organisationen (NGOs).'),
    new DoubleNewLine(),
    new BoldText(new EinfacherText('Long- u. Shortlisting')),
    new DoubleNewLine(),
    new EinfacherText('Der Gesetzgeber hat einen Themenkatalog (ESRS 1 Anlage A AR 16) aufgestellt, in dem er definiert hat, welche Nachhaltigkeitsaspekte aus seiner Sicht für Unternehmen wesentlich sein können und daher durch jedes berichtspflichtige Unternehmen geprüft werden müssen. Unternehmen können auch über diese Liste hinaus Aspekte als wesentlich identifizieren.'),
    new NewLine(),
    new EinfacherText('Da der sektoragnostische Themenkatalog des Gesetzgebers grundsätzlich für alle berichtspflichtigen Unternehmen gilt und nicht nach Branchen bzw. Geschäftstätigkeit der Unternehmen unterscheidet, geht es in einem ersten Schritt darum, zu prüfen, welche der aufgeführten Nachhaltigkeitsaspekte vor dem Hintergrund ihrer Geschäftstätigkeit und unter Beachtung der doppelten Wesentlichkeit für das Unternehmen grundsätzlich wesentlich sein können. Ziel dieser ersten Analyse ist es also, die Nachhaltigkeitsaspekte herauszufiltern, die nachvollziehbar für das Unternehmen nicht wesentlich sind. Auf der so entstehenden Shortlist verbleiben dann die Nachhaltigkeitsaspekte, die für das Unternehmen wesentlich sein können.'),
    new DoubleNewLine(),
    new BoldText(new EinfacherText('Bewertung')),
    new DoubleNewLine(),
    new EinfacherText('Bei den Bewertungen der Nachhaltigkeitsaspekte des eigenen Unternehmens werden die positiven und die negativen Auswirkungen genau wie die finanziellen Risiken und Chancen berücksichtigt. Nach Darstellung des Gesetzgebers kann eine nachhaltigkeitsbezogene Auswirkung von Anfang an finanziell wesentlich sein oder finanziell wesentlich werden, wenn nach vernünftigem Ermessen davon auszugehen ist, dass sie die Finanzlage, die finanzielle Leistungsfähigkeit, die Cashflows, den Zugang zu Finanzmitteln oder die Kapitalkosten des Unternehmens kurz-, mittel- oder langfristig beeinflusst. Die Wesentlichkeit der Risiken und Chancen wird hier auf Grundlage einer Kombination aus der Eintrittswahrscheinlichkeit und dem potenziellen Ausmaß der finanziellen Auswirkungen bewertet.'),
    new DoubleNewLine(),
    new SimpleTable(['', 'Ausmaß', 'Umfang', 'Unabänderlichkeit', 'Wahrscheinlichkeit'], [
      ['Tatsächliche negative Auswirkungen', '✓', '✓', '✓', ''],
      ['Potenziell negative Auswirkungen', '✓', '✓', '✓', '✓'],
      ['Tatsächliche positive Auswirkung', '✓', '✓', '', ''],
      ['Potenziell positive Auswirkung', '✓', '✓', '', '✓'],
      ['Finanzielle Risiken und Chancen', '✓', '', '', '✓'],
    ]),
  ]),
  anzeigen: false,
  cardWithBackground: true,
  karteId: 'wesentlichkeitsanalyse',
};

const HintergrundwissenKarte = {
  kartenTitel: 'Hintergrundwissen',
  ersteKarte: false,
  buttonText: 'Berichtspflicht',
  wirdAngezeigt: () => {
    return JSON.parse(localStorage.HintergrundwissenKarteAnzeigen || false);
  },
  clickedWeiter: () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    BerichtspflichtKarte.anzeigen = true;
    localStorage.BerichtspflichtKarteAnzeigen = 'true';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    new ScrollToId(BerichtspflichtKarte.karteId).scrollToId();
  },
  htmlKartenBody: new KartenInhalt([
    new EinfacherText(
      'Die Bewertungslogik wurde mit internen und externen Expert:innen aus dem Bereich Nachhaltigkeit entwickelt. In den aktuellen Diskussionen stehen eindeutig die Themen Klimaschutz und Klimawandel im Fokus. Hier sind die Anforderungen der EU-Taxonomie am weitesten fortgeschritten und es gibt konkrete Anforderungen im Hinblick auf die Mess- und Zielgrößen. Jedoch reduziert sich Nachhaltigkeit nicht allein auf Umweltthemen, sondern die Bereiche Soziales und Governance (Unternehmensführung) sind ebenso von zentraler Bedeutung für einen langfristigen Erfolg der Organisationen. Wir erwarten insbesondere durch die Sozialtaxonomie eine deutliche Konkretisierung der Anforderungen und eine höhere Gewichtung von sozialen Themen im Nachhaltigkeitsdiskurs. Damit einhergehend wird sich auch die Bewertungslogik weiterentwickeln, schärfen und die zugrunde gelegte Erwartungshaltung an die Nachhaltigkeitsleistung der Organisationen erhöhen.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Die Berichtsstandards und regulatorischen Anforderungen entwickeln sich fortlaufend weiter. Der aktuelle Entwurf der CSRD-Berichtsstandards berücksichtigt noch keine sektorspezifischen Anforderungen, und auch Standards mit Fokus auf kleine und mittlere Unternehmen (KMU) fehlen und sind noch in der Entwicklung.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Entsprechend der regulatorischen Anforderungen entwickeln wir das EB-Sustainability-Scoring konsequent weiter und richten es an Ihren Bedürfnissen aus. Dazu benötigen wir Ihr Feedback:'
    ),
    new DoubleNewLine(),
    new EinfacherText('Melden Sie sich dazu gerne per E-Mail unter '),
    new EinfacherLink('esg@eb.de', 'mailto:esg@eb.de'),
  ]),
  anzeigen: false,
  karteId: 'hintergrundwissen',
};

const BerichtspflichtKarte = {
  kartenTitel: 'Berichtspflicht',
  ersteKarte: false,
  buttonText: 'EB-Sustainability-Scoring',
  wirdAngezeigt: () => {
    return JSON.parse(localStorage.BerichtspflichtKarteAnzeigen || false);
  },
  clickedWeiter: () => {
    router.push(allgemeinOrganisation.pathInDerNavigation);
  },
  htmlKartenBody: new KartenInhalt([
    new EinfacherText(
      'Mit der Einführung der CSRD wird der Anwenderkreis an berichtspflichtigen Organisationen in Deutschland deutlich erweitert. Ab dem Geschäftsjahr 2025 sind alle großen Organisation (Rechtsform der Kapitalgesellschaft) im EU-regulierten Markt von der Berichtspflicht erfasst. Als „groß“ gilt eine Organisation, wenn sie zwei der folgenden drei Kriterien erfüllt:'
    ),
    new DoubleNewLine(),
    new EinfacherText('Durchschnittliche Mitarbeiterzahl > 250 (unverändert)'),
    new NewLine(),
    new EinfacherText('Umsatz > 50 Mio. € (ursprünglich 40 Mio. €)'),
    new NewLine(),
    new EinfacherText('Bilanzsumme > 25 Mio. € (ursprünglich 20 Mio. €)'),
    new DoubleNewLine(),
    new EinfacherText(
      'Die Größenkriterien wurden von der Europäischen Kommission aufgrund der hohen Inflation in den Jahre 2021 und 2022 noch einmal nach oben korrigiert. Die angepassten Größenkriterien sollen ab 01.01.2024 gelten (eine rückwirkende Anwendung ab 01.01.2023 ist ebenfalls möglich). Diese Anpassungen sollen bis spätestens 24.12.2024 in nationales Recht überführt werden.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Zuvor galt die Berichtspflicht nur für kapitalmarktorientierte Organisationen mit mehr als 500 Mitarbeiter:innen. Demnach sind auch eingetragene Vereine oder Stiftungen nicht von der Erweiterung der Berichtspflicht betroffen. Dies kann sich jedoch mit der Überführung der CSRD in deutsches Recht ändern und die Anforderungen können ausgeweitet und verschärft werden.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Doch ob berichtspflichtig oder nicht – die Bedeutung des Themas Nachhaltigkeit geht weiter über regulatorische Anforderungen hinaus und ist kein Trend, sondern das „neue Normal“. Kund:innen und Mitarbeiter:innen legen mehr und mehr Wert auf einen seriösen Umgang mit Nachhaltigkeitsaspekten. Auch weitere Stakeholder, wie die breite Öffentlichkeit oder die Medien, beschäftigen sich mehr und mehr mit dem Thema.'
    ),
    new DoubleNewLine(),
    new EinfacherText(
      'Starten Sie jetzt mit dem EB-Sustainability-Scoring und der ersten Standortbestimmung Ihrer Nachhaltigkeitsleistung!'
    ),
  ]),
  anzeigen: false,
  cardWithBackground: true,
  karteId: 'berichtspflicht',
};

export default [
  EinführungKarte,
  StrukturKarte,
  WesentlichkeitsanalyseKarte,
  HintergrundwissenKarte,
  BerichtspflichtKarte,
];
