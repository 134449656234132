
import type { Component, PropType } from 'vue';
import Vue from 'vue';

import type { Textteil } from '@/components/fragebogen/fragetypen/zusammengesetzterText/ZusammengesetzterText.vue';

const defaultExport = Vue.extend({
  props: {
    prop: {
      type: Object as PropType<SimpleTable>,
      required: true,
    },
  },
});
export default defaultExport;

export class SimpleTable implements Textteil {
  tableHeader: string[];
  tableContent: string[][];
  component: Component = defaultExport;

  constructor(tableHeader: string[], tableContent: string[][]) {
    this.tableHeader = tableHeader;
    this.tableContent = tableContent;
  }
}
