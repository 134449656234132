
import type { PropType } from 'vue';
import mixins from 'vue-typed-mixins';
import handleQuantitativeError from '@/mixins/handleError';

import updateResponses from '@/mixins/updateResponses';
import { PlausibilitätDialogParameter } from '@/ts/events/PlausibilitätParameter';
import { trpcClient } from '@/utils/trpc';
import { erlaubteAbweichung } from '@/components/fragebogen/dialog/PlausibiltaetDialog.vue';
import ToolTipResponder from '@/components/fragebogen/quantitativ/ToolTipResponder.vue';

export default mixins(updateResponses, handleQuantitativeError).extend({
  components: {
    ToolTipResponder,
  },
  props: {
    questionId: {
      type: Number,
      required: true,
    },
    value: {
      type: null as unknown as PropType<number | null | undefined>,
      required: true,
    },
    valuePreviousYear: {
      type: null as unknown as PropType<number | null | undefined>,
      required: false,
    },
    unit: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<'text' | 'number'>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reportLocked: {
      type: Boolean,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
    responder: {
      type: String,
      default: undefined,
    },
    respondedAt: {
      type: Date,
      default: undefined,
    },
  },
  methods: {
    createNumericResponse(questionId: number, response: string) {
      trpcClient.response.createQuantitativeNumeric
        .mutate({
          response: response ? +response : null,
          questionId,
        })
        .then(this.emitResponses)
        .catch((e) =>
          this.handleError(e, 'Konnte die Antwort nicht speichern:')
        );
      if (response && this.valuePreviousYear) {
        const thisYearMoreThen50PercentMore =
          this.valuePreviousYear * (1 + erlaubteAbweichung) < +response;
        const thisYearLessThen50PercentLess =
          this.valuePreviousYear * erlaubteAbweichung > +response;
        if (thisYearMoreThen50PercentMore || thisYearLessThen50PercentLess) {
          this.$root.$emit(
            PlausibilitätDialogParameter.eventName,
            new PlausibilitätDialogParameter(+response, this.valuePreviousYear)
          );
        }
      }
    },
    createStringResponse(questionId: number, response: string) {
      trpcClient.response.createQuantitativeString
        .mutate({
          response,
          questionId,
        })
        .then(this.emitResponses)
        .catch((e) =>
          this.handleError(e, 'Konnte die Antwort nicht speichern:')
        );
    },
  },
});
