
import type { ApexOptions } from 'apexcharts';
import Vue from 'vue';
import type { DataPoint } from '@/components/fragebogen/quantitativ/GraphElement.vue';
import { donutChartColors } from '@/ts/Colors';
export default Vue.extend({
  props: {
    datenPunkteBerichtsjahr: {
      type: Array as () => Array<DataPoint>,
      required: true,
    },
    datenPunkteVorjahr: {
      type: Array as () => Array<DataPoint>,
      default: () => [],
    },
    absoluteWerte: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    seriesBerichtsjahr(): any {
      return this.series(this.datenPunkteBerichtsjahr);
    },
    seriesVorjahr(): any {
      return this.series(this.datenPunkteVorjahr);
    },
    chartOptionsBerichtsjahr(): ApexOptions {
      return {
        ...this.chartOptions,
        title: {
          text: 'Berichtsjahr',
          align: 'center',
          style: {
            fontFamily: 'unset', // reset font
          },
        },
        labels: this.datenPunkteBerichtsjahr.map((x) => x.label),
        dataLabels: {
          formatter: this.dataLabelsFormatter(
            this.absoluteWerte,
            this.datenPunkteBerichtsjahr
          ),
          style: {
            colors: ['#fff'],
          },
          background: {
            enabled: true,
            foreColor: '#000',
            padding: 0,
            borderRadius: 0,
            borderWidth: 0,
            borderColor: '#000',
            opacity: 1,
          },
        },
      };
    },
    chartOptionsVorjahr(): ApexOptions {
      return {
        ...this.chartOptions,
        title: {
          text: 'Vorjahr',
          align: 'center',
          style: {
            fontFamily: 'unset', // reset font
          },
        },
        labels: this.datenPunkteVorjahr.map((x) => x.label),
        dataLabels: {
          formatter: this.dataLabelsFormatter(
            this.absoluteWerte,
            this.datenPunkteVorjahr
          ),
        },
      };
    },
    chartOptions(): ApexOptions {
      return {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        colors: donutChartColors(
          this.$vuetify.theme.currentTheme.primary! as string
        ),
        xaxis: {
          categories: [],
          labels: {
            trim: true,
            style: {
              fontFamily: 'unset', // reset font
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
      };
    },
  },
  methods: {
    series(datenPunkte: Array<DataPoint>) {
      return datenPunkte.map((x) => x.value);
    },
    dataLabelsFormatter(absoluteWerte: boolean, datenPunkte: Array<DataPoint>) {
      return (val: string | number | number[], opts?: any): string | number => {
        if (absoluteWerte) {
          return datenPunkte[opts.seriesIndex].value;
        } else {
          return `${Number(val).toFixed(1)}%`;
        }
      };
    },
  },
});

export class DatenPunkt {
  wert: number;
  label: string;
  constructor(wert: number, label: string) {
    this.wert = wert;
    this.label = label;
  }
}
