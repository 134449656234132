
import Vue from 'vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage, isValidEmail } from '@/utils/generic';
import type { VuetifyForm } from '@/ts/types/generic';

export default Vue.extend({
  data: () => {
    return {
      password: '',
      email: '',
      error: null as null | string,
      formRulesEmail: [
        (value: string) => !!value || 'Email ist erforderlich',
        (value: string) =>
          (value && value.length <= 255) ||
          `Email muss weniger als 255 Zeichen lang sein`,
        (value: string) => isValidEmail(value) || 'Es ist keine gültige E-Mail',
      ],
      formRulesPassword: [
        (value: string) => !!value || 'Passwort ist erforderlich',
        (value: string) =>
          (value && value.length <= 255) ||
          `Passwort muss weniger als 255 Zeichen lang sein`,
      ],
      showPassword: false,
      refs: {
        email: 'email',
      },
      loginloading: false,
    };
  },
  computed: {
    passwordFieldType(): string {
      return this.showPassword ? 'text' : 'password';
    },
    passwordFieldIcon(): string {
      return this.showPassword ? 'mdi-eye' : 'mdi-eye-off';
    },
  },
  mounted() {
    (this.$refs[this.refs.email] as HTMLElement).focus();
  },

  methods: {
    async login(): Promise<void> {
      const isValidated = (this.$refs.form as VuetifyForm).validate();

      if (isValidated) {
        try {
          this.loginloading = true;
          const user = await trpcClient.user.login.mutate({
            email: this.email,
            password: this.password,
          });

          localStorage.setItem('user', JSON.stringify(user));
          this.$root.$emit('login');

          const userIsAdmin = user.role === 'ADMIN';
          if (userIsAdmin) {
            this.$router.push('/organizations');
          } else {
            this.$router.push('/');
          }
        } catch (error) {
          this.error = getErrorMessage(error);
        } finally {
          this.loginloading = false;
        }
      }
    },
    toggleVisibilityOfPassword() {
      this.showPassword = !this.showPassword;
    },
  },
});
