export const getErrorMessage = (error: any) => {
  const isInstanceofError = error instanceof Error;
  const isString = typeof error === 'string';

  if (isInstanceofError) {
    try {
      const parsedError = JSON.parse(error.message) as Error[];
      // zod validation errors are returned as an array
      if (Array.isArray(parsedError) && parsedError.length > 0) {
        return parsedError[0].message;
      } else {
        throw new Error('Can not parse the error message');
      }
    } catch (catchError) {
      return error.message;
    }
  }

  if (isString) {
    return error;
  }

  return 'Ein unbekannter Fehler ist aufgetreten';
};

export const isValidEmail = (email: string) => {
  const regExp = /\S+@\S+\.\S{2,}/;
  return regExp.test(email);
};

export function isValidPassword(password: string) {
  // min 10 chars, at least: 1 special + 1 number
  const regExp =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,^()_=+\-"'/])[A-Za-z\d@$!%*#?&.,^()_=+\-"'/]{10,50}$/;

  return regExp.test(password);
}

export function downloadFileInsideFrontend(
  fileContent: string | Uint8Array,
  fileName: string,
  fileType: 'csv' | 'docx',
) {

  let blob: Blob;

  if (fileType === 'csv') {
    const UTF8BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

    blob = new Blob([UTF8BOM, fileContent], {
      type: 'text/csv',
    });
  } else if (fileType === 'docx') {
    blob = new Blob([fileContent], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
  } else {
    throw new Error('Unsupported file type');
  }

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function getFileName(fileName: string, fileType: string) {
  // Get the current date
  const now = new Date();

  // Extract components of the date
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  // Format the filename
  const filename = `${fileName}-${year}-${month}-${day}.${fileType}`;

  return filename;
}