import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      refreshing: false,
      registration: null as unknown as ServiceWorkerRegistration,
      updateExists: false,
    };
  },

  created() {
    document.addEventListener(
      'swUpdated',
      this.updateAvailable as EventListener,
      { once: true }
    );

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) {
        return;
      }
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },

  methods: {
    updateAvailable(event: CustomEvent<ServiceWorkerRegistration>): void {
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshApp(): void {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
});
