
import mixins from 'vue-typed-mixins';
import { file2Base64 } from '@/ts/Files';
import { trpcClient } from '@/utils/trpc';
import AnswerUploadDialog from '@/components/fragebogen/dialog/AnswerUploadDialog.vue';
import { DialogEvents } from '@/ts/events/Dialog';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import handleQuantitativeError from '@/mixins/handleError';
import { downloadFileInsideFrontend } from '@/utils/generic';

export default mixins(handleQuantitativeError).extend({
  components: {
    AnswerUploadDialog,
  },
  data() {
    return {
      answers: null as File | null,
      downloadingSample: false,
      uploadingAnswers: false,
      showAnswerUploadDialog: false,
      closeDialog: DialogEvents.closeDialog,
      okButtonPressed: DialogEvents.okButtonPressed,
      showDialog: DialogEvents.showDialog,
      countAnsweredQuestions: 0,
      countOfQuestions: 0,
    };
  },
  methods: {
    async uploadAnswers() {
      if (this.answers) {
        this.uploadingAnswers = true;
        this.countAnsweredQuestions = 0;
        this.countOfQuestions = 0;
        const file2Base64Content = await file2Base64(this.answers);
        trpcClient.response.bulkUploadQuantitative
          .mutate(file2Base64Content)
          .then((response) => {
            this.countAnsweredQuestions = response.countAnsweredQuestions;
            this.countOfQuestions = response.countOfQuestions;
            const snackbarText = `Antworten erfolgreich hochgeladen. ${this.countAnsweredQuestions} von ${this.countOfQuestions} beantwortet.`;
            this.$root.$emit(
              SnackbarAnzeigen.eventName,
              new SnackbarAnzeigen('success', snackbarText)
            );
          })
          .catch((e) => this.handleError(e, 'Upload fehlgeschlagen:'))
          .finally(() => {
            this.uploadingAnswers = false;
          });
      }
    },
    async downloadSample() {
      this.downloadingSample = true;
      trpcClient.response.downloadSample
        .query()
        .then((downloadedSample) => {
          downloadFileInsideFrontend(
            downloadedSample,
            'beispieldatei_quantitative_fragen.csv',
            'csv'
          );
        })
        .finally(() => {
          this.downloadingSample = false;
        });
    },
  },
});
