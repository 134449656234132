
import mixins from 'vue-typed-mixins';
import handleReportChange from '@/mixins/handleReportChange';
import { reportId } from '@/router/queryparam';
import { DialogEvents } from '@/ts/events/Dialog';

export default mixins(handleReportChange).extend({
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      reportIdFromURL: null as null | number,
      DialogEvents,
    };
  },
  async mounted() {
    const reportIdFromURL = reportId.parameterWertLesenNumber(this.$route);
    this.reportIdFromURL = reportIdFromURL;
  },
});
