export const createPdfExportFileName = (prefix: string) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${prefix}-${year}-${month}-${day}-${hours}-${minutes}-${seconds}.pdf`;
};

export const generateAndDownloadReportPdf = async (pdfUrl: string, fileNamePrefix: string) => fetch(pdfUrl, {
  credentials: 'include'
})
  .then(async res => {
    if (!res.ok) {
      const error = await res.text();
      throw new Error(error);
    }
    const blob = await res.blob();
    const filename = createPdfExportFileName(fileNamePrefix);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  })
  .catch(error => {
    const errMsg = 'Error generating PDF';
    console.error(errMsg, error);
    // Show error message
    throw error;
  });
