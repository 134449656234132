
import Vue from 'vue';

export default Vue.extend({
  methods: {
    click() {
      this.$emit('click');
    },
  },
});
