
import type { PropType } from 'vue';
import Vue from 'vue';

const percentFormat = new Intl.NumberFormat('de-DE', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default Vue.extend({
  props: {
    value: {
      type: null as unknown as PropType<number | null | undefined>,
      required: true,
    },
    previousYear: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedProportion(): String {
      if (Number.isNaN(this.value) || !Number.isFinite(this.value)) {
        return '-';
      }
      return percentFormat.format(this.value || 0);
    },
  },
});
