
import type { PropType } from 'vue';
import mixins from 'vue-typed-mixins';
import type { ApexOptions } from 'apexcharts';

import { graphWithPrintView } from '@/mixins/graphWithPrintView';
import AntwortZusammenfassung from '@/components/auswertung/AntwortZusammenfassung.vue';

import type { CategoryDataBackend } from '@/utils/backendTypes';
import { scoreRadarColors } from '@/ts/Colors';

const useInfoDialog =
  (process.env.VUE_APP_SCORE_RADAR_SHOW_DIALOG as string) === '1';

export default mixins(graphWithPrintView).extend({
  components: {
    AntwortZusammenfassung,
  },
  props: {
    titel: {
      type: String,
      required: false,
      default: 'Environment',
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    categories: {
      type: Array as PropType<CategoryDataBackend[]>,
      required: false,
      default: () => [
        {
          name: 'Klimawandel',
          score: 3.4,
        },
        {
          name: 'Umweltverschmutzung',
          score: 1.1,
        },
        {
          name: 'Wasser- und Meeresressourcen',
          score: 2,
        },
        {
          name: 'Biodiversität und Ökosysteme',
          score: 4.9,
        },
        {
          name: 'Ressourcennutzung und Kreislaufwirtschaft',
          score: 5,
        },
      ],
    },
  },

  data() {
    const useFirstCategoryNameAsItIs = this.categories[0].name;
    const splitOtherCategoryNames = this.categories
      .slice(1)
      .map((category) => category.name.split(' '));
    const result = [useFirstCategoryNameAsItIs, ...splitOtherCategoryNames];
    return {
      beschriftungImUhrzeigersinn: result,
      werteImUhrzeigersinn: this.categories
        .map((category) => category.score)
        .map((score) => Number((Math.round(score * 10) / 10).toFixed(1))),
      showDialog: false,
      useInfoDialog,
    };
  },
  computed: {
    options(): ApexOptions {
      const oneDecimal = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      });
      return {
        chart: {
          id: this.titel,
          animations: {
            enabled: !this.isPrintView,
          },
          toolbar: {
            show: true,
          },
          events: {
            markerClick: (event, chartContext, { dataPointIndex }) => {
              if (dataPointIndex === 0) {
                this.openDialog();
              }
            },
          },
        },
        xaxis: {
          categories: this.beschriftungImUhrzeigersinn,
          labels: {
            trim: true,
            style: {
              colors: new Array(this.beschriftungImUhrzeigersinn.length).fill([
                'black',
              ]),
              fontFamily: 'unset', // reset font
              fontWeight: 'bold',
            },
          },
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: 5,
          labels: {
            style: {
              fontFamily: 'unset', // reset font
              fontWeight: 'bold',
            },
          },
        },
        markers: {
          size: 0,
          colors: ['white'],
          strokeColors: ['white'],
          hover: {
            size: 10,
          },
        },
        plotOptions: {
          radar: {
            polygons: {
              fill: {
                colors: scoreRadarColors(
                  this.$vuetify.theme.currentTheme.primary! as string
                ),
              },
            },
          },
        },
        stroke: {
          colors: ['white'],
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            foreColor: 'black',
            borderColor: 'black',
          },
          style: {
            fontFamily: 'unset', // reset font
            colors: ['white'],
          },
          formatter(val: number, opts) {
            return oneDecimal.format(val);
          },
        },
        fill: {
          colors: ['#9E9E9E'],
          opacity: 0.5,
        },
        series: this.series,
      };
    },
    series(): any {
      return [
        {
          name: '',
          data: this.werteImUhrzeigersinn.map((wert) => {
            return {
              x: 'name is not relevant',
              y: wert,
              // goals: [
              //   {
              //     name: 'Branchenvergleich',
              //     value: noteInProzentUmrechnen(wert + 1),
              //     strokeHeight: 5,
              //     strokeColor: this.$vuetify.theme.currentTheme.primary,
              //   },
              // ],
            };
          }),
        },
      ];
    },
  },
  mounted() {
    // document.querySelectorAll(
    //   "#apexchartsEnvironment .apexcharts-xaxis .apexcharts-datalabel"
    // )[0].onclick = () => {
    //   console.log("mounted and clicked");
    // };
  },
  methods: {
    openDialog(): void {
      this.showDialog = true;
    },
  },
});
