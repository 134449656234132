
import type { PropType } from 'vue';
import Vue from 'vue';

import type {
  QuantitativeQuestionsBackend,
  QuantitativeResponseBackend,
} from '@/utils/backendTypes';
import QuestionColumn from '@/components/fragebogen/quantitativ/tabellenDarstellung/QuestionColumn.vue';
import SimpleQuestionAnswerColumn from '@/components/fragebogen/quantitativ/tabellenDarstellung/SimpleQuestionAnswerColumn.vue';

export default Vue.extend({
  components: {
    QuestionColumn,
    SimpleQuestionAnswerColumn,
  },
  props: {
    question: {
      type: Array as PropType<QuantitativeQuestionsBackend>,
      required: true,
    },
    responses: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    advancedTable: {
      type: Boolean,
      required: true,
    },
    reportLocked: {
      type: Boolean,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const innerQuestion = this.question[0];
    return {
      innerQuestion,
    };
  },
  computed: {
    response(): QuantitativeResponseBackend | null | undefined {
      return this.responses[this.innerQuestion.frontendId];
    },
    value(): string | null | undefined {
      const valueNumeric = this.response?.valueString;
      return valueNumeric;
    },
    responder(): string | undefined {
      const user = this.response?.organizationUser?.user;
      return user ? `${user.firstName} ${user.lastName}` : undefined;
    },
    respondedAt(): Date | undefined {
      return this.response?.createdAt;
    },
  },
});
