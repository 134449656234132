
import mixins from 'vue-typed-mixins';
import NavigationGroup from '@/components/navigation/NavigationGroup.vue';
import { modelMixin } from '@/mixins/model';
import type { NavigationsElternPunkt } from '@/components/navigation/navigation';
import {
  navigationAdmin,
  navigationRegular,
} from '@/components/navigation/navigation';
import { getUser } from '@/components/login/login';
import type { LoggedInUser, UserExtendedRole } from '@/ts/types/generic';
import { useStore } from '@/store';

export default mixins(modelMixin).extend({
  components: {
    NavigationGroup,
  },
  data() {
    return {
      navigation: [] as NavigationsElternPunkt[],
      store: useStore(),
    };
  },
  mounted() {
    this.store.$subscribe(() => {
      this.setNavigation(this.store.selectedOrganizationReport?.isAggregated);
    });

    this.setNavigation(this.store.selectedOrganizationReport?.isAggregated);
  },
  methods: {
    setNavigation(isAggregatedReport: boolean | undefined) {
      const user = getUser() as LoggedInUser;
      if (user.role === 'ADMIN') {
        this.navigation = navigationAdmin;
      } else {
        this.navigation = navigationRegular(
          user.extendedRole as UserExtendedRole,
          isAggregatedReport || false
        );
      }
    },
  },
});
