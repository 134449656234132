
import Vue from 'vue';
import QRCode from 'qrcode';

import { DialogEvents } from '@/ts/events/Dialog';
import DialogKarte from '@/components/library/DialogKarte.vue';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';

export default Vue.extend({
  components: {
    DialogKarte,
  },
  props: {
    inhalt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      dialogSchließenEventName: DialogEvents.closeDialog,
      dataUrl: null as unknown as String,
    };
  },
  mounted() {},
  methods: {
    QRCodeGenerieren() {
      QRCode.toDataURL(
        this.inhalt,
        { errorCorrectionLevel: 'H' },
        (err, url) => {
          let snackbarAnzeigen;
          if (url) {
            snackbarAnzeigen = new SnackbarAnzeigen(
              'success',
              'QR-Code erfolgreich erstellt 😊'
            );
            this.dataUrl = url;
          } else {
            snackbarAnzeigen = new SnackbarAnzeigen(
              'error',
              `Fehler beim Erstellen vom QR-Code 😔 ${err}`
            );
          }
          this.$root.$emit(SnackbarAnzeigen.eventName, snackbarAnzeigen);
        }
      );
    },
  },
});
