
import Vue from 'vue';
import DialogKarte from '@/components/library/DialogKarte.vue';
import { DialogEvents } from '@/ts/events/Dialog';

export default Vue.extend({
  components: { DialogKarte },
  props: {
    standardAbbreviation: {
      type: String,
      required: true,
    },
    chipText: {
      type: String,
      default: undefined,
    },
    berichtsstandard: {
      type: String,
      default: undefined,
    },
    href: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      default: undefined,
    },
    textColor: {
      type: String,
      default: 'white--text',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    dialogSchließenEventName: DialogEvents.closeDialog,
  }),
  computed: {
    innerChipText(): string {
      return this.chipText || this.standardAbbreviation;
    },
  },
});
