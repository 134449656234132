
import Vue from 'vue';

import type { CategorySection } from '@prisma/client';
import { reportId, tabId } from '@/router/queryparam';
import NextNavigation from '@/components/library/NextNavigation.vue';
import ButtonFuerNavigation from '@/components/library/ButtonFuerNavigation.vue';
import type {
  QualitativeFrageBackend,
  QuantitativeGruppeBackend,
} from '@/utils/backendTypes';
import { useStore } from '@/store';

export default Vue.extend({
  components: {
    NextNavigation,
    ButtonFuerNavigation,
  },
  props: {
    tabNames: {
      type: Array<String>,
      required: true,
    },
    tabContents: {
      type: Array<QualitativeFrageBackend[] | QuantitativeGruppeBackend[]>,
      required: true,
    },
    tabCategorySections: {
      type: Array<CategorySection>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStep: 0,
      window,
      store: useStore(),
    };
  },
  watch: {
    '$route.params': function (newParams) {
      this.currentStep = 0;
      this.$emit('category-param-changed', newParams.category);
    },
  },
  mounted() {
    const reportIdFromURL = reportId.parameterWertLesenNumber(this.$route);
    const selectedOrganizationReportId =
      this.store.selectedOrganizationReportId;
    if (reportIdFromURL === selectedOrganizationReportId) {
      this.currentStep = tabId.parameterWertLesenNumber(this.$route) ?? 0;
    }

    this.$emit('category-param-changed', this.$route.params.category);
  },
});
