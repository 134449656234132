
import type { PropType } from 'vue';
import Vue from 'vue';
import { DialogEvents } from '@/ts/events/Dialog';
import DialogKarte from '@/components/library/DialogKarte.vue';

interface ReportStandard {
  id: number;
  name: string;
}

export default Vue.extend({
  components: { DialogKarte },
  props: {
    themeName: {
      type: String,
      default: '',
    },
    reportStandards: {
      type: Array as PropType<ReportStandard[]>,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      dialogSchließenEventName: DialogEvents.closeDialog,
    };
  },
});
