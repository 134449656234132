import Vue from 'vue';
import { useStore } from '@/store';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import { getErrorMessage } from '@/utils/generic';
import { trpcClient } from '@/utils/trpc';

export default Vue.extend({
  data() {
    return {
      selectedOrganizationReportId: undefined as number | undefined,
      store: useStore(),
    };
  },
  methods: {
    async handleReportChange(selectedOrganizationReportId: any) {
      try {
        await trpcClient.organizationReport.setSelectedForOrganizationUser.mutate(
          {
            id: selectedOrganizationReportId as number,
          }
        );

        this.store.selectedOrganizationReportId =
          selectedOrganizationReportId as number;

        this.$emit('rerender-app');
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen(
            'error',
            `Der Bericht kann nicht geändert werden: ${errorMessage}`
          )
        );

        this.selectedOrganizationReportId =
          this.store.selectedOrganizationReportId;
      }
    },
  },
});
