import Vue from 'vue';

import type { OrganizationReportQuantitativeActualResponse } from '@prisma/client';

export default Vue.extend({
  methods: {
    emitResponses(
      response:
        | { message: string; responses?: undefined }
        | {
            message: string;
            responses: {
              [key: number]: OrganizationReportQuantitativeActualResponse;
            };
          }
    ) {
      const responses = response.responses;
      if (responses) {
        this.$emit('new-responses', responses);
      }
    },
  },
});
