
import type { PropType } from 'vue';
import Vue from 'vue';
const good = {
  color: 'green',
  icon: 'mdi-check-circle',
} as const;
const medium = {
  color: 'yellow',
  icon: 'mdi-minus-circle',
} as const;
const bad = {
  color: 'red',
  icon: 'mdi-close-circle',
} as const;

export default Vue.extend({
  props: {
    antwortAmpel: {
      type: Object as PropType<AntwortAmpel>,
      required: true,
    },
  },
  computed: {
    goodMediumBad(): typeof good | typeof medium | typeof bad {
      if (this.antwortAmpel.between(4, 5)) {
        return good;
      } else if (this.antwortAmpel.between(2, 3.99)) {
        return medium;
      }
      return bad;
    },
  },
});

export class AntwortAmpel {
  value: number;

  constructor(punkte: number) {
    this.value = punkte;
  }

  between(left: number, right: number) {
    const min = Math.min(left, right);
    const max = Math.max(left, right);

    return this.value >= min && this.value <= max;
  }
}
