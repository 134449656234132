
import Vue from 'vue';
import OrganizationReportCrud from './components/OrganizationReportCrud.vue';
import OrganizationUserCrud from './components/OrganizationUserCrud.vue';
import OrganizationReportOverview from './components/OrganizationReportOverview.vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import type {
  LoggedInUser,
  OrganizationUserType,
  VuetifyForm,
} from '@/ts/types/generic';
import { getUser } from '@/components/login/login';
import { getOrganizationIdFromUser } from '@/utils/user';
import type { Organization } from '@/utils/backendTypes';
import OrganizationBranding from '@/views/organizations/components/OrganizationBranding.vue';
import { ebPurple } from '@/plugins/vuetify';
import IconWithTooltip from '@/components/library/IconWithTooltip.vue';
import InfoBanner from '@/components/library/InfoBanner.vue';

const OrganizationUserTypeTranslation: Record<OrganizationUserType, string> = {
  MEMBER: 'Mitglied',
  OWNER: 'Besitzer',
};

export default Vue.extend({
  name: 'ParentOrganizationView',
  components: {
    OrganizationReportCrud,
    OrganizationUserCrud,
    OrganizationReportOverview,
    OrganizationBranding,
    IconWithTooltip,
    InfoBanner,
  },
  data: () => ({
    tab: null,
    ebPurple,
    organizationId: undefined as undefined | number,
    organization: null as null | Organization,
    error: null as null | string,
    updateKey: Number(new Date()),
    formRulesName: [
      (value: string) => !!value || 'Feld ist erforderlich',
      (value: string) =>
        (value && value.length <= 50) ||
        `Name muss weniger als 50 Zeichen lang sein (jetzt ${value.length})`,
    ],
    // organization data
    dialogOrganizations: false,
    dialogOrganizationsDelete: false,
    headersOrganizations: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
    ],
    editedOrganizationsIndex: -1,
    editedOrganizationsItem: {
      name: '',
      id: 0,
    },
    defaultOrganizationsItem: {
      name: '',
      id: 0,
    },
    users: [] as any[],
  }),

  computed: {
    formOrganizationsTitle() {
      return this.editedOrganizationsIndex === -1
        ? 'Neue Organisation'
        : 'Organisation bearbeiten';
    },
    subOrganizations() {
      return this?.organization?.subOrganizations || [];
    },
  },

  watch: {
    dialogOrganizations(val) {
      val || this.closeOrganizations();
    },
    dialogDeleteOrganizations(val) {
      val || this.closeOrganizationsDelete();
    },
  },

  created() {
    const user = getUser() as LoggedInUser;
    const userIsAdmin = user.extendedRole === 'ADMIN';
    const userIsParentOrgOwner = user.extendedRole === 'PARENT_ORG_OWNER';
    const onErrorPage = userIsAdmin ? '/organizations' : '/';

    let { id } = this.$route.params;

    if (userIsAdmin && !id) {
      this.$router.push('/organizations');
    }

    if (userIsParentOrgOwner) {
      const orgId = getOrganizationIdFromUser(user);
      if (orgId) {
        id = String(orgId);
      }
    }

    const organizationId = Number(id);
    const organizationIdIsSet = organizationId && organizationId > 0;
    if (!organizationIdIsSet) {
      this.$router.push(onErrorPage);
    }

    this.organizationId = organizationId;
    this.setData();
  },

  methods: {
    async setData() {
      const user = getUser() as LoggedInUser;
      const onErrorPage = user.role === 'ADMIN' ? '/organizations' : '/';

      try {
        const organization = await trpcClient.organization.getById.query({
          id: this.organizationId as number,
        });

        if (!organization || organization.parentOrganizationId) {
          this.$router.push(onErrorPage);
        }
        this.organization = organization;
        this.setUsers();
        this.updateKey = Number(new Date());
      } catch (error) {
        this.$router.push(onErrorPage);
      }
    },

    setUsers() {
      const preparedUsers = this.organization?.organizationUsers?.map(
        (orgUser) => {
          const user = orgUser.user;

          const fullName = `${user.firstName} ${user.lastName}`;
          const isVerified = user.isVerified ? 'Ja' : 'Nein';
          const type = orgUser.type;
          const typeText = OrganizationUserTypeTranslation[orgUser.type];

          return {
            ...user,
            fullName,
            isVerified,
            type,
            typeText,
          };
        }
      );

      this.users = preparedUsers || [];
    },

    editOrganizationsItem(item: any) {
      this.editedOrganizationsIndex = this.subOrganizations.indexOf(item);
      this.editedOrganizationsItem = Object.assign({}, item);
      this.dialogOrganizations = true;
    },

    deleteOrganizationsItem(item: any) {
      this.editedOrganizationsIndex = this.subOrganizations.indexOf(item);
      this.editedOrganizationsItem = Object.assign({}, item);
      this.dialogOrganizationsDelete = true;
    },

    async deleteOrganizationsItemConfirm() {
      this.closeOrganizationsDelete();
      try {
        await trpcClient.organization.deleteById.mutate({
          id: this.editedOrganizationsItem.id,
        });
        await this.setData();
        this.error = null;
      } catch (error) {
        this.error = getErrorMessage(error);
      }
    },

    closeOrganizations() {
      this.dialogOrganizations = false;
      this.$nextTick(() => {
        this.editedOrganizationsItem = Object.assign(
          {},
          this.defaultOrganizationsItem
        );
        this.editedOrganizationsIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    closeOrganizationsDelete() {
      this.dialogOrganizationsDelete = false;
      this.$nextTick(() => {
        this.editedOrganizationsItem = Object.assign(
          {},
          this.defaultOrganizationsItem
        );
        this.editedOrganizationsIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    async saveOrganizations() {
      const isValidated = (this.$refs.form as VuetifyForm).validate();

      if (isValidated) {
        if (this.editedOrganizationsIndex > -1) {
          try {
            await trpcClient.organization.updateById.mutate({
              name: this.editedOrganizationsItem.name,
              id: this.editedOrganizationsItem.id,
            });
            await this.setData();
            this.error = null;
          } catch (error) {
            this.error = getErrorMessage(error);
          }
        } else {
          try {
            await trpcClient.organization.create.mutate({
              name: this.editedOrganizationsItem.name,
              parentOrganizationId: this.organizationId,
            });
            await this.setData();
            this.error = null;
          } catch (error) {
            this.error = getErrorMessage(error);
          }
        }
        this.closeOrganizations();
      }
    },

    viewOrganizationsItem(item: any) {
      this.$router.push(`/organizations/${this.organizationId}/sub/${item.id}`);
    },
  },
});
