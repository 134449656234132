import { WaitForElement } from '@/ts/WaitForElement';

export class ScrollToElement {
  selector: string;

  constructor(selector: string) {
    this.selector = selector;
  }

  public async scrollToElement() {
    const element = await new WaitForElement(this.selector).waitForElement();

    if (element) {
      const offsetElementWillNotBeHiddenByHeader = 58;
      const y =
        element.getBoundingClientRect().top +
        window.scrollY -
        offsetElementWillNotBeHiddenByHeader;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}

export class ScrollToId {
  id: string;

  constructor(id: string) {
    this.id = id;
  }

  public async scrollToId() {
    new ScrollToElement(`#${this.id}`).scrollToElement();
  }
}
