
import type { PropType } from '@vue/composition-api';
import Vue from 'vue';
import type { NavigationsPunkt } from '@/components/navigation/navigation';

export default Vue.extend({
  props: {
    navigationsziel: {
      type: Object as PropType<NavigationsPunkt>,
      required: false,
      default: () => {},
    },
    targetName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      window,
    };
  },
});
