
import type { PropType } from 'vue';
import Vue from 'vue';
import FrageId from '@/components/library/FrageId.vue';
import type { QuantitativeQuestionBackend } from '@/utils/backendTypes';
import InfoDialog from '@/components/library/InfoDialog.vue';

export default Vue.extend({
  components: {
    FrageId,
    InfoDialog,
  },
  props: {
    question: {
      type: Object as PropType<QuantitativeQuestionBackend>,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
    toolTipText: {
      type: String,
      default: undefined,
    },
  },
});
