
import Vue from 'vue';
import type { PropType } from 'vue';

export default Vue.extend({
  props: {
    mdiIcon: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
    tooltipTop: {
      type: Boolean,
      default: true,
    },
    to: {
      type: Object as PropType<String | Object>,
      default: undefined,
    },
  },
  data() {
    return {};
  },
});
