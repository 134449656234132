
import Vue from 'vue';
import { PlausibilitätDialogParameter } from '@/ts/events/PlausibilitätParameter';
import { DialogEvents } from '@/ts/events/Dialog';
import DialogKarte from '@/components/library/DialogKarte.vue';

export const erlaubteAbweichung = 0.5;
const prozent = erlaubteAbweichung * 100;

export default Vue.extend({
  components: { DialogKarte },
  data: () => ({
    dialogPlausibiltätAnzeigen: false,
    aktuellerWert: {} as Number,
    vorjahr: {} as Number,
    prozent,
    dialogSchließenEventName: DialogEvents.closeDialog,
  }),
  mounted() {
    this.$root.$on(
      PlausibilitätDialogParameter.eventName,
      (event: PlausibilitätDialogParameter) => {
        this.aktuellerWert = event.aktuellerWert;
        this.vorjahr = event.vorjahr;
        this.dialogPlausibiltätAnzeigen = true;
      }
    );
  },
  methods: {
    dialogSchließen(): void {
      this.dialogPlausibiltätAnzeigen = false;
    },
  },
});
