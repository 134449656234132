
import mixins from 'vue-typed-mixins';
import { trpcClient } from '@/utils/trpc';
import handleQuantitativeError from '@/mixins/handleError';
import updateResponses from '@/mixins/updateResponses';
import ToolTipResponder from '@/components/fragebogen/quantitativ/ToolTipResponder.vue';

export default mixins(updateResponses, handleQuantitativeError).extend({
  components: {
    ToolTipResponder,
  },
  props: {
    questionId: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    reportLocked: {
      type: Boolean,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
    responder: {
      type: String,
      default: undefined,
    },
    respondedAt: {
      type: Date,
      default: undefined,
    },
  },
  methods: {
    createNumericResponse(questionId: number, response: string) {
      trpcClient.response.createQuantitativeNumeric
        .mutate({
          response: response ? +response : null,
          questionId,
        })
        .then(this.emitResponses)
        .catch((e) =>
          this.handleError(e, 'Konnte die Antwort nicht speichern:')
        );
    },
  },
});
