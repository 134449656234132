import type { LoggedInUser } from '@/ts/types/generic';

export const getUser = (): LoggedInUser | null => {
  const userStringified = localStorage.getItem('user');

  if (!userStringified) {
    return null;
  }

  return JSON.parse(userStringified);
};
