
import type { ApexOptions } from 'apexcharts';
import Vue from 'vue';
import type { DataPoint } from '@/components/fragebogen/quantitativ/GraphElement.vue';
import { barChartColors } from '@/ts/Colors';

export default Vue.extend({
  props: {
    absoluteWerte: {
      type: Boolean,
      required: true,
    },
    datenPunkteBerichtsjahr: {
      type: Array as () => Array<DataPoint>,
      required: true,
    },
    datenPunkteVorjahr: {
      type: Array as () => Array<DataPoint>,
      required: true,
    },
  },
  computed: {
    series(): any {
      return this.datenPunkteBerichtsjahr.map((x, index) => {
        const data = [x.value, this.datenPunkteVorjahr[index].value];
        return {
          name: x.label,
          data,
        };
      });
    },
    chartOptions(): ApexOptions {
      const componentOptions: any = this.$options;
      return {
        colors: barChartColors(
          this.$vuetify.theme.currentTheme.primary! as string
        ),
        states: {
          hover: {
            filter: {
              value: 0,
              type: '',
            },
          },
        },
        chart: {
          type: 'bar',
          stacked: true,
          stackType: this.absoluteWerte ? 'normal' : '100%',
          width: 'auto',
          toolbar: {
            show: false,
          },
          events: {
            updated(_chartContext, _config) {
              const selector = `[${componentOptions._scopeId}] .apexcharts-text.apexcharts-xaxis-label:nth-child(1)`;
              const element = document.querySelector(selector);
              element?.setAttribute('text-anchor', 'end');
            },
          },
        },
        xaxis: {
          categories: ['Berichtsjahr', 'Vorjahr'],
          labels: {
            trim: true,
            style: {
              fontFamily: 'unset', // reset font
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        legend: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
      };
    },
  },
});

export class DatenPunkt {
  wert: number;
  label: string;
  constructor(wert: number, label: string) {
    this.wert = wert;
    this.label = label;
  }
}
