
import Vue from 'vue';
import type { VuetifyForm } from '@/ts/types/generic';
import { getErrorMessage, isValidEmail } from '@/utils/generic';
import { trpcClient } from '@/utils/trpc';

export default Vue.extend({
  data: () => {
    return {
      email: '',
      error: null as null | string,
      isSetSuccessfully: false,
      formRulesEmail: [
        (value: string) => !!value || 'Email ist erforderlich',
        (value: string) =>
          (value && value.length <= 255) ||
          `Email muss weniger als 255 Zeichen lang sein`,
        (value: string) =>
          isValidEmail(value) || 'Es ist keine gültige E-Mail',
      ],
    };
  },
  methods: {
    async submit() {
      const isValidated = (this.$refs.form as VuetifyForm).validate();

      if (isValidated) {
        try {
          await trpcClient.user.requestPasswordReset.mutate({
            email: this.email,
          });
          this.error = null;
          this.isSetSuccessfully = true;
        } catch (error) {
          this.error = getErrorMessage(error);
        }
      }
    },
  },
});
