
import type { PropType } from 'vue';
import Vue from 'vue';

import type {
  QuantitativeQuestionsBackend,
  QuantitativeResponseBackend,
} from '@/utils/backendTypes';
import QuestionColumn from '@/components/fragebogen/quantitativ/tabellenDarstellung/QuestionColumn.vue';
import EmissionFactorColumn from '@/components/fragebogen/quantitativ/tabellenDarstellung/EmissionFactorColumn.vue';
import SimpleQuestionAnswerColumn from '@/components/fragebogen/quantitativ/tabellenDarstellung/SimpleQuestionAnswerColumn.vue';
import DisplayProportion from '@/components/fragebogen/quantitativ/tabellenDarstellung/DisplayProportion.vue';
import DisplayPreviousYear from '@/components/fragebogen/quantitativ/tabellenDarstellung/DisplayPreviousYear.vue';
import DisplayDevelopment from '@/components/fragebogen/quantitativ/tabellenDarstellung/DisplayDevelopment.vue';

export default Vue.extend({
  components: {
    QuestionColumn,
    EmissionFactorColumn,
    SimpleQuestionAnswerColumn,
    DisplayProportion,
    DisplayPreviousYear,
    DisplayDevelopment,
  },
  props: {
    question: {
      type: Array as PropType<QuantitativeQuestionsBackend>,
      required: true,
    },
    responses: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    responsesPreviousYear: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    advancedTable: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reportLocked: {
      type: Boolean,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
    toolTipText: {
      type: String,
      default: undefined,
    },
  },
  data() {
    const innerQuestion = this.question[0];
    const emissionFactorId = innerQuestion.emissionFactor[0]?.id;
    const emissionFactorFrontendId =
      innerQuestion.emissionFactor[0]?.frontendId;
    return {
      innerQuestion,
      emissionFactorId,
      emissionFactorFrontendId,
    };
  },
  computed: {
    response(): QuantitativeResponseBackend | null | undefined {
      return this.responses[this.innerQuestion.frontendId];
    },
    responsePreviousYear(): QuantitativeResponseBackend | null | undefined {
      return this.responsesPreviousYear[this.innerQuestion.frontendId];
    },
    responseEmmisionfactor(): QuantitativeResponseBackend | null | undefined {
      return this.responses[this.emissionFactorFrontendId];
    },
    value(): number | null | undefined {
      const valueNumeric = this.response?.valueNumeric;
      if (this.disabled && valueNumeric) {
        return Number((Math.round(valueNumeric * 100) / 100).toFixed(2));
      }
      return valueNumeric;
    },
    valueProportion(): number | null | undefined {
      return this.response?.valueNumericProportion;
    },
    valuePreviousYear(): number | null | undefined {
      return this.responsePreviousYear?.valueNumeric;
    },
    valuePreviousYearProportion(): number | null | undefined {
      return this.responsePreviousYear?.valueNumericProportion;
    },
    valueDevelopment(): number | null {
      if (this.value === undefined || this.value === null) {
        return null;
      }
      if (
        this.valuePreviousYear === undefined ||
        this.valuePreviousYear === null
      ) {
        return null;
      }
      return this.value / this.valuePreviousYear - 1;
    },
    valueEmissionFactor(): number | null {
      const factorFromDatabase = this.responseEmmisionfactor?.valueNumeric;
      if (factorFromDatabase === undefined || factorFromDatabase === null) {
        return this.innerQuestion.emissionFactor[0]?.defaultEmissionFactor;
      }
      return factorFromDatabase;
    },
    responder(): string | undefined {
      const user = this.response?.organizationUser?.user;
      return user ? `${user.firstName} ${user.lastName}` : undefined;
    },
    respondedAt(): Date | undefined {
      return this.response?.createdAt;
    },
    responderEmissionFactor(): string | undefined {
      const user = this.responseEmmisionfactor?.organizationUser?.user;
      return user ? `${user.firstName} ${user.lastName}` : undefined;
    },
    respondedAtEmissionFactor(): Date | undefined {
      return this.responseEmmisionfactor?.createdAt;
    },
  },
});
