
import type { Component, PropType } from 'vue';
import Vue from 'vue';
import type { Textteil } from '@/components/fragebogen/fragetypen/zusammengesetzterText/ZusammengesetzterText.vue';
import BereichsNoten from '@/components/scores/BereichsNoten.vue';

const defaultExport = Vue.extend({
  components: { BereichsNoten },
  props: {
    prop: {
      type: Object as PropType<InfokarteGesamtAuswertung>,
      required: true,
    },
  },
});
export default defaultExport;

export class InfokarteGesamtAuswertung implements Textteil {
  component: Component = defaultExport;

  constructor() {}
}
