
import Vue from 'vue';
import { getErrorMessage } from '@/utils/generic';
import { trpcClient } from '@/utils/trpc';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
import type { VuetifyForm } from '@/ts/types/generic';
import { useStore } from '@/store';
import type { ReportOverviewData } from '@/utils/backendTypes';
import InfoBanner from '@/components/library/InfoBanner.vue';

interface Item {
  id: number;
  year: number;
  reports: Report[];
}

interface Report {
  id: number;
  name: string;
  organization: string;
  isLocked: boolean;
  isLockedFormatted: string;
  isAggregatedFormatted: string;
  hasCO2report: string;
}

interface FormattedItems {
  id: number;
  year: number;
  reports: Report[];
  canAggregate: boolean;
}

export default Vue.extend({
  components: {
    InfoBanner,
  },
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
    updateKey: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    reportOverviewData: null as ReportOverviewData | null,
    error: null as null | string,
    newReportName: '',
    formRulesName: [
      (value: string) => !!value || 'Feld ist erforderlich',
      (value: string) =>
        (value && value.length <= 50) ||
        `Name muss weniger als 50 Zeichen lang sein (jetzt ${value.length})`,
    ],
    selectedReports: {} as { [key: number]: boolean },
    headers: [
      { text: 'Jahr', value: 'year', sortable: true },
      { text: 'Organisation', value: undefined, sortable: false },
      { text: 'Bericht', value: undefined, sortable: false },
      {
        text: 'Abgeschlossen',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Aggregiert',
        align: 'start',
        sortable: false,
      },
      {
        text: '1,5 Gradziel Bericht erstellt',
        align: 'start',
        sortable: false,
      },
    ],
    selectedYear: null as number | null,
    store: useStore(),
  }),

  computed: {
    formattedItems(): FormattedItems[] {
      if (this.reportOverviewData) {
        const items: FormattedItems[] = [];

        for (const reportData of this.reportOverviewData) {
          const reports: Report[] = [];

          let possibleReportsNumberForAggregation = 0;

          for (const report of reportData.organizationReports) {
            if (report.isLocked) {
              possibleReportsNumberForAggregation++;
            }

            reports.push({
              id: report.id,
              name: report.name,
              organization: report.organization.name,
              isLocked: report.isLocked,
              isLockedFormatted: report.isLocked ? 'Ja' : 'Nein',
              isAggregatedFormatted: report.isAggregated ? 'Ja' : 'Nein',
              hasCO2report:
                report.organizationCO2Reports.length > 0 ? 'Ja' : 'Nein',
            });
          }

          const canAggregate = possibleReportsNumberForAggregation > 1;

          items.push({
            id: reportData.id,
            year: reportData.year,
            canAggregate,
            reports,
          });
        }

        return items;
      }

      return [];
    },

    isActionButtonDisabled() {
      const selectedReports = Object.keys(this.selectedReports).length;
      const isDisabled = selectedReports < 2;

      return isDisabled;
    },
  },

  watch: {
    updateKey(newVal, oldVal) {
      this.setData();
    },
  },

  created() {
    this.setData();
  },

  methods: {
    async setData() {
      try {
        this.reportOverviewData =
          await trpcClient.organizationReport.getReportOverview.query({
            organizationId: this.organizationId,
          });
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.error = errorMessage;
      }
    },

    async updateData() {
      this.$emit('refetch-data');
      await this.store.setOrganizationReports();
    },

    getYearKey(year: Item) {
      return year.year;
    },

    getReports(item: Item): Report[] {
      return item.reports;
    },

    updateSelectedReports(
      reportId: number,
      year: number,
      addingReport: boolean
    ) {
      const removingReport = !addingReport;
      let removeOnNextTick = false;

      if (addingReport) {
        const selectedReports = Object.keys(this.selectedReports).length;
        if (selectedReports === 0) {
          this.selectedYear = year;
          this.$set(this.selectedReports, reportId, true);
        }

        if (selectedReports > 0) {
          if (this.selectedYear !== year) {
            this.$set(this.selectedReports, reportId, false);
            removeOnNextTick = true;
          } else {
            this.$set(this.selectedReports, reportId, true);
          }
        }
      }

      if (removingReport) {
        this.$delete(this.selectedReports, reportId);
      }

      this.$nextTick(() => {
        if (removeOnNextTick) {
          this.$delete(this.selectedReports, reportId);

          // show snackbox
          const snackbarAnzeigen = new SnackbarAnzeigen(
            'warning',
            'Sie können nur die Berichte desselben Jahres aggregieren.'
          );
          this.$root.$emit(SnackbarAnzeigen.eventName, snackbarAnzeigen);
        }
      });
    },

    async aggregateReport() {
      const isValidated = (this.$refs.form as VuetifyForm).validate();
      if (isValidated) {
        this.error = null;
        this.isLoading = true;

        const organizationReportIds = Object.keys(this.selectedReports).map(
          (key) => parseInt(key, 10)
        );

        try {
          await trpcClient.organizationReport.aggregateReport.mutate({
            organizationId: this.organizationId,
            year: this.selectedYear as number,
            name: this.newReportName,
            organizationReportIds,
          });
        } catch (error) {
          this.error = getErrorMessage(error);
        }

        await this.updateData();
        await this.setData();

        this.isLoading = false;
        this.dialog = false;
        this.selectedReports = {};
        this.newReportName = '';
      }
    },
  },
});
