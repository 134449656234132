export class WaitForElement {
  selector: string;

  constructor(selector: string) {
    this.selector = selector;
  }

  public async waitForElement(): Promise<Element> {
    return new Promise((resolve) => {
      const element = document.querySelector(this.selector);
      if (element) {
        return resolve(element);
      }

      const observer = new MutationObserver((_mutations) => {
        const element = document.querySelector(this.selector);
        if (element) {
          resolve(element);
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }
}
