
import Vue from 'vue';

import { trpcClient } from '@/utils/trpc';
import { getErrorMessage, isValidPassword } from '@/utils/generic';
import type { User, VuetifyForm } from '@/ts/types/generic';

export default Vue.extend({
  data: () => {
    return {
      token: '',
      user: null as null | User,
      password1: '',
      password2: '',
      showPassword1: false,
      showPassword2: false,
      errorOnInit: null as null | string,
      errorOnSubmit: null as null | string,
      isSetSuccessfully: false,
      formRulesPassword1: [
        (value: string) => !!value || 'Passwort ist erforderlich',
        (value: string) =>
          (value && value.length <= 50) ||
          `Passwort muss weniger als 50 Zeichen lang sein`,
        (value: string) =>
          isValidPassword(value) ||
          'Das Passwort muss mindestens 10 Zeichen lang sein und mindestens eine Zahl und ein Sonderzeichen enthalten, z. B. ,!#%^&',
      ],
    };
  },
  computed: {
    userFullName(): string {
      if (this.user) {
        return `${this.user.firstName} ${this.user.lastName}`;
      }
      return '';
    },
  },
  async created() {
    const { token } = this.$route.query;

    if (!token) {
      this.errorOnInit = 'Verifizierungs-Token nicht gefunden.';
      return;
    }

    try {
      const verificationToken = await trpcClient.verification.getByToken.query(
        token as string
      );

      this.token = token as string;
      this.user = verificationToken.user;
    } catch (error) {
      this.errorOnInit = getErrorMessage(error);
    }
  },
  methods: {
    formRulesPassword2(value: string) {
      if (!this.password1) {
        return true;
      }

      if (!value) {
        return 'Passwort ist erforderlich';
      }

      if (value !== this.password1) {
        return 'Passwörter stimmen nicht überein';
      }

      return true;
    },
    onInputPassword1() {
      if (this.password2) {
        (this.$refs.form as VuetifyForm).validate();
      }
    },
    onInputPassword2() {
      if (this.password1) {
        (this.$refs.form as VuetifyForm).validate();
      }
    },
    async submit() {
      const isValidated = (this.$refs.form as VuetifyForm).validate();

      if (isValidated) {
        try {
          await trpcClient.verification.verifyAndSetPassword.mutate({
            token: this.token,
            password1: this.password1,
            password2: this.password2,
          });

          this.isSetSuccessfully = true;
        } catch (error) {
          this.errorOnSubmit = getErrorMessage(error);
        }
      }
    },
  },
});
