
import Vue from 'vue';
import type { PropType } from 'vue';
import type { MaterialityThemeTreeNode } from '../types';
import ThemeNameDialog from './ThemeNameDialog.vue';
import ReportStandardDialog from './ReportStandardDialog.vue';
import DeleteThemeDialog from './DeleteThemeDialog.vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';

type ReorderAction = 'MoveUp' | 'MoveDown';

interface DataItem {
  id: number;
  name: string;
  categoryId?: number;
  parentMaterialityThemeId?: number;
  allReportStandards?: { id: number; name: string }[];
  selectedReportStandardsIds?: number[];
  showCreateButton?: boolean;
  showEditButton?: boolean;
  showMoveUpButton?: boolean;
  showMoveDownButton?: boolean;
  showMaterialityButton?: boolean;
  showDeleteButton?: boolean;
  children?: DataItem[];
}

export default Vue.extend({
  name: 'MaterialityThemesTree',
  components: {
    ThemeNameDialog,
    ReportStandardDialog,
    DeleteThemeDialog,
  },
  props: {
    items: {
      type: Array as PropType<DataItem[]>,
      required: true,
    },
    refetchTreeDataFn: Function,
  },
  data: () => ({
    showNameDialog: false,
    showDeleteDialog: false,
    showReportStandardDialog: false,
    openNodes: [] as (number | string)[],
    editedNode: {
      id: undefined,
      name: undefined,
      categoryId: undefined,
      parentMaterialityThemeId: undefined,
      allReportStandards: [],
      selectedReportStandardsIds: [],
    } as MaterialityThemeTreeNode,
  }),

  watch: {
    items(value: DataItem[]) {
      const allNodes: (number | string)[] = [];
      for (const sectionNode of value) {
        allNodes.push(sectionNode.id);

        if (sectionNode.children && sectionNode.children.length > 0) {
          for (const categoryNode of sectionNode.children) {
            allNodes.push(categoryNode.id);

            if (categoryNode.children && categoryNode.children.length > 0) {
              for (const subThemeNode of categoryNode.children) {
                allNodes.push(subThemeNode.id);

                if (subThemeNode.children && subThemeNode.children.length > 0) {
                  for (const subSubThemeNode of subThemeNode.children) {
                    allNodes.push(subSubThemeNode.id);
                  }
                }
              }
            }
          }
        }
      }

      this.openNodes = allNodes;
    },
  },

  methods: {
    addTheme(item: DataItem) {
      this.editedNode = {
        categoryId: item.categoryId,
        parentMaterialityThemeId: item.parentMaterialityThemeId,
      };

      this.showNameDialog = true;
    },
    editTheme(item: DataItem) {
      this.editedNode = {
        id: item.id,
        name: item.name,
        categoryId: item.categoryId,
        parentMaterialityThemeId: item.parentMaterialityThemeId,
      };

      this.showNameDialog = true;
    },
    deleteTheme(item: DataItem) {
      this.editedNode = {
        id: item.id,
      };

      this.showDeleteDialog = true;
    },
    async moveTheme(item: DataItem, action: ReorderAction) {
      try {
        await trpcClient.materialityThemes.reorderById.mutate({
          id: item.id,
          action,
        });

        this.refetchTreeDataFn();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        this.$root.$emit(
          SnackbarAnzeigen.eventName,
          new SnackbarAnzeigen('error', errorMessage)
        );
      }
    },
    editMateriality(item: DataItem) {
      this.editedNode = {
        id: item.id,
        allReportStandards: item.allReportStandards,
        selectedReportStandardsIds: item.selectedReportStandardsIds,
      };

      this.showReportStandardDialog = true;
    },
  },
});
