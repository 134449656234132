import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

export const ebPurple= '#5A2873';

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: ebPurple,
        'eb-purple': ebPurple
      },
    },
  },
});
