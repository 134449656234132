
import Vue from 'vue';
import { SnackbarAnzeigen } from '@/ts/events/SnackbarAnzeigen';
export default Vue.extend({
  data() {
    return {
      anzeigen: false,
      type: '',
      inhalt: '',
    };
  },
  mounted() {
    this.$root.$on(SnackbarAnzeigen.eventName, (event: SnackbarAnzeigen) => {
      this.type = event.type;
      this.inhalt = event.inhalt;
      // als letztes anzeigen, damit erst alle data attribute gesetzt sind und dann die Snackbar angezeigt wird
      this.anzeigen = true;
    });
  },
});
