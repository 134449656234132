
import type { PropType } from 'vue';
import mixins from 'vue-typed-mixins';
import ThemeNameDialog from './ThemeNameDialog.vue';
import ReportStandardDialog from './ReportStandardDialog.vue';
import DeleteThemeDialog from './DeleteThemeDialog.vue';
import ReportStandardsDialog from '@/views/organization-report/components/ReportStandardsDialog.vue';
import { modelMixin } from '@/mixins/model';

export interface DataItem {
  id: number | string;
  name: string;
  isSelected?: boolean;
  children?: DataItem[];
}
const demoItems = [
  {
    id: 'section-1',
    name: 'Environment',
    children: [
      {
        id: 'category-7',
        name: 'Klimawandel',
        children: [
          {
            id: 1,
            name: 'test',
            isSelected: true,
            reportStandards: [
              { id: 39, name: 'ESRS E4-5' },
              { id: 40, name: 'ESRS E4-6' },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'category-9',
        name: 'Wasser- und Meeresressourcen',
        children: [
          {
            id: 2,
            name: 'Wasser',
            isSelected: true,
            reportStandards: [
              { id: 44, name: 'ESRS E5-4' },
              { id: 45, name: 'ESRS E5-5' },
            ],
            children: [],
          },
        ],
      },
    ],
  },
];

export default mixins(modelMixin).extend({
  components: {
    ThemeNameDialog,
    ReportStandardDialog,
    DeleteThemeDialog,
    ReportStandardsDialog,
  },
  props: {
    materialityThemes: {
      type: Array as PropType<DataItem[]>,
      default: () => demoItems,
    },
    openNodes: {
      type: Array as PropType<(string | number)[]>,
      default: () => [],
    },
  },
});
