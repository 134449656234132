import { DoubleNewLine } from '@/components/fragebogen/fragetypen/zusammengesetzterText/DoubleNewLine.vue';
import { EinfacherLink } from '@/components/fragebogen/fragetypen/zusammengesetzterText/EinfacherLink.vue';
import { EinfacherText } from '@/components/fragebogen/fragetypen/zusammengesetzterText/EinfacherText.vue';
import KartenInhalt from '@/components/homepage/KartenInhalt';

const firstCard = {
  kartenTitel: 'Frequently Asked Questions (FAQs)',
  ersteKarte: true,
  htmlKartenBody: new KartenInhalt([
    new EinfacherText(
      'Hier finden Sie eine Hilfestellung zu den häufigsten Fragen rund um das EB-Sustainability-Scoring. Falls Sie darüber hinaus Fragen haben, können Sie sich an '
    ),
    new EinfacherLink('esg@eb.de', 'mailto:esg@eb.de'),
    new EinfacherText(' wenden.'),
  ]),
  anzeigen: true,
  cardWithBackground: true,
  karteId: 'FAQs-title'
};

const secondCard = {
  kartenTitel: 'Wie kann ich eine neue Unterorganisation anlegen?',
  htmlKartenBody: new KartenInhalt([
    new EinfacherText('In der Navigationsleiste unter Verwaltung können Sie u.a. neue Unterorganisationen anlegen. Klicken Sie dazu auf Unterorganisationen und dann auf den Neu-Button. Nun können Sie den Namen der Unterorganisation festlegen und bestätigen Sie dies mit einem Klick auf Speichern. Nachdem Sie die Unterorganisation angelegt haben. Können Sie neue Nutzer zu dieser Unterorganisation einladen, Berichte erstellen, das Design und das Design für die Unterorganisationen anpassen. Zudem können Sie die Namen der Unterorganisation nachträglich anpassen und die Unterorganisation löschen.'),
    new DoubleNewLine(),
    new EinfacherText('Bitte beachten Sie, dass wenn Sie eine Unterorganisation löschen, auch die hinterlegten Daten und Auswertungen gelöscht werden.')

  ]),
  anzeigen: true,
  cardWithBackground: false,
  karteId: 'FAQs-unterorganisation-erstellen'
};

const thirdCard = {
  kartenTitel: 'Was kann ich tun, wenn mein Einladungslink zum EB-Sustainability-Scoring abgelaufen ist?',
  htmlKartenBody: new KartenInhalt([
    new EinfacherText('Wenn Sie unter Verwaltung auf Nutzer klicken, können Sie neue Nutzer zum EB-Sustainability-Scoring einladen. Diese Personen erhalten anschließend einen Einladungslink per Mail an die von Ihnen festgelegte E-Mailadresse. Dieser Link ist für zwei Wochen gültig. Sollte dieser Link in diesem Zeitraum nicht genutzt und der Zugang aktiviert werden, verliert der Einladungslink seine Gültigkeit. Den Staus der Einladung sehen Sie in der Übersicht unter Verifiziert. Hat ein Nutzer den Zugang aktiviert wird dies mit Ja kenntlich gemacht. Ist der Zugang noch nicht aktiviert ist dies mit Nein gekennzeichnet.'),
    new DoubleNewLine(),
    new EinfacherText('Wenn der Einladungslink abgelaufen ist, kann dieser nicht mehr genutzt werden. Um einen neuen Einladungslink an diesen Nutzer zu schicken, klicken Sie unter Aktionen auf Nutzer löschen und legen diesen mit einem Klick auf Neu erneut im System an. Die Person erhält dann einen neuen Einladungslink.')

  ]),
  anzeigen: true,
  cardWithBackground: true,
  karteId: 'FAQs-einladungslink'
};
const fourthCard = {
  kartenTitel: 'Kann ich das Design des EB-Sustainability-Scorings anpassen?',
  htmlKartenBody: new KartenInhalt([
    new EinfacherText('In der Navigationsleiste unter Verwaltung können Sie u.a. das Design des EB-Sustainability-Scorings anpassen. Klicken Sie dazu auf Design und nun können Sie sowohl ein Logo als Bilddatei hochladenals auch eine neue Primärfarbe festlegen. Um ein neues Logo festzulegen, klicken Sie auf Bilddatei auswählen und wählen die entsprechende Bilddatei aus und klicken Sie auf Öffnen. Nun erscheint das Logo in der Vorschau. Möchten Sie dieses Logo festlegen, klicken Sie abschließend auf Bilddatei hochladen. Möchten Sie das Logo anpassen, wieder holen Sie die Schritte mit einer anderen Bilddatei oder stellen Sie den Ursprungszustand mit einem Klick auf Bilddatei zurücksetzen her.'),
    new DoubleNewLine(),
    new EinfacherText('Die Primärfarbe des EB-Sustainability-Scoring können Sie unter Primärfarbe auswählen festlegen. Wählen Sie dazu Ihre Wunschfarbe direkt in der Auswahl aus oder legen Sie die Farbe mittels HEX-, RGB- oder HSL-Code fest. Um zwischen den Farb-Codes (HEX, RGB, HSL) zu wechseln, klicken Sie auf die Pfeile nach oben oder unten. Wenn Sie die entsprechende Farbe festgelegt haben, dann klicken Sie auf Farbe auswählen.'),
    new DoubleNewLine(),
    new EinfacherText('Bitte beachten Sie, dass sehr helle Farben (z.B.) weiß die Nutzbarkeit des EB-Sustainability-Scorings einschränken können. Sie können die Farbgebung jederzeit mit einem Klick auf Farbe zurücksetzen in den Ursprungszustand zurück versetzen oder eine neue Farbe mit Klick auf Farbe auswählen festlegen. Zudem können Sie für die Organisation und die auch die jeweiligen Unterorganisationen eigene Designs festlegen.')

  ]),
  anzeigen: true,
  cardWithBackground: false,
  karteId: 'FAQs-design'
};

export default [firstCard, secondCard, thirdCard, fourthCard];
