import { defineStore } from 'pinia';

import type {
  Organizations,
  SelectedOrganizationReport,
} from '@/utils/backendTypes';
import { trpcClient } from '@/utils/trpc';

interface State {
  organizations: Organizations;
  selectedOrganizationReportId: number | undefined;
}

export const useStore = defineStore({
  id: 'store',
  state: (): State => ({
    organizations: [],
    selectedOrganizationReportId: undefined,
  }),
  actions: {
    async setOrganizationReports() {
      try {
        const { organizations, selectedOrganizationReportId } =
          await trpcClient.organizationReport.getAllByOrganizationId.query();

        this.organizations = organizations;

        this.selectedOrganizationReportId = selectedOrganizationReportId;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  },
  getters: {
    selectedOrganizationReport(): SelectedOrganizationReport | undefined {
      for (const organization of this.organizations) {
        for (const organizationReport of organization.organizationReports) {
          if (organizationReport.id === this.selectedOrganizationReportId) {
            return organizationReport;
          }
        }
      }

      return undefined;
    },
  },
});

export type ESGStore = ReturnType<typeof useStore>;
