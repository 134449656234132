
import Vue from 'vue';

import type { CategorySection } from '@prisma/client';

import { trpcClient } from '@/utils/trpc';
import QuantitativExpansionPanelsNew from '@/components/fragebogen/quantitativ/QuantitativExpansionPanelsNew.vue';
import { useStore } from '@/store';
import type { LoggedInUser } from '@/ts/types/generic';
import { getOrganizationIdFromUser } from '@/utils/user';

import TabbedView from '@/components/TabbedView.vue';
import type {
  QuantitativeGruppeBackend,
  QuantitativeResponseBackend,
  QuantitativeResponsePreviousYearBackend,
} from '@/utils/backendTypes';

import { getUser } from '@/components/login/login';
import ReportAlerts from '@/components/library/ReportAlerts.vue';

export default Vue.extend({
  components: {
    TabbedView,
    QuantitativExpansionPanelsNew,
    ReportAlerts,
  },
  data() {
    return {
      window,
      tabNames: [] as Array<String>,
      tabContents: [] as Array<QuantitativeGruppeBackend[]>,
      tabCategorySections: [] as Array<CategorySection>,
      responses: {} as { [key: string]: QuantitativeResponseBackend },
      responsesPreviousYear: {} as {
        [key: string]: QuantitativeResponsePreviousYearBackend;
      },
      isReportLocked: false,
      isReportAggregated: false,
      isSubOrganizationReport: false,
      refetchIntervalId: 0,
      store: useStore(),
      loadingQuestions: true,
    };
  },

  computed: {
    hasQuestions() {
      return this.tabNames.length > 0;
    },
  },

  mounted() {
    this.setReportValues();

    this.store.$subscribe(() => {
      this.setReportValues();
    });

    this.refetchIntervalId = setInterval(
      this.refetchResponses,
      60000
    ) as unknown as number;
  },
  beforeDestroy() {
    clearInterval(this.refetchIntervalId);
  },
  methods: {
    convertToGruppierung(param: any) {
      return param as (typeof this.tabContents)[number];
    },
    setReportValues() {
      const selectedOrganizationReport = this.store.selectedOrganizationReport;
      if (selectedOrganizationReport) {
        this.isReportLocked = selectedOrganizationReport.isLocked;
        this.isReportAggregated = selectedOrganizationReport.isAggregated;

        const user = getUser() as LoggedInUser;
        const organizationId = getOrganizationIdFromUser(user) as number;
        this.isSubOrganizationReport =
          organizationId !== selectedOrganizationReport.organizationId;
      }
    },
    async fetchData(category: string) {
      const section = 'quantitativ';

      this.loadingQuestions = true;
      const structureAndResponses =
        await trpcClient.question.getQuantitativeBySection.query({
          section,
          category,
        });
      this.loadingQuestions = false;

      const tabs = structureAndResponses.structure.map(
        (quantitativeCategory) => {
          return {
            name: quantitativeCategory.name,
            tabContent: quantitativeCategory.groups,
            categorySection: quantitativeCategory.section,
          };
        }
      );

      this.tabNames = tabs.map((x) => x.name);
      this.tabContents = tabs.map((x) => x.tabContent);
      this.tabCategorySections = tabs.map((x) => x.categorySection);

      this.responses = structureAndResponses.responses;
      this.responsesPreviousYear = structureAndResponses.responsesPreviousYear;
      return tabs;
    },
    async refetchResponses() {
      const section = 'quantitativ';
      const category = this.$route.params.category || '';

      const responses = await trpcClient.response.getQuantitative.query({
        section,
        category,
      });

      this.responses = responses;
    },
  },
});
