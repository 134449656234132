
import Vue from 'vue';
import type { CategorySection } from '@prisma/client';

import { dataAttributFrageId, selectorFrageId } from '@/ts/DataAttribute';
import { trpcClient } from '@/utils/trpc';
import { useStore } from '@/store';
import type { LoggedInUser } from '@/ts/types/generic';
import { getOrganizationIdFromUser } from '@/utils/user';

import type { QualitativeFrageBackend } from '@/utils/backendTypes';
import QualitativeFrage from '@/components/fragebogen/fragetypen/QualitativeFrage.vue';
import TabbedView from '@/components/TabbedView.vue';
import ReportAlerts from '@/components/library/ReportAlerts.vue';
import { getUser } from '@/components/login/login';
import { frageId, reportId } from '@/router/queryparam';
import { ScrollToElement } from '@/ts/ScrollTo';

export default Vue.extend({
  components: {
    QualitativeFrage,
    TabbedView,
    ReportAlerts,
  },
  data() {
    return {
      aktuellerStep: 0,
      window,
      dataAttributFrageId,
      tabNames: [] as Array<String>,
      tabContents: [] as Array<QualitativeFrageBackend[]>,
      tabCategorySections: [] as Array<CategorySection>,
      isReportLocked: false,
      isReportAggregated: false,
      isSubOrganizationReport: false,
      store: useStore(),
      loadingQuestions: true,
    };
  },
  async mounted() {
    const reportIdFromURL = reportId.parameterWertLesenNumber(this.$route);
    const selectedOrganizationReportId =
      this.store.selectedOrganizationReportId;

    if (reportIdFromURL === selectedOrganizationReportId) {
      await new ScrollToElement(
        selectorFrageId(frageId.parameterWertLesen(this.$route))
      ).scrollToElement();
    }

    this.setReportValues();

    this.store.$subscribe(() => {
      this.setReportValues();
    });
  },
  methods: {
    convertToType(param: any) {
      return param as (typeof this.tabContents)[number];
    },
    setReportValues() {
      const selectedOrganizationReport = this.store.selectedOrganizationReport;
      if (selectedOrganizationReport) {
        this.isReportLocked = selectedOrganizationReport.isLocked;
        this.isReportAggregated = selectedOrganizationReport.isAggregated;

        const user = getUser() as LoggedInUser;
        const organizationId = getOrganizationIdFromUser(user) as number;
        this.isSubOrganizationReport =
          organizationId !== selectedOrganizationReport.organizationId;
      }
    },
    async fetchData(category: string) {
      const section = 'qualitativ';

      this.loadingQuestions = true;
      const tabs = (
        await trpcClient.question.getQualitativeBySection.query({
          section,
          category,
        })
      ).map((qualitativeCategory) => {
        return {
          name: qualitativeCategory.name,
          tabContent: qualitativeCategory.questions,
          categorySection: qualitativeCategory.section,
        };
      });
      this.loadingQuestions = false;
      this.tabNames = tabs.map((x) => x.name);
      this.tabContents = tabs.map((x) => x.tabContent);
      this.tabCategorySections = tabs.map((x) => x.categorySection);
      return tabs;
    },
  },
});
