
import type { PropType } from 'vue';
import Vue from 'vue';

import type {
  QuantitativeQuestionsBackend,
  QuantitativeResponseBackend,
} from '@/utils/backendTypes';

import QuantitativeNumberInput from '@/components/fragebogen/quantitativ/QuantitativeNumberInput.vue';

export default Vue.extend({
  components: {
    QuantitativeNumberInput,
  },
  props: {
    question: {
      type: Array as PropType<QuantitativeQuestionsBackend>,
      required: true,
    },
    responses: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    responsesPreviousYear: {
      type: Object as PropType<{ [key: string]: QuantitativeResponseBackend }>,
      required: true,
    },
    advancedTable: {
      type: Boolean,
      required: true,
    },
    reportLocked: {
      type: Boolean,
      required: true,
    },
    auswertung: {
      type: Boolean,
      default: false,
    },
  },
});
