
import Vue from 'vue';

export default Vue.extend({
  props: {
    advancedTable: {
      type: Boolean,
      required: true,
    },
  },
});
