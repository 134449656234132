const percentAlphaAsHex = (percent: number) =>
  Math.floor((percent * 255) / 100).toString(16);

const twentyPercentAlphaAsHex = () => percentAlphaAsHex(20);
const twentyfivePercentAlphaAsHex = () => percentAlphaAsHex(25);
// const fourtyPercentAlphaAsHex = () => percentAlphaAsHex(40);
const sixtyPercentAlphaAsHex = () => percentAlphaAsHex(60);
const fiftyPercentAlphaAsHex = () => percentAlphaAsHex(50);
const seventyfivePercentAlphaAsHex = () => percentAlphaAsHex(75);
const eightyPercentAlphaAsHex = () => percentAlphaAsHex(80);
// const ninetyPercentAlphaAsHex = () => percentAlphaAsHex(90);
const hundredPercentAlphaAsHex = () => percentAlphaAsHex(100);

export const cardBackgroundColor = (primaryColor: string) =>
  primaryColor + twentyPercentAlphaAsHex();

export const ownScoreColor = (primaryColor: string) =>
  primaryColor + eightyPercentAlphaAsHex();

export const branchenVergleichColor = (primaryColor: string) =>
  primaryColor + sixtyPercentAlphaAsHex();

export const scoreRadarColors = (primaryColor: string) => [
  '#fff',
  primaryColor + twentyfivePercentAlphaAsHex(),
  primaryColor + fiftyPercentAlphaAsHex(),
  primaryColor + seventyfivePercentAlphaAsHex(),
  primaryColor + hundredPercentAlphaAsHex(),
];

export const barChartColors = (primaryColor: string) => {
  const computedStyle = getComputedStyle(document.documentElement);
  return [
    primaryColor + hundredPercentAlphaAsHex(),
    primaryColor + sixtyPercentAlphaAsHex(),
    primaryColor + twentyPercentAlphaAsHex(),
    computedStyle.getPropertyValue('--v-primary-darken1'),
    computedStyle.getPropertyValue('--v-primary-darken3'),
    computedStyle.getPropertyValue('--v-primary-darken4'),
  ];
};

export const donutChartColors = barChartColors;

export const xdcChartColors = (primaryColor: string) => [
  primaryColor,
  primaryColor + sixtyPercentAlphaAsHex(),
  primaryColor + twentyPercentAlphaAsHex(),
];
