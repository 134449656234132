import { render, staticRenderFns } from "./VerificationView.vue?vue&type=template&id=5536c990&scoped=true"
import script from "./VerificationView.vue?vue&type=script&lang=ts"
export * from "./VerificationView.vue?vue&type=script&lang=ts"
import style0 from "./VerificationView.vue?vue&type=style&index=0&id=5536c990&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5536c990",
  null
  
)

export default component.exports