
import Vue from 'vue';
import OrganizationReportCrud from './components/OrganizationReportCrud.vue';
import OrganizationUserCrud from './components/OrganizationUserCrud.vue';
import { trpcClient } from '@/utils/trpc';
import { getErrorMessage } from '@/utils/generic';
import type {
  LoggedInUser,
  OrganizationUserType,
  VuetifyForm,
} from '@/ts/types/generic';
import { getUser } from '@/components/login/login';
import { getOrganizationIdFromUser } from '@/utils/user';
import type { Organization } from '@/utils/backendTypes';
import OrganizationBranding from '@/views/organizations/components/OrganizationBranding.vue';

const OrganizationUserTypeTranslation: Record<OrganizationUserType, string> = {
  MEMBER: 'Mitglied',
  OWNER: 'Besitzer',
};

export default Vue.extend({
  name: 'SubOrganizationView',
  components: {
    OrganizationReportCrud,
    OrganizationUserCrud,
    OrganizationBranding,
  },
  data: () => ({
    tab: null,
    organizationId: undefined as undefined | number,
    parentOrganizationId: undefined as undefined | number,
    dialog: false,
    dialogDelete: false,
    organization: null as null | Organization,
    editedIndex: -1,
    editedItem: {
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      type: '' as OrganizationUserType,
    },
    defaultItem: {
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      type: '' as OrganizationUserType,
    },
    error: null as null | string,
    users: [] as any[],
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    const user = getUser() as LoggedInUser;
    const userIsAdmin = user.extendedRole === 'ADMIN';
    const onErrorPage = userIsAdmin ? '/organizations' : '/';

    const { isAdminPath, isParentOrgOwnerPath } = this.$route.meta as any;

    let id, parentId;

    if (isAdminPath || isParentOrgOwnerPath) {
      id = this.$route.params.id;
      parentId = this.$route.params.parentId;

      if (!id || !parentId) {
        this.$router.push(onErrorPage);
      }
      const organizationId = Number(id);
      const organizationIdIsSet = organizationId && organizationId > 0;

      const parentOrganizationId = Number(parentId);
      const parentOrganizationIdIsSet =
        parentOrganizationId && parentOrganizationId > 0;

      if (!organizationIdIsSet || !parentOrganizationIdIsSet) {
        this.$router.push(onErrorPage);
      }
      this.organizationId = organizationId;
      this.parentOrganizationId = parentOrganizationId;
    } else {
      const user = getUser() as LoggedInUser;
      const organizationId = getOrganizationIdFromUser(user);

      if (!organizationId) {
        this.$router.push('/');
      }

      this.organizationId = organizationId;
    }

    this.setData();
  },

  methods: {
    async setData() {
      const user = getUser() as LoggedInUser;
      const userIsAdmin = user.extendedRole === 'ADMIN';
      const onErrorPage = userIsAdmin ? '/organizations' : '/';

      try {
        this.organization = await trpcClient.organization.getById.query({
          id: this.organizationId as number,
        });

        this.setUsers();
      } catch (error) {
        this.$router.push(onErrorPage);
      }
    },

    setUsers() {
      const preparedUsers = this?.organization?.organizationUsers?.map(
        (orgUser) => {
          const user = orgUser.user;

          const fullName = `${user.firstName} ${user.lastName}`;
          const isVerified = user.isVerified ? 'Ja' : 'Nein';
          const type = orgUser.type;
          const typeText = OrganizationUserTypeTranslation[orgUser.type];

          return {
            ...user,
            fullName,
            isVerified,
            type,
            typeText,
          };
        }
      );

      this.users = preparedUsers || [];
    },

    editItem(item: any) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item: any) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await trpcClient.user.deleteById.mutate({
          id: this.editedItem.id,
        });
        await this.setData();
        this.error = null;
      } catch (error) {
        this.error = getErrorMessage(error);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        (this.$refs.form as VuetifyForm).resetValidation();
      });
    },

    async save() {
      const isValidated = (this.$refs.form as VuetifyForm).validate();
      if (isValidated) {
        const isEditForm = this.editedIndex > -1;
        const isSaveForm = !isEditForm;

        if (isSaveForm) {
          try {
            await trpcClient.user.create.mutate({
              organizationId: this.organizationId as number,
              email: this.editedItem.email,
              firstName: this.editedItem.firstName,
              lastName: this.editedItem.lastName,
              type: this.editedItem.type,
            });
            await this.setData();
            this.error = null;
          } catch (error) {
            await this.setData();
            this.error = getErrorMessage(error);
          }
        }

        if (isEditForm) {
          try {
            await trpcClient.user.updateById.mutate({
              id: this.editedItem.id,
              firstName: this.editedItem.firstName,
              lastName: this.editedItem.lastName,
              type: this.editedItem.type,
            });
            await this.setData();
            this.error = null;
          } catch (error) {
            this.error = getErrorMessage(error);
          }
        }

        this.close();
      }
    },
    goToParentOrganization() {
      const user = getUser();
      const userIsAdmin = user?.extendedRole === 'ADMIN';
      const userIsParentOrgOwner = user?.extendedRole === 'PARENT_ORG_OWNER';

      if (userIsAdmin) {
        this.$router.push(`/organizations/${this.parentOrganizationId}`);
      }

      if (userIsParentOrgOwner) {
        this.$router.push(`/organization`);
      }
    },
  },
});
