
import type { PropType } from 'vue';
import Vue from 'vue';
import AntwortAmpelVue, {
  AntwortAmpel,
} from '@/components/auswertung/AntwortAmpel.vue';

import type { CategoryDataBackend } from '@/utils/backendTypes';

export default Vue.extend({
  components: {
    AntwortAmpelVue,
  },
  props: {
    sectionName: {
      type: String,
      required: true,
    },
    categories: {
      type: Array as PropType<CategoryDataBackend[]>,
      required: true,
    },
  },
  data() {
    return {
      AntwortAmpel,
    };
  },
});
